import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { ReviewList } from "modules/review/ui/review-list";
import { NavLink } from "react-router-dom";

export const Reviews = () => {
  return (
    <LayoutBase
      title="Reviews"
      actions={
        <>
          <NavLink to="/review/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
        </>
      }
    >
      <ReviewList />
    </LayoutBase>
  );
};
