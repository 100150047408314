import { sample } from "effector";
import { reviewModel } from "./review.model";
import { paginationModel } from "features/pagination/pagination.model";

const take = 10;
export const reviewsModel = reviewModel();
export const reviewsPaginationModel = paginationModel({
  loadFx: reviewsModel.loadReviewsFx,
  take,
});

const $pendingLoadingReviews = reviewsModel.loadReviewsFx.pending.map(
  (pending) => !pending
);

sample({
  clock: reviewsModel.loadReviewsFx.doneData,
  fn: (clock) => clock.results,
  target: reviewsModel.$reviews,
});

sample({
  clock: reviewsModel.ReviewsGate.close,
  filter: () => $pendingLoadingReviews.getState(),
  fn: () => [],
  target: [reviewsModel.$reviews, reviewsPaginationModel.reset],
});

sample({
  clock: reviewsModel.removeReviewWithAttachmentsFx.done,
  source: reviewsPaginationModel.params,
  fn: (source) => ({
    take: source.take,
    skip: source.skip,
  }),
  target: reviewsModel.loadReviewsFx,
});
