import { getSearchParamsAsJson } from "./lib";
import { api, response } from "./axios";
import { type AttachmentByFieldId } from "./attachment";
import type { FindAllOptions, Paginated } from "./types";

export interface Property {
  id?: number;
  slug?: string;
  type: string;
  price?: number;
  status: StatusType;
  description: string;
  attachments: AttachmentByFieldId | null;
  province: PropertyLocation | null;
  location: PropertyLocation | null;
  year?: number;
  built?: number;
  plot?: number;
  terrace?: number;
  bedrooms?: number;
  bathrooms?: number;
  floor?: number;
  pool?: number;
  parking?: number;
  garage?: number;
  features?: string[];
  hidden: boolean;
  meta?: Record<string, unknown> | null;
  source?: string | null; // if parsed - label from, null - our
  createdAt?: Date;
  updatedAt?: Date;
}

export const statusType = ["Sale", "Rent", "Available"] as const;
export type StatusType = (typeof statusType)[number];

export type PropertyListItem = {
  label: string;
  value: string;
  isChild?: boolean;
  isParent?: boolean;
};

export interface PropertyLocation {
  id: number;
  label: string;
  slug?: string;
  parent?: PropertyLocation | null;
  children: PropertyLocation[];
}

export const getProperties = (
  params?: FindAllOptions
): Promise<Paginated<Property>> => {
  const searchParams = getSearchParamsAsJson(params);
  return api.get(`/property?${searchParams}`).then(response);
};

export const getProvinces = (): Promise<PropertyLocation[]> => {
  return api.get(`/property-location/province`).then(response);
};

export const getLocations = (
  params?: FindAllOptions
): Promise<PropertyLocation[]> => {
  const searchParams = getSearchParamsAsJson(params);
  return api.get(`/property-location?${searchParams}`).then(response);
};

export const getLocation = (id: number): Promise<PropertyLocation> => {
  return api.get(`/property-location/${id}`).then(response);
};

export const provinceCreate = (label: string): Promise<PropertyLocation> => {
  return api.post(`/property-location/province`, { label }).then(response);
};

export const locationCreate = (
  label: string,
  parentId: number
): Promise<Location> => {
  return api.post(`/property-location`, { label, parentId }).then(response);
};

export const locationRemove = (id: number): Promise<number> => {
  return api.delete(`/property-location/${id}`).then(response);
};

export const getProperty = ({ id }: { id: number }): Promise<Property> => {
  return api.get(`/property/${id}`).then(response);
};

export const propertyCreate = (property: Property): Promise<Property> => {
  return api.post(`/property`, property).then(response);
};

export const propertyEdit = (property: Property): Promise<Property> => {
  return api.put(`/property`, property).then(response);
};

export const propertyRemove = (id: number): Promise<number> => {
  return api.delete(`/property/${id}`).then(response);
};

export const getPropertyReference = (property: Property) => {
  return property.source ? property.slug : `VT${property.id}`;
};

export const propertyStatuses: PropertyListItem[] = [
  {
    label: "Sale",
    value: "Sale",
  },
  {
    label: "Rent",
    value: "Sent",
  },
  {
    label: "Available",
    value: "Available",
  },
];

export const propertyYears = (): PropertyListItem[] => {
  const start = 1970;
  const current = new Date().getFullYear();
  const length = current - start + 1;

  const years: PropertyListItem[] = Array.from({ length }).map((_, i) => ({
    label: String(current - i),
    value: String(current - i),
  }));

  return [
    { label: "No year", value: "" },
    ...years,
    { label: `Pre ${start}`, value: "1111" },
  ];
};

export const propertyFeatures: PropertyListItem[] = [
  {
    label: "Air Conditioning",
    value: "Air Conditioning",
  },
  {
    label: "Beach",
    value: "Beach",
  },
  {
    label: "Sea",
    value: "Sea",
  },
  {
    label: "Mountain",
    value: "Mountain",
  },
  {
    label: "Lake",
    value: "Lake",
  },
  {
    label: "Forest",
    value: "Forest",
  },
  {
    label: "Port",
    value: "Port",
  },
  {
    label: "Golf",
    value: "Golf",
  },
  {
    label: "Tennis Court",
    value: "Tennis Court",
  },
  {
    label: "Electricity",
    value: "Electricity",
  },
  {
    label: "Water",
    value: "Water",
  },
  {
    label: "Telephone",
    value: "Telephone",
  },
  {
    label: "Gas",
    value: "Gas",
  },
  {
    label: "Guest toilet",
    value: "Guest toilet",
  },
  {
    label: "Sauna",
    value: "Sauna",
  },
  {
    label: "Steam room",
    value: "Steam room",
  },
  {
    label: "Outdoor kitchen",
    value: "Outdoor kitchen",
  },
  {
    label: "Playroom",
    value: "Playroom",
  },
  {
    label: "Gym",
    value: "Gym",
  },
];

export const propertyTypes = [
  {
    label: "Apartment",
    value: "1-1",
    isParent: true,
  },
  {
    label: "Ground Floor Apartment",
    value: "1-2",
    isChild: true,
  },
  {
    label: "Middle Floor Apartment",
    value: "1-4",
    isChild: true,
  },
  {
    label: "Top Floor Apartment",
    value: "1-5",
    isChild: true,
  },
  {
    label: "Penthouse",
    value: "1-6",
    isChild: true,
  },
  {
    label: "Penthouse Duplex",
    value: "1-7",
    isChild: true,
  },
  {
    label: "Duplex",
    value: "1-8",
    isChild: true,
  },
  {
    label: "Ground Floor Studio",
    value: "1-9",
    isChild: true,
  },
  {
    label: "Middle Floor Studio",
    value: "1-10",
    isChild: true,
  },
  {
    label: "Top Floor Studio",
    value: "1-11",
    isChild: true,
  },
  {
    label: "House",
    value: "2-1",
    isParent: true,
  },
  {
    label: "Detached Villa",
    value: "2-2",
    isChild: true,
  },
  {
    label: "Semi-Detached House",
    value: "2-4",
    isChild: true,
  },
  {
    label: "Townhouse",
    value: "2-5",
    isChild: true,
  },
  {
    label: "Finca - Cortijo",
    value: "2-6",
    isChild: true,
  },
  {
    label: "Bungalow",
    value: "2-9",
    isChild: true,
  },
  {
    label: "Quad",
    value: "2-10",
    isChild: true,
  },
  {
    label: "Castle",
    value: "2-12",
    isChild: true,
  },
  {
    label: "City Palace",
    value: "2-13",
    isChild: true,
  },
  {
    label: "Wooden Cabin",
    value: "2-14",
    isChild: true,
  },
  {
    label: "Wooden House",
    value: "2-15",
    isChild: true,
  },
  {
    label: "Mobile Home",
    value: "2-16",
    isChild: true,
  },
  {
    label: "Cave House",
    value: "2-17",
    isChild: true,
  },
  {
    label: "Plot",
    value: "3-1",
    isParent: true,
  },
  {
    label: "Residential Plot",
    value: "3-2",
    isChild: true,
  },
  {
    label: "Commercial Plot",
    value: "3-3",
    isChild: true,
  },
  {
    label: "Land",
    value: "3-4",
    isChild: true,
  },
  {
    label: "Land with Ruin",
    value: "3-5",
    isChild: true,
  },
  {
    label: "Commercial",
    value: "4-1",
    isParent: true,
  },
  {
    label: "Bar",
    value: "4-2",
    isChild: true,
  },
  {
    label: "Restaurant",
    value: "4-3",
    isChild: true,
  },
  {
    label: "CafГ©",
    value: "4-4",
    isChild: true,
  },
  {
    label: "Hotel",
    value: "4-5",
    isChild: true,
  },
  {
    label: "Hostel",
    value: "4-6",
    isChild: true,
  },
  {
    label: "Guest House",
    value: "4-7",
    isChild: true,
  },
  {
    label: "Bed and Breakfast",
    value: "4-8",
    isChild: true,
  },
  {
    label: "Shop",
    value: "4-9",
    isChild: true,
  },
  {
    label: "Office",
    value: "4-10",
    isChild: true,
  },
  {
    label: "Storage Room",
    value: "4-11",
    isChild: true,
  },
  {
    label: "Parking Space",
    value: "4-12",
    isChild: true,
  },
  {
    label: "Farm",
    value: "4-13",
    isChild: true,
  },
  {
    label: "Night Club",
    value: "4-15",
    isChild: true,
  },
  {
    label: "Warehouse",
    value: "4-16",
    isChild: true,
  },
  {
    label: "Garage",
    value: "4-17",
    isChild: true,
  },
  {
    label: "Business",
    value: "4-18",
    isChild: true,
  },
  {
    label: "Mooring",
    value: "4-19",
    isChild: true,
  },
  {
    label: "Stables",
    value: "4-20",
    isChild: true,
  },
  {
    label: "Kiosk",
    value: "4-21",
    isChild: true,
  },
  {
    label: "Chiringuito",
    value: "4-22",
    isChild: true,
  },
  {
    label: "Beach Bar",
    value: "4-23",
    isChild: true,
  },
  {
    label: "Mechanics",
    value: "4-24",
    isChild: true,
  },
  {
    label: "Hairdressers",
    value: "4-25",
    isChild: true,
  },
  {
    label: "Photography Studio",
    value: "4-26",
    isChild: true,
  },
  {
    label: "Laundry",
    value: "4-27",
    isChild: true,
  },
  {
    label: "Aparthotel",
    value: "4-28",
    isChild: true,
  },
  {
    label: "Apartment Complex",
    value: "4-29",
    isChild: true,
  },
  {
    label: "Residential Home",
    value: "4-30",
    isChild: true,
  },
  {
    label: "Vineyard",
    value: "4-32",
    isChild: true,
  },
  {
    label: "Olive Grove",
    value: "4-33",
    isChild: true,
  },
  {
    label: "Car Park",
    value: "4-34",
    isChild: true,
  },
  {
    label: "Commercial Premises",
    value: "4-35",
    isChild: true,
  },
  {
    label: "Campsite",
    value: "4-36",
    isChild: true,
  },
  {
    label: "With Residence",
    value: "4-37",
    isChild: true,
  },
  {
    label: "Other",
    value: "4-100",
    isChild: true,
  },
];
