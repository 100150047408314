import React, { useEffect } from "react";
import { LayoutBase } from "shared/ui/layouts";
import { BlogEditForm } from "modules/blog/ui/blog-edit-form";
import { useGate, useStore } from "effector-react";
import { NavLink, useNavigate } from "react-router-dom";
import { blogCreateModel } from "modules/blog/blog.model";
import { Loader } from "shared/ui/loader";

export const BlogCreate = () => {
  const blog = useStore(blogCreateModel.$post);
  const navigate = useNavigate();
  const loading = useStore(blogCreateModel.$loading);

  useGate(blogCreateModel.PostCreateGate, {
    title: "",
    slug: "",
    excerpt: "",
    fields: null,
    attachments: null,
    hidden: true,
  });

  useEffect(() => {
    blog?.id && navigate(`/blog/${blog?.id}/edit`);
  }, [blog?.id]);

  console.log("=====blog create");
  // console.log(blog);

  return (
    <LayoutBase
      title="Create new post in blog"
      actions={
        <>
          <NavLink to="/blog" className="btn btn-secondary btn-sm">
            All blogs
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {!blog?.id && <BlogEditForm blog={blog} />}
    </LayoutBase>
  );
};
