import React from "react";
import type { Field } from "modules/field/field.types";
import { useStore } from "effector-react";
import {
  $fields,
  fieldAdd,
  fieldUpdate,
  fieldChangeOrder,
  fieldRemoveFx,
} from "../../field.model";
import { FieldByType } from "./field-by-type";
import { Box } from "shared/ui/box";
import { ButtonAdd, ButtonRemove } from "shared/ui/buttons";
import { ButtonCollapse, ButtonOrder } from "shared/ui/buttons";
import { FormLabel } from "shared/ui/form-label";

interface Props {
  field: Field;
}

export const FieldRepeater = ({ field }: Props) => {
  const fields = useStore($fields);
  const groups = fields.filter((f) => f.parentId === field.id);

  groups.sort((a, b) => {
    if (a.order && b.order) {
      return a.order > b.order ? 1 : -1;
    }

    return 1;
  });

  const initialField =
    (field.data?.initialFields && field.data?.initialFields[0]) ?? null;

  return (
    <Box>
      <div className="flex items-center">
        {field.label && (
          <div className="flex-1">
            <FormLabel text={`${field.label} (${field.name})`} />
          </div>
        )}

        {/* toggle collapsed */}
        <ButtonCollapse
          className=""
          collapsed={!!field.collapsed}
          onClick={() =>
            fieldUpdate({
              field,
              key: "collapsed",
              value: !field.collapsed,
            })
          }
        />
      </div>
      {/* repeater-groups */}
      {field.collapsed && groups.length > 0 && (
        <>
          {groups.map((group, index) => {
            const prevField = groups[index - 1];
            const nextField = groups[index + 1];

            return (
              <div key={group.id} className="flex items-center">
                <div className="flex-1">
                  <FieldByType field={group} />
                </div>
                {/* remove group fields */}
                {groups.length > 1 && (
                  <div className="flex flex-col w-8 ml-3 gap-1">
                    {/* order prev */}
                    {prevField ? (
                      <ButtonOrder
                        order="up"
                        onClick={() =>
                          fieldChangeOrder({
                            fromField: group,
                            toField: prevField,
                          })
                        }
                      />
                    ) : null}
                    {/* order next */}
                    {nextField ? (
                      <ButtonOrder
                        order="down"
                        onClick={() =>
                          fieldChangeOrder({
                            fromField: group,
                            toField: nextField,
                          })
                        }
                      />
                    ) : null}
                    {/* remove */}
                    <ButtonRemove onClick={() => fieldRemoveFx(group)} />
                  </div>
                )}
              </div>
            );
          })}
          {/* add new repeater group fields */}
          {initialField && (
            <ButtonAdd
              onClick={() =>
                fieldAdd({
                  initialField,
                  parentId: field.id,
                })
              }
              text="Add group fields"
              fullWidth
            />
          )}
        </>
      )}
    </Box>
  );
};
