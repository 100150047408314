import React from "react";
import { logoutUserFx } from "modules/auth/auth.model";
import { menu } from "shared/config/menu";
import cn from "clsx";
import { SidebarItemCollapsed } from "./sidebar-item-collapsed";
import { SidebarItem } from "./sidebar-item";
import { useStore } from "effector-react";
import { $collapsed } from "../sidebar.model";
import { ButtonCollapse } from "./button-collapse";
import { config } from "shared/config";

export const Sidebar = () => {
  const collapsed = useStore($collapsed);
  const handleLogoutClick = () => logoutUserFx();

  return (
    <div
      className={cn(
        "relative min-h-screen bg-gray-700",
        collapsed ? "w-[60px]" : "w-[180px]"
      )}
    >
      <div className="sticky top-0 h-screen flex flex-col overflow-y-auto webkit-overflow-scroll">
        <div
          className={cn(
            "flex items-center h-[50px] p-3 text-white font-bold",
            collapsed && "justify-center"
          )}
        >
          {collapsed ? config.title[0] : config.title}
        </div>

        {menu?.map((item, index) => {
          /* has children */
          if (item.children?.length) {
            return (
              <SidebarItemCollapsed
                key={index}
                path={item.path}
                index={index}
                label={item.label}
                icon={item.icon}
                collapsed={collapsed}
              >
                {item.children.map((child) => {
                  return (
                    <SidebarItem
                      key={child.path}
                      path={child.path}
                      label={child.label}
                      icon={child.icon}
                      collapsed={collapsed}
                      isChildren
                    />
                  );
                })}
              </SidebarItemCollapsed>
            );
          }

          return (
            <SidebarItem
              key={item.path}
              path={item.path}
              label={item.label}
              icon={item.icon}
              collapsed={collapsed}
            />
          );
        })}

        {/* logout */}
        <SidebarItem
          path="/login"
          icon="arrow-right-on-rectangle"
          label="Logout"
          collapsed={collapsed}
          onClick={handleLogoutClick}
        />
        <div className={cn("m-3", collapsed ? "text-center" : "text-right")}>
          <ButtonCollapse />
        </div>
      </div>
    </div>
  );
};
