import { combine, forward, sample, type Store } from "effector";
import {
  $attachments,
  attachmentsRemoveAll,
  attachmentsRevokeAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { propertyModel } from "./property.model";
import { propertySchemaValidate } from "./property.config";
import { type Property } from "shared/api";

export const propertyEdit = propertyModel();

const $pendingLoadingProperty = propertyEdit.loadPropertyByIdFx.pending.map(
  (pending) => !pending
);

sample({
  clock: propertyEdit.PropertyEditGate.open,
  filter: () => $pendingLoadingProperty.getState(),
  target: propertyEdit.loadPropertyByIdFx,
});

// errors validate schema
sample({
  clock: propertyEdit.PropertyEditGate.open,
  fn: () => propertySchemaValidate ?? null,
  target: $validateSchema,
});

// clear
sample({
  clock: [
    propertyEdit.PropertyEditGate.close,
    propertyEdit.removePropertyWithAttachmentsFx.done,
  ],
  filter: () => $pendingLoadingProperty.getState(),
  fn: () => null,
  target: [propertyEdit.$property, attachmentsRemoveAll, $validateSchema],
});

sample({
  clock: propertyEdit.PropertyEditGate.close,
  fn: () => ({}),
  target: $errors,
});

forward({
  from: propertyEdit.loadPropertyByIdFx.doneData,
  to: propertyEdit.$property,
});

// initialize attachments
sample({
  clock: propertyEdit.loadPropertyByIdFx.doneData,
  fn: (clock) => ({
    initialAttachments: clock.attachments,
  }),
  target: propertyEdit.initializeAttachmentsFx,
});

// validate
sample({
  clock: propertyEdit.update,
  target: validateField,
});

sample({
  clock: propertyEdit.submitForm,
  source: propertyEdit.$property,
  fn: (source) => source ?? {},
  target: validateAllFields,
});

sample({
  clock: propertyEdit.submitForm,
  filter: () => {
    return !Object.keys($errors.getState()).length;
  },
  source: combine({
    attachments: $attachments,
    property: propertyEdit.$property as Store<Property>,
  }),
  target: propertyEdit.updatePropertyAttachmentsFx,
});

// clear blob data
sample({
  clock: propertyEdit.updatePropertyAttachmentsFx.doneData,
  target: attachmentsRevokeAll,
});

// new attachments
sample({
  clock: propertyEdit.updatePropertyAttachmentsFx.doneData,
  // source: propertyEdit.updatePropertyAttachmentsFx.doneData,
  fn: (clock) => clock.attachments,
  target: $attachments,
});

// new data
sample({
  clock: propertyEdit.updatePropertyAttachmentsFx.doneData,
  fn: (clock) => clock.property,
  target: propertyEdit.$property,
});
