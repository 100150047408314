import { combine, forward, sample, type Store } from "effector";
import {
  $attachments,
  attachmentsRemoveAll,
  attachmentsRevokeAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { reviewModel } from "./review.model";
import { reviewSchemaValidate } from "./review.config";
import { type Review } from "shared/api";

export const reviewEdit = reviewModel();

const $pendingLoadingReview = reviewEdit.loadReviewByIdFx.pending.map(
  (pending) => !pending
);

sample({
  clock: reviewEdit.ReviewEditGate.open,
  filter: () => $pendingLoadingReview.getState(),
  target: reviewEdit.loadReviewByIdFx,
});

// errors validate schema
sample({
  clock: reviewEdit.ReviewEditGate.open,
  fn: () => reviewSchemaValidate ?? null,
  target: $validateSchema,
});

// clear
sample({
  clock: [
    reviewEdit.ReviewEditGate.close,
    reviewEdit.removeReviewWithAttachmentsFx.done,
  ],
  filter: () => $pendingLoadingReview.getState(),
  fn: () => null,
  target: [reviewEdit.$review, attachmentsRemoveAll, $validateSchema],
});

sample({
  clock: reviewEdit.ReviewEditGate.close,
  fn: () => ({}),
  target: $errors,
});

forward({
  from: reviewEdit.loadReviewByIdFx.doneData,
  to: reviewEdit.$review,
});

// initialize attachments
sample({
  clock: reviewEdit.loadReviewByIdFx.doneData,
  fn: (clock) => ({
    initialAttachments: clock?.attachments ?? null,
  }),
  target: reviewEdit.initializeAttachmentsFx,
});

// validate
sample({
  clock: reviewEdit.update,
  target: validateField,
});

sample({
  clock: reviewEdit.submitForm,
  source: reviewEdit.$review,
  fn: (source) => source ?? {},
  target: validateAllFields,
});

sample({
  clock: reviewEdit.submitForm,
  filter: () => {
    return !Object.keys($errors.getState()).length;
  },
  source: combine({
    attachments: $attachments,
    review: reviewEdit.$review as Store<Review>,
  }),
  target: reviewEdit.updateReviewAttachmentsFx,
});

// clear blob data
sample({
  clock: reviewEdit.updateReviewAttachmentsFx.doneData,
  target: attachmentsRevokeAll,
});

// new attachments
sample({
  clock: reviewEdit.updateReviewAttachmentsFx.doneData,
  // source: reviewEdit.updateReviewAttachmentsFx.doneData,
  fn: (clock) => clock.attachments,
  target: $attachments,
});

// new data
sample({
  clock: reviewEdit.updateReviewAttachmentsFx.doneData,
  fn: (clock) => clock.review,
  target: reviewEdit.$review,
});
