import { createEvent, createStore } from "effector";
import { validate, type ValidateSchema } from "./validation.lib";

export const $errors = createStore<{ [key: string]: string }>({});
export const $validateSchema = createStore<ValidateSchema | null>(null);
export const validateField = createEvent<{ key: string; value: unknown }>();

$errors.on(validateField, (state, { key, value }) => {
  const errors = { ...state };
  const validateSchema = $validateSchema.getState();
  if (validateSchema && validateSchema[key]) {
    const validateStatus = validate({ validators: validateSchema[key], value });
    if (validateStatus) {
      if (validateStatus.isValid) {
        delete errors[key];
      } else {
        errors[key] = validateStatus.message;
      }
    }
    return errors;
  }
});

export const validateAllFields = createEvent<{ [key: string]: unknown }>();
$errors.on(validateAllFields, (state, fields) => {
  const errors = { ...state };
  const validateSchema = $validateSchema.getState();
  if (validateSchema && Object.keys(validateSchema).length) {
    for (let validateField in validateSchema) {
      const validateStatus = validate({
        validators: validateSchema[validateField],
        value: fields[validateField],
      });

      if (validateStatus) {
        if (validateStatus.isValid) {
          delete errors[validateField];
        } else {
          errors[validateField] = validateStatus.message;
        }
      }
    }
    return errors;
  }
});
