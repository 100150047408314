import React, { useMemo } from "react";
import { FormLabel } from "shared/ui/form-label";
import { InputNumber, Textarea } from "shared/ui/inputs";
import { Box } from "shared/ui/box";
import { useGate, useStore } from "effector-react";
import { $errors } from "modules/validation/validation.model";
import { propertyCreate } from "../property-create.model";
import type {
  Property,
  PropertyLocation,
  PropertyListItem,
} from "shared/api/property";
import {
  propertyFeatures,
  propertyStatuses,
  propertyTypes,
  propertyYears,
} from "shared/api/property";

import { ImagePreviewUpload } from "../../attachment/ui/image-preview-upload";
import { SelectBox } from "shared/ui/select-box";
import {
  $locations,
  $provinces,
  LocationGate,
} from "../property-location.model";
import { propertyEdit } from "../property-edit.model";
import { PropertyFeatures } from "./property-features";

export const PropertyBaseControls = ({ property }: { property: Property }) => {
  const isNew = !property?.id;
  const propertyModel = isNew ? propertyCreate : propertyEdit;
  const errors = useStore($errors);
  const allLocations = useStore($locations);
  const provinces = useStore($provinces);
  const locations =
    (property.province &&
      allLocations?.filter((l) => l.parent?.id === property.province?.id)) ??
    [];
  const years = useMemo(propertyYears, []);
  useGate(LocationGate, {
    include: {
      parent: true,
    },
  });
  // console.log("--provinces");
  // console.log(provinces);
  // console.log("--locations");
  // console.log(locations);
  // console.log("--locations");
  // console.log(locations);
  // console.log("--property");
  // console.log(property);
  // console.log("--errors");
  // console.log(errors);

  return (
    <Box>
      <div className="flex flex-col xl:flex-row">
        {/* preview */}
        <div className="flex-1 xl:max-w-[280px] xl:mr-5 pb-10">
          <FormLabel text="Preview" />
          <div className="h-[220px]">
            <ImagePreviewUpload fieldId="preview" />
          </div>
        </div>
        {/* base info */}
        <div className="flex-1 space-y-3">
          {/* row location */}
          <div className="flex flex-wrap gap-3">
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Province" required />
              <SelectBox<PropertyLocation>
                value={property.province}
                items={provinces}
                onChange={(item) => {
                  propertyModel.update({ key: "province", value: item });
                }}
                error={errors.province}
              />
            </div>

            {/* location */}
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Location" />
              <SelectBox<PropertyLocation>
                value={property.location}
                items={locations}
                onChange={(item) => {
                  propertyModel.update({ key: "location", value: item });
                }}
                disabled={!locations.length}
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center gap-3">
            {/* status */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Status" required />
              <SelectBox<PropertyListItem>
                value={
                  propertyStatuses.find((p) => p.value === property.status) ??
                  null
                }
                items={propertyStatuses}
                onChange={(item) =>
                  propertyModel.update({ key: "status", value: item.value })
                }
                error={errors.status}
              />
            </div>

            {/* type */}
            <div className="flex-1 min-w-[240px]">
              <FormLabel text="Type" required />
              <SelectBox<PropertyListItem>
                value={
                  propertyTypes.find((p) => p.value === property.type) ?? null
                }
                items={propertyTypes}
                onChange={(item) =>
                  propertyModel.update({ key: "type", value: item.value })
                }
                error={errors.type}
              />
            </div>

            {/* price */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Price" required />
              <InputNumber
                sizing="sm"
                value={property.price}
                onChange={(e) =>
                  propertyModel.update({
                    key: "price",
                    value: Number(e.target.value),
                  })
                }
                error={errors.price}
                maxLength={8}
                adornment="eu"
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center gap-3">
            {/* year */}
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Year" />
              <SelectBox<PropertyListItem>
                value={
                  years.find((y) => Number(y.value) === property.year) ??
                  years[0]
                }
                items={years}
                onChange={(item) =>
                  propertyModel.update({
                    key: "year",
                    value: Number(item.value),
                  })
                }
                error={errors.status}
              />
            </div>
            {/* build */}
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Built" />
              <InputNumber
                sizing="sm"
                value={property.built}
                onChange={(e) =>
                  propertyModel.update({
                    key: "built",
                    value: Number(e.target.value),
                  })
                }
                error={errors.built}
                maxLength={6}
                adornment="m2"
              />
            </div>
            {/* plot */}
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Plot" />
              <InputNumber
                sizing="sm"
                value={property.plot}
                onChange={(e) =>
                  propertyModel.update({
                    key: "plot",
                    value: Number(e.target.value),
                  })
                }
                error={errors.plot}
                maxLength={6}
                adornment="m2"
              />
            </div>
            {/* terrace */}
            <div className="flex-1 min-w-[160px]">
              <FormLabel text="Terrace" />
              <InputNumber
                sizing="sm"
                value={property.terrace}
                onChange={(e) =>
                  propertyModel.update({
                    key: "terrace",
                    value: Number(e.target.value),
                  })
                }
                error={errors.terrace}
                maxLength={6}
                adornment="m2"
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center gap-3">
            {/* Bathrooms */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Bathrooms" />
              <InputNumber
                sizing="sm"
                value={property.bathrooms}
                onChange={(e) =>
                  propertyModel.update({
                    key: "bathrooms",
                    value: Number(e.target.value),
                  })
                }
                error={errors.bathrooms}
                maxLength={2}
              />
            </div>
            {/* Bedrooms */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Bedrooms" />
              <InputNumber
                sizing="sm"
                value={property.bedrooms}
                onChange={(e) =>
                  propertyModel.update({
                    key: "bedrooms",
                    value: Number(e.target.value),
                  })
                }
                error={errors.bedrooms}
                maxLength={2}
              />
            </div>
            {/* floor */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Floor" />
              <InputNumber
                sizing="sm"
                value={property.floor}
                onChange={(e) =>
                  propertyModel.update({
                    key: "floor",
                    value: Number(e.target.value),
                  })
                }
                error={errors.floor}
                maxLength={2}
              />
            </div>
            {/* pool */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Pool" />
              <InputNumber
                sizing="sm"
                value={property.pool}
                onChange={(e) =>
                  propertyModel.update({
                    key: "pool",
                    value: Number(e.target.value),
                  })
                }
                error={errors.pool}
                maxLength={2}
              />
            </div>
            {/* parking */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Parking" />
              <InputNumber
                sizing="sm"
                value={property.parking}
                onChange={(e) =>
                  propertyModel.update({
                    key: "parking",
                    value: Number(e.target.value),
                  })
                }
                error={errors.parking}
                maxLength={2}
              />
            </div>
            {/* garage */}
            <div className="flex-1 min-w-[100px]">
              <FormLabel text="Garage" />
              <InputNumber
                sizing="sm"
                value={property.garage}
                onChange={(e) =>
                  propertyModel.update({
                    key: "garage",
                    value: Number(e.target.value),
                  })
                }
                error={errors.garage}
                maxLength={2}
              />
            </div>
          </div>
        </div>
      </div>
      {/* features */}
      <div className="mt-5">
        <FormLabel text="Features" />
        <PropertyFeatures property={property} />
      </div>
      {/* description */}
      <div className="mt-5">
        <FormLabel text="Description" />
        <Textarea
          value={property?.description ?? ""}
          onChange={(e) =>
            propertyModel.update({
              key: "description",
              value: e.target.value,
            })
          }
          sizing="md"
          error={errors.description}
        />
      </div>
    </Box>
  );
};
