import React from "react";
import { Loader } from "shared/ui/loader";
import { useGate, useStore } from "effector-react";
import { pagesModel, pagesPaginationModel } from "../page.model";
import { type Page } from "shared/api";
import { useRouterChangePageIndex } from "features/pagination/useRouterChangePageIndex";
import { Pagination } from "features/pagination/ui/pagination";
import { TablePosts } from "shared/ui/table-posts";
import { useNavigate } from "react-router-dom";

export const PageList = () => {
  const pages = useStore(pagesModel.$posts);
  const pending = useStore(pagesModel.$loading);
  useGate(pagesModel.PostsGate);
  const navigate = useNavigate();

  const pagination = useStore(pagesPaginationModel.params);
  useRouterChangePageIndex({
    pageIndex: pagination.pageIndex,
    callback: pagesPaginationModel.changePageIndex,
  });

  console.log(pages);
  return (
    <>
      {pending && <Loader />}
      <Pagination className="mb-3" prefixUrl="page" {...pagination} />
      <TablePosts<Page>
        items={pages}
        colData={{
          title: {
            header: "Title",
            type: "strong-text",
            data: (item) => item.title,
            className: "min-w-[160px]",
          },
          updatedAt: {
            header: "Date edit",
            type: "text",
            data: (item) => new Date(item.updatedAt!).toLocaleString(),
            className: "min-w-[160px]",
          },
          hidden: {
            header: "Hidden",
            type: "hidden",
            data: (item) => item.hidden,
            className: "w-[80px]",
          },
        }}
        onRemove={pagesModel.removePostWithAttachmentsFx}
        onClick={(id) => navigate(`/page/${id}/edit`)}
      />
      <Pagination className="mt-3" prefixUrl="page" {...pagination} />
    </>
  );
};
