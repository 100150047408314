import { combine, sample, type Store } from "effector";
import {
  $attachments,
  attachmentsRemoveAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { propertyModel } from "./property.model";
import { propertySchemaValidate } from "./property.config";
import { type Property } from "shared/api";

export const propertyCreate = propertyModel();
sample({
  clock: [
    propertyCreate.PropertyCreateGate.open,
    propertyCreate.PropertyCreateGate.close,
  ],
  source: propertyCreate.PropertyCreateGate.open,
  target: propertyCreate.$property,
});

// errors validate schema
sample({
  clock: propertyCreate.PropertyCreateGate.open,
  fn: () => propertySchemaValidate ?? null,
  target: $validateSchema,
});

// clear all
sample({
  clock: propertyCreate.PropertyCreateGate.close,
  fn: () => null,
  target: [propertyCreate.$property, attachmentsRemoveAll, $validateSchema],
});

sample({
  clock: propertyCreate.PropertyCreateGate.close,
  fn: () => ({}),
  target: $errors,
});

// validate and updated data
sample({
  clock: propertyCreate.update,
  target: validateField,
});

// validate
sample({
  clock: propertyCreate.submitForm,
  source: propertyCreate.$property,
  fn: (source) => source ?? {},
  target: validateAllFields,
});

sample({
  clock: propertyCreate.submitForm,
  filter: () => {
    return !Object.keys($errors.getState()).length;
  },
  source: combine({
    property: propertyCreate.$property as Store<Property>,
    attachments: $attachments,
  }),
  target: propertyCreate.createPropertyWithAttachmentsFx,
});

sample({
  clock: propertyCreate.createPropertyWithAttachmentsFx.doneData,
  target: propertyCreate.$property,
});
