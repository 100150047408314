import React from "react";
import { Box } from "shared/ui/box";
import { type Attachment } from "shared/api";

interface Props {
  label?: string;
  images: Partial<Attachment>[];
}

export const ImageList = ({ label, images }: Props) => {
  return (
    <Box label={label}>
      <div className="flex flex-wrap justify-center gap-3 mt-3">
        {/* list files */}
        {images.map((image, index) => (
          <div
            key={index}
            className="relative flex-1 min-w-[160px] max-w-[300px]"
          >
            {image.url && (
              <img
                className="h-[180px] w-full object-cover mx-auto rounded-lg shadow-md"
                src={image.url}
                alt={image.alt ?? ""}
                loading={"lazy"}
              />
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};
