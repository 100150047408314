import React from "react";
import type { ButtonProps } from "shared/ui/buttons/button";
import { Button } from "shared/ui/buttons/button";
import { Icon } from "../icon";

interface Props extends Omit<ButtonProps, "children"> {
  text?: string;
  collapsed: boolean;
}

export const ButtonCollapse = (props: Props) => {
  const { collapsed, text, ...rest } = props;
  return (
    <Button variant="square" color="secondary" size="sm" {...rest}>
      {collapsed ? (
        <Icon name="chevron-up" className="w-[20px] h-[20px] stroke-white" />
      ) : (
        <Icon name="chevron-down" className="w-[20px] h-[20px] stroke-white" />
      )}
    </Button>
  );
};
