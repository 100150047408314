import { createEffect, createStore, sample } from "effector";
import { type Block, getBlocks } from "shared/api";
import { createGate } from "effector-react";
import { type FindAllOptions } from "shared/api/types";

// effects
export const loadRelBlocksFx = createEffect({
  handler: async (params?: FindAllOptions) => {
    return await getBlocks(params);
  },
});

export const $relBlocks = createStore<Block[] | null>(null);
export const RelBlocksGate = createGate();
export const $pendingRelBlocks = loadRelBlocksFx.pending;

sample({
  clock: RelBlocksGate.open,
  filter: () => !$pendingRelBlocks.getState(),
  fn: () => ({ select: { id: true, title: true } }),
  target: loadRelBlocksFx,
});

$relBlocks.on(loadRelBlocksFx.doneData, (_, { results }) => results);
