import React, { useEffect } from "react";
import { LayoutBase } from "shared/ui/layouts";
import { BlockEditForm } from "modules/block/ui/block-edit-form";
import { useGate, useStore } from "effector-react";
import { NavLink, useNavigate } from "react-router-dom";
import { blockCreateModel } from "modules/block/block.model";
import { Loader } from "shared/ui/loader";

export const BlockCreate = () => {
  const block = useStore(blockCreateModel.$post);
  const navigate = useNavigate();
  const loading = useStore(blockCreateModel.$loading);

  useGate(blockCreateModel.PostCreateGate, {
    title: "",
    fields: null,
    attachments: null,
  });

  useEffect(() => {
    block?.id && navigate(`/block/${block?.id}/edit`);
  }, [block?.id]);

  console.log("=====block create");
  // console.log(block);

  return (
    <LayoutBase
      title="Create new block"
      actions={
        <>
          <NavLink to="/block" className="btn btn-secondary btn-sm">
            All blocks
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {!block?.id && <BlockEditForm block={block} />}
    </LayoutBase>
  );
};
