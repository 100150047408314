import { sample } from "effector";
import { propertyModel } from "./property.model";
import { paginationModel } from "features/pagination/pagination.model";

const take = 10;
export const propertiesModel = propertyModel();
export const propertiesPaginationModel = paginationModel({
  loadFx: propertiesModel.loadPropertiesFx,
  take,
});

const $pendingLoadingProperties = propertiesModel.loadPropertiesFx.pending.map(
  (pending) => !pending
);

sample({
  clock: propertiesModel.loadPropertiesFx.doneData,
  fn: (clock) => clock.results,
  target: propertiesModel.$properties,
});

sample({
  clock: propertiesModel.PropertiesGate.close,
  filter: () => $pendingLoadingProperties.getState(),
  fn: () => [],
  target: [propertiesModel.$properties, propertiesPaginationModel.reset],
});

sample({
  clock: propertiesModel.removePropertyWithAttachmentsFx.done,
  source: propertiesPaginationModel.params,
  fn: (source) => ({
    take: source.take,
    skip: source.skip,
  }),
  target: propertiesModel.loadPropertiesFx,
});
