import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { NavLink } from "react-router-dom";
import { PropertyList } from "modules/property/ui/property-list";

export const Properties = () => {
  return (
    <LayoutBase
      title="Properties"
      actions={
        <>
          <NavLink to="/property/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
        </>
      }
    >
      <PropertyList />
    </LayoutBase>
  );
};
