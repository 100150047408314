import { getEnvVar } from "../lib/helpers";

export const config = {
  apiUrl: getEnvVar("REACT_APP_API_URL"),
  uploadUrl: getEnvVar("REACT_APP_UPLOAD_URL"),
  title: "marbella",
  upload: {
    allowMimeImages: ["image/png", "image/jpeg"],
    allowMimeDocuments: ["application/pdf"],
  },
};
