import { postModel } from "modules/post-field-attachment/post-field-attachment.model";
import { createPostEvents } from "modules/post-field-attachment/event-create";
import { updatePostEvents } from "modules/post-field-attachment/event-update";
import { blogSchemaValidate, initialBlogContentFields } from "./blog.config";
import {
  type Blog,
  blogCreate,
  blogEdit,
  blogRemove,
  getBlog,
  getBlogs,
} from "shared/api";
import { paginationModel } from "features/pagination/pagination.model";
import { postsPaginationEvents } from "../post-field-attachment/event-list-pagination";

const apiResolvers = {
  loadPosts: getBlogs,
  loadPostById: getBlog,
  createPost: blogCreate,
  updatePost: blogEdit,
  removePost: blogRemove,
};

const postType = "blog";
export const blogCreateModel = postModel<Blog>(postType, apiResolvers);
createPostEvents<Blog>(blogCreateModel, blogSchemaValidate);

export const blogEditModel = postModel<Blog>(postType, apiResolvers);
updatePostEvents<Blog>(
  blogEditModel,
  initialBlogContentFields,
  blogSchemaValidate
);

// results with pagination
export const blogsModel = postModel<Blog>(postType, apiResolvers);
const take = 10;
export const blogsPaginationModel = paginationModel({
  loadFx: blogsModel.loadPostsFx,
  take,
});
postsPaginationEvents<Blog>(blogsModel, blogsPaginationModel);
