import {
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
  sample,
} from "effector";
import {
  type AttachmentByFieldId,
  attachmentUpdateByIds,
  getReview,
  getReviews,
  type Review,
  reviewCreate,
  reviewEdit,
  reviewRemove,
} from "shared/api";
import { createGate } from "effector-react";
import {
  attachmentsInitialize,
  uploadAttachmentsFx,
} from "../attachment/attachment.model";
import { type FindAllOptions } from "shared/api/types";

export const reviewModel = () => {
  const $review = createStore<Review | null>(null);
  const $reviews = createStore<Review[]>([]);
  const $reviewRemovedId = createStore<number | null>(null);
  const ReviewCreateGate = createGate<Review>();
  const ReviewEditGate = createGate<{ id: number }>();
  const ReviewsGate = createGate<FindAllOptions | undefined>();

  const submitForm = createEvent();
  const update = createEvent<{ key: string; value: unknown }>();
  $review.on(update, (state, { key, value }) => {
    if (state) {
      return { ...state, [key]: value };
    }
  });

  const loadReviewsFx = createEffect({
    handler: async (params?: FindAllOptions) => {
      return await getReviews(params);
    },
  });

  const loadReviewByIdFx = createEffect({
    handler: async ({ id }: { id: number }) => {
      return await getReview({ id });
    },
  });

  const initializeAttachmentsFx = createEffect({
    handler: async ({
      initialAttachments,
    }: {
      initialAttachments: AttachmentByFieldId | null;
    }) => {
      attachmentsInitialize({
        initialAttachments,
        fields: null,
        additionalFieldIds: ["avatar"],
      });
    },
  });

  const createReviewWithAttachmentsFx = createEffect({
    handler: async ({
      review,
      attachments,
    }: {
      review: Review;
      attachments: AttachmentByFieldId | null;
    }) => {
      const created = await reviewCreate(review);

      if (attachments) {
        await uploadAttachmentsFx({
          object: "review",
          recordId: created.id as number,
          attachments,
          method: "create",
        });
      }

      return created;
    },
  });

  const updateReviewAttachmentsFx = createEffect({
    handler: async ({
      review,
      attachments,
    }: {
      review: Review;
      attachments: AttachmentByFieldId | null;
    }) => {
      const newAttachments = await uploadAttachmentsFx({
        object: "review",
        recordId: review.id!,
        attachments,
        method: "update",
      });
      return {
        review: {
          ...(await reviewEdit(review)),
          attachments: newAttachments,
        },
        attachments: newAttachments,
      };
    },
  });

  const removeReviewWithAttachmentsFx = createEffect({
    handler: async (id: number): Promise<number> => {
      if (id) {
        // remove all attachments
        await attachmentUpdateByIds({
          object: "review",
          recordId: id,
          loadedAttachmentIds: [],
        });
      }
      return await reviewRemove(id);
    },
  });

  sample({
    clock: removeReviewWithAttachmentsFx.doneData,
    target: $reviewRemovedId,
  });

  const $loading = restore(
    merge([
      loadReviewByIdFx.pending,
      loadReviewsFx.pending,
      updateReviewAttachmentsFx.pending,
      createReviewWithAttachmentsFx.pending,
      removeReviewWithAttachmentsFx.pending,
      uploadAttachmentsFx.pending,
    ]),
    false
  );

  return {
    $review,
    $reviews,
    $reviewRemovedId,
    $loading,
    ReviewCreateGate,
    ReviewEditGate,
    ReviewsGate,
    submitForm,
    update,
    initializeAttachmentsFx,
    loadReviewsFx,
    loadReviewByIdFx,
    createReviewWithAttachmentsFx,
    updateReviewAttachmentsFx,
    removeReviewWithAttachmentsFx,
  };
};
