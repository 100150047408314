export type FieldType =
  | "block"
  | "block-static"
  | "block-rel"
  | "repeater"
  | "repeater-group"
  | "text"
  | "textarea"
  | "text-editor"
  | "image"
  | "icon"
  | "button"
  | "select";

export interface Field {
  id?: string;
  parentId?: string;
  type: FieldType;
  name: string;
  label: string;
  data?: {
    initialFields?: Field[];
    [key: string]: unknown;
  };
  order?: number;
  collapsed?: boolean;
}

export enum ModalTypesEnums {
  "ONLINE_CONSULTATION" = "Online consultation",
  "CALLBACK" = "Callback",
  "SPECIALIST_CONSULTATION" = "Specialist consultation",
  "ORDER_YACHT" = "Order yacht",
  "EVALUATE_PROPERTY" = "Evaluate property",
  "SELL_PROPERTY" = "Sell property",
  "SUBSCRIBE" = "Subscribe now",
  "ORDER_JET" = "Order Private jet charter!",
}

export type TextDataType = {
  value: string;
};

export type BlockRelationDataType = {
  blockId: number | null;
};

export type ButtonAsType = "link" | "modal";

export type ButtonDataType = {
  as: ButtonAsType;
  href?: string;
  text?: string;
  modal?: string;
};

export type IconDataType = {
  icon: string;
};

export type SelectDataItem = { id?: string | number; label: string };

export type SelectDataType = {
  initialData?: SelectDataItem[];
  selected?: SelectDataItem;
};

export type AttachmentDataType = {
  multiple: boolean;
};

export type RepeaterDataType = {
  initialFields?: Field[];
};

export type RepeaterGroupDataType = {
  initialFields?: Field[];
};
