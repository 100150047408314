import { sample } from "effector";
import { type PostModel } from "./post-field-attachment.model";

export const postsEvents = <T>(model: PostModel<T>) => {
  const $pendingLoadingPages = model.loadPostsFx.pending.map(
    (pending) => !pending
  );
  model.$posts.on(model.loadPostsFx.doneData, (_, { results }) => results);

  sample({
    clock: model.PostsGate.open,
    filter: () => $pendingLoadingPages.getState(),
    fn: (clock) => clock,
    target: model.loadPostsFx,
  });

  sample({
    clock: model.PostsGate.close,
    filter: () => $pendingLoadingPages.getState(),
    fn: () => [],
    target: model.$posts,
  });

  sample({
    clock: model.removePostWithAttachmentsFx.done,
    source: model.PostsGate.open,
    target: model.loadPostsFx,
  });
};
