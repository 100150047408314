import React, { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Loader } from "shared/ui/loader";
import { useStore } from "effector-react";
import { $authPending, $isAuthenticated } from "modules/auth/auth.model";

interface Props {
  children?: ReactNode;
}

export const PublicRoute = ({ children }: Props) => {
  const isAuthenticated = useStore($isAuthenticated);
  const loading = useStore($authPending);

  if (loading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Navigate to={"/"} replace />;
  }

  return children ? (children as JSX.Element) : <Outlet />;
};
