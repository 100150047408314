import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "modules/auth/ui/routes/private-route";
import { PublicRoute } from "modules/auth/ui/routes/public-route";
import { Page404 } from "pages/page404";
import { LoginPage } from "pages/login";
import { Dashboard } from "./dashboard";
import { Blogs } from "./blog/blogs";
import { BlogCreate } from "./blog/blog-create";
import { BlogEdit } from "./blog/blog-edit";
import { Pages } from "./page/pages";
import { PageCreate } from "./page/page-create";
import { PageEdit } from "./page/page-edit";
import { Blocks } from "./block/blocks";
import { BlockCreate } from "./block/block-create";
import { BlockEdit } from "./block/block-edit";
import { Reviews } from "./review/reviews";
import { ReviewCreate } from "./review/review-create";
import { ReviewEdit } from "./review/review-edit";
import { Properties } from "./property/properties";
import { PropertyCreate } from "./property/property-create";
import { PropertyEdit } from "./property/property-edit";
import { PropertyLocation } from "./property/property-location";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />

        {/* properties */}
        <Route path="/property" element={<Properties />} />
        <Route path="/property/location" element={<PropertyLocation />} />
        <Route path="/property/page/:page" element={<Properties />} />
        <Route path="/property/create" element={<PropertyCreate />} />
        <Route path="/property/:id/edit" element={<PropertyEdit />} />
        {/* pages */}
        <Route path="/page" element={<Pages />} />
        <Route path="/page/page/:page" element={<Pages />} />
        <Route path="/page/create" element={<PageCreate />} />
        <Route path="/page/:id/edit" element={<PageEdit />} />
        {/* blogs */}
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/page/:page" element={<Blogs />} />
        <Route path="/blog/create" element={<BlogCreate />} />
        <Route path="/blog/:id/edit" element={<BlogEdit />} />
        {/* blocks */}
        <Route path="/block" element={<Blocks />} />
        <Route path="/block/page/:page" element={<Blocks />} />
        <Route path="/block/create" element={<BlockCreate />} />
        <Route path="/block/:id/edit" element={<BlockEdit />} />
        {/* reviews */}
        <Route path="/review" element={<Reviews />} />
        <Route path="/review/page/:page" element={<Reviews />} />
        <Route path="/review/create" element={<ReviewCreate />} />
        <Route path="/review/:id/edit" element={<ReviewEdit />} />
      </Route>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
