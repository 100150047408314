import React, { type InputHTMLAttributes } from "react";
import cn from "clsx";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  color?: "primary" | "secondary";
}

export const InputCheckbox = ({ className, color, ...rest }: Props) => {
  return (
    <input
      type="checkbox"
      className={cn(className, "checkbox", {
        "checkbox-primary": color === "primary",
        "checkbox-secondary": color === "secondary",
      })}
      {...rest}
    />
  );
};
