import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { PropertyLocationForm } from "modules/property/ui/property-location-form";

export const PropertyLocation = () => {
  return (
    <LayoutBase title="Locations">
      <PropertyLocationForm />
    </LayoutBase>
  );
};
