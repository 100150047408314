import cn from "clsx";
import { Icon } from "shared/ui/icon";
import { type EditorMenuItemProps } from "./types";

export const MenuItem = ({
  action,
  disabled = false,
  isActive,
  icon,
}: EditorMenuItemProps) => {
  const active = isActive ? isActive() : false;
  return (
    <button
      className={cn(
        "btn btn-square btn-sm border-none flex items-center justify-center rounded-md transition-all group disabled:bg-gray-100 disabled:opacity-50",
        active ? "bg-indigo-600 hover:bg-indigo-600" : "bg-gray-100"
      )}
      disabled={disabled}
      onClick={action}
    >
      {icon && (
        <Icon
          name={icon}
          className={cn("w-[18px] h-[18px] group-hover:fill-white", {
            "fill-white": active,
          })}
        />
      )}
    </button>
  );
};
