import React, { ReactNode } from "react";
import { $authPending, $isAuthenticated } from "modules/auth/auth.model";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Loader } from "shared/ui/loader";
import { useStore } from "effector-react";

interface Props {
  children?: ReactNode;
}

export const PrivateRoute = ({ children }: Props) => {
  const isAuthenticated = useStore($isAuthenticated);
  const loading = useStore($authPending);
  const location = useLocation();

  if (loading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate to={"/login"} state={{ from: location.pathname }} replace />
    );
  }

  return children ? (children as JSX.Element) : <Outlet />;
};
