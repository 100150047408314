import { useGate, useStore } from "effector-react";
import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { LayoutBase } from "shared/ui/layouts";
import { BlogEditForm } from "modules/blog/ui/blog-edit-form";
import { blogEditModel } from "modules/blog/blog.model";
import { Loader } from "shared/ui/loader";

export const BlogEdit = () => {
  const params = useParams<{ id?: string }>();
  const blog = useStore(blogEditModel.$post);
  const blogRemovedId = useStore(blogEditModel.$postRemovedId);
  const loading = useStore(blogEditModel.$loading);
  const navigate = useNavigate();

  const blogId = Number(params.id);
  useGate(blogEditModel.PostEditGate, { postId: blogId });

  useEffect(() => {
    blogRemovedId === blogId && navigate("/blog");
  }, [blogRemovedId]);

  console.log("=====blog edit");
  // console.log(blog);

  return (
    <LayoutBase
      title={`Edit post`}
      subtitle={`#${blogId}: ${blog?.title ?? ""}`}
      actions={
        <>
          <NavLink to="/blog/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
          <NavLink to="/blog" className="btn btn-secondary btn-sm">
            All blogs
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {blog && <BlogEditForm blog={blog} />}
    </LayoutBase>
  );
};
