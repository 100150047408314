import React, { type MouseEvent, useRef, useState } from "react";
import { Button, type ButtonProps } from "shared/ui/buttons/button";
import cn from "clsx";
import { useOnClickOutside } from "shared/hooks/use-on-click-outside";
import { Icon } from "../icon";

interface Props extends Omit<ButtonProps, "children"> {
  text?: string;
}

export const ButtonRemove = (props: Props) => {
  const { onClick, text, ...rest } = props;
  const [open, setOpen] = useState(false);
  const refDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside(refDropdown, () => setOpen(false));

  const handleDropdownClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
    onClick && onClick();
  };

  return (
    <div
      className={cn("flex justify-end dropdown dropdown-top dropdown-end", {
        "dropdown-open": open,
      })}
      ref={refDropdown}
    >
      <Button
        variant={text ? "icon-text" : "square"}
        color="error"
        size="sm"
        {...rest}
        onClick={() => setOpen(!open)}
      >
        <Icon name="trash" className="w-[20px] h-[20px] stroke-black" />
        {text && text}
      </Button>
      <ul
        tabIndex={0}
        className={cn(
          "dropdown-content menu menu-compact shadow bg-red-200 bg-base-100 p-1 rounded-lg",
          { hidden: !open }
        )}
      >
        <li onClick={handleDropdownClick}>
          <a
            href="/#"
            className="hover:bg-error active:bg-error hover:text-white"
          >
            Remove
          </a>
        </li>
      </ul>
    </div>
  );
};
