import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { InputToggle } from "shared/ui/inputs";
import { blogCreateModel, blogEditModel } from "../blog.model";
import { Button } from "shared/ui/buttons/button";
import { ButtonRemove } from "shared/ui/buttons";
import { Box } from "shared/ui/box";
import { type Blog } from "shared/api";

interface Props {
  blog: Blog;
}

export const BlogRightPanelControls = ({ blog }: Props) => {
  const isNewBlog = !blog?.id;
  return (
    <Box className="space-y-3">
      {isNewBlog ? (
        <>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={blog?.hidden}
              onChange={() =>
                blogCreateModel.update({
                  key: "hidden",
                  value: !blog?.hidden,
                })
              }
            />
          </div>

          <Button color="accent" onClick={blogCreateModel.submitForm} fullWidth>
            Save new blog
          </Button>
        </>
      ) : (
        <>
          <div>
            <FormLabel text="Created:" inline />
            {blog?.createdAt && new Date(blog.createdAt).toLocaleString()}
          </div>
          <div>
            <FormLabel text="Updated:" inline />
            {blog?.updatedAt && new Date(blog.updatedAt).toLocaleString()}
          </div>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={blog.hidden}
              onChange={() =>
                blogEditModel.update({
                  key: "hidden",
                  value: !blog.hidden,
                })
              }
            />
          </div>
          <div className="text-right">
            <ButtonRemove
              text="Remove"
              onClick={() =>
                blogEditModel.removePostWithAttachmentsFx(blog.id!)
              }
            />
          </div>
          <Button color="accent" onClick={blogEditModel.submitForm} fullWidth>
            Update blog
          </Button>
        </>
      )}
    </Box>
  );
};
