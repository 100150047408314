import React from "react";
import { $collapsed, setCollapsed } from "../sidebar.model";
import { Button } from "shared/ui/buttons/button";
import { useStore } from "effector-react";
import { Icon } from "shared/ui/icon";

export const ButtonCollapse = () => {
  const sidebarCollapsed = useStore($collapsed);
  return (
    <Button
      onClick={setCollapsed}
      color="light"
      variant="square"
      outline
      size="sm"
    >
      {sidebarCollapsed ? (
        <Icon name="chevron-double-right" className="stroke-white" />
      ) : (
        <Icon name="chevron-double-left" className="stroke-white" />
      )}
    </Button>
  );
};
