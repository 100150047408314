import React, { ReactNode } from "react";
import cn from "clsx";

interface Props {
  content: ReactNode;
  rightPanel: ReactNode;
  readonly?: boolean;
}

export const LayoutPostEdit = ({
  content,
  rightPanel,
  readonly = false,
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col lg:flex-row",
        readonly && "pointer-events-none opacity-70"
      )}
    >
      {/* content */}
      <div className="flex-1">{content}</div>
      {/* right panel */}
      <div className="lg:ml-5 lg:w-[260px]">
        <div className="sticky top-[60px]">{rightPanel}</div>
      </div>
    </div>
  );
};
