import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { Input, Textarea } from "shared/ui/inputs";
import { Box } from "shared/ui/box";
import { type Review } from "shared/api";
import { useStore } from "effector-react";
import { $errors } from "modules/validation/validation.model";
import { reviewCreate } from "../review-create.model";
import { reviewEdit } from "../review-edit.model";
import { Rating } from "shared/ui/rating";
import { ImagePreviewUpload } from "../../attachment/ui/image-preview-upload";

export const ReviewBaseControls = ({ review }: { review: Review }) => {
  const isNew = !review?.id;
  const reviewModel = isNew ? reviewCreate : reviewEdit;
  const errors = useStore($errors);

  return (
    <Box className="flex">
      {/* avatar */}
      <div className="flex-1 max-w-[280px] mr-5">
        <FormLabel text="Avatar" />
        <div className="h-[220px]">
          <ImagePreviewUpload fieldId="avatar" />
        </div>
      </div>
      {/* base info */}
      <div className="flex-1 space-y-3">
        <div>
          {/* title */}
          <FormLabel text="Title" />
          <Input
            type="text"
            sizing="sm"
            value={review?.title ?? ""}
            onChange={(e) =>
              reviewModel.update({
                key: "title",
                value: e.target.value as string,
              })
            }
            error={errors.title}
          />
        </div>

        {/* text */}
        <div>
          <FormLabel text="Text" />
          <Textarea
            value={review?.text ?? ""}
            onChange={(e) =>
              reviewModel.update({
                key: "text",
                value: e.target.value,
              })
            }
            sizing="md"
            error={errors.text}
          />
        </div>

        {/* author */}
        <div>
          <FormLabel text="Author" />
          <Input
            type="text"
            sizing="sm"
            value={review?.author ?? ""}
            onChange={(e) =>
              reviewModel.update({
                key: "author",
                value: e.target.value as string,
              })
            }
          />
        </div>

        {/* rating */}
        <div className="mt-3">
          <FormLabel text="Rating" />
          <Rating
            size="lg"
            rate={review?.rating}
            onChange={(rate) =>
              reviewModel.update({
                key: "rating",
                value: rate,
              })
            }
          />
        </div>
      </div>
    </Box>
  );
};
