import React, { type InputHTMLAttributes } from "react";
import cn from "clsx";
import { PopupError } from "shared/ui/popup-error";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizing?: "xs" | "sm" | "md" | "lg";
  error?: string;
  className?: string;
  adornment?: "eu" | "m2";
}

export const Input = ({
  sizing,
  id,
  error,
  adornment,
  className,
  ...rest
}: InputProps) => {
  return (
    <div className="relative w-full">
      {error && <PopupError message={error} />}
      {adornment && (
        <span className="absolute h-full p-[2px]">
          <span className="flex items-center justify-center w-[30px] h-full bg-gray-100 rounded-md">
            {adornment === "m2" && (
              <>
                m<sup>2</sup>
              </>
            )}
            {adornment === "eu" && <>&euro;</>}
          </span>
        </span>
      )}
      <input
        className={cn(className, "input input-bordered w-full", {
          "input-sm": sizing === "sm",
          "input-md": sizing === "md",
          "input-error": error,
          "pl-[35px]": adornment,
        })}
        {...rest}
      />
    </div>
  );
};
