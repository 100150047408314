import { useGate, useStore } from "effector-react";
import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { LayoutBase } from "shared/ui/layouts";
import { PageEditForm } from "modules/page/ui/page-edit-form";
import { pageEditModel } from "modules/page/page.model";
import { Loader } from "shared/ui/loader";

export const PageEdit = () => {
  const params = useParams<{ id?: string }>();
  const page = useStore(pageEditModel.$post);
  const pageRemovedId = useStore(pageEditModel.$postRemovedId);
  const loading = useStore(pageEditModel.$loading);
  const navigate = useNavigate();

  const pageId = Number(params.id);
  useGate(pageEditModel.PostEditGate, { postId: pageId });

  useEffect(() => {
    pageRemovedId === pageId && navigate("/page");
  }, [pageRemovedId]);

  console.log("=====page edit");
  // console.log(page);

  return (
    <LayoutBase
      title={`Edit page`}
      subtitle={`#${pageId}: ${page?.title ?? ""}`}
      actions={
        <>
          <NavLink to="/page/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
          <NavLink to="/page" className="btn btn-secondary btn-sm">
            All pages
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {page && <PageEditForm page={page} />}
    </LayoutBase>
  );
};
