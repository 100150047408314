import React from "react";

interface Props {
  message: string;
}

export const PopupError = ({ message }: Props) => {
  return (
    <>
      <div className="absolute -top-3 left-1 badge badge-error text-white z-10">
        {message}
      </div>
    </>
  );
};
