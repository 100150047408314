import React, { type InputHTMLAttributes } from "react";
import cn from "clsx";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const InputToggle = ({ className, ...rest }: Props) => {
  return (
    <input
      type="checkbox"
      className={cn(className, "toggle toggle-primary")}
      {...rest}
    />
  );
};
