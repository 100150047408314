import React, { ChangeEvent, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  inputId: string;
  type?: "area" | "button";
  multiple?: boolean;
  accept?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const UploadArea = ({
  inputId,
  type = "area",
  multiple = false,
  accept = "image/png,image/jpeg,application/pdf",
  onChange,
  children,
}: Props) => {
  const id = `file-upload|${inputId}`;

  const InputFile = () => (
    <input
      id={id}
      name={id}
      type="file"
      className="sr-only"
      accept={accept}
      multiple={multiple}
      onChange={onChange}
    />
  );

  return (
    <label htmlFor={id} className="relative cursor-pointer h-full">
      {type === "area" && (
        <div className="h-full flex justify-center items-center px-6 pt-5 pb-6 rounded-md border-2 border-dashed border-gray-300">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-gray-600 justify-center">
              <span>{multiple ? "Upload a files" : "Upload a file"}</span>
              <InputFile />
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      )}
      {/* button */}
      {type === "button" && (
        <>
          {children}
          <div className="w-full btn btn-secondary btn-sm">
            <span>{multiple ? "Upload a files" : "Upload a file"}</span>
          </div>
          <InputFile />
        </>
      )}
    </label>
  );
};
