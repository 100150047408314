import React from "react";
import { Link } from "react-router-dom";
import cn from "clsx";

interface Props {
  prefixUrl: string;
  skip: number;
  take: number;
  total: number;
  pageIndex: number;
  className?: string;
}

export const Pagination = ({
  skip,
  take,
  total,
  pageIndex,
  prefixUrl,
  className,
}: Props) => {
  if (!total || take >= total) return null;
  const countButtons = Math.ceil(total / take);
  return (
    <div className={cn("btn-group", className)}>
      {[...new Array(countButtons)].map((_, index) => {
        return (
          <Link
            className={cn("btn", {
              "btn-active":
                pageIndex - 1 === index || (pageIndex === 0 && index === 0),
            })}
            key={index}
            to={`/${prefixUrl}/page/${index + 1}`}
          >
            {index + 1}
          </Link>
        );
      })}
    </div>
  );
};
