import React from "react";
import { AuthErrors } from "shared/api";
import { Alert } from "shared/ui/alert";

interface Props {
  errors: AuthErrors;
}

export const FormErrors = ({ errors }: Props) => {
  return (
    <Alert variant="warning">
      {Object.values(errors).map((error, index) => {
        return <span key={index}>{error}</span>;
      })}
    </Alert>
  );
};
