import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { Textarea, Input } from "shared/ui/inputs";
import { pageCreateModel, pageEditModel } from "../page.model";
import { Box } from "shared/ui/box";
import { type Page } from "shared/api";
import { useStore } from "effector-react";
import { $errors } from "modules/validation/validation.model";
import { PageParentSelect } from "./page-parent-select";

interface Props {
  page: Page;
}

export const PageBaseControls = ({ page }: Props) => {
  const isNew = !page?.id;
  const pageModel = isNew ? pageCreateModel : pageEditModel;
  const errors = useStore($errors);

  return (
    <Box className="flex">
      {/* base info */}
      <div className="flex-1 space-y-3">
        <div>
          {/* title */}
          <FormLabel text="Title" />
          <Input
            type="text"
            sizing="sm"
            value={page?.title}
            onChange={(e) =>
              pageModel.update({
                key: "title",
                value: e.target.value as string,
              })
            }
            error={errors.title}
          />
        </div>

        {/* select parent */}
        <div>
          <FormLabel text="Parent page" />
          <PageParentSelect
            id={page.id}
            parentId={page.parentId}
            isNew={isNew}
          />
        </div>

        {/* slug */}
        <div>
          <FormLabel text="Slug (not required)" />
          <Input
            type="text"
            sizing="sm"
            value={page?.slug}
            onChange={(e) =>
              pageModel.update({
                key: "slug",
                value: e.target.value,
              })
            }
          />
        </div>

        {/* exception */}
        <div>
          <FormLabel text="Excerpt" />
          <Textarea
            value={page?.excerpt ?? ""}
            onChange={(e) =>
              pageModel.update({
                key: "excerpt",
                value: e.target.value,
              })
            }
          />
        </div>
      </div>
    </Box>
  );
};
