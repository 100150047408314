import React, { type ChangeEvent } from "react";
import { UploadArea } from "shared/ui/upload-area";
import { ButtonRemove } from "shared/ui/buttons";
import { useStore } from "effector-react";
import {
  $attachments,
  attachmentsChange,
} from "modules/attachment/attachment.model";
import { getAttachmentUrl } from "../attachment.lib";

export const ImagePreviewUpload = ({ fieldId }: { fieldId: string }) => {
  const attachments = useStore($attachments);
  const attachment =
    attachments && attachments[fieldId]?.length
      ? attachments[fieldId][0]
      : null;

  const handleAttachmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files as FileList;
    if (newFile) {
      attachmentsChange({
        fieldId,
        newFiles: newFile,
        allowType: "image",
      });
    }
  };

  return (
    <>
      <UploadArea
        inputId="preview"
        type={attachment ? "button" : "area"}
        onChange={handleAttachmentChange}
        multiple={false}
      >
        {/* loaded image */}
        {attachment?.url && (
          <div className="max-h-[230px] h-[calc(100%-30px)] w-full mb-3 border">
            {/* loaded image */}
            <img
              src={getAttachmentUrl(attachment)}
              alt=""
              className="h-full w-full object-cover object-top rounded-lg"
              loading={"lazy"}
            />
            {/* remove */}
            <div className="absolute top-2 right-2">
              <ButtonRemove
                onClick={() =>
                  attachmentsChange({
                    fieldId,
                    newFiles: null,
                  })
                }
              />
            </div>
          </div>
        )}
      </UploadArea>
    </>
  );
};
