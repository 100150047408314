import React, { useMemo } from "react";
import type {
  Field,
  SelectDataItem,
  SelectDataType,
} from "modules/field/field.types";
import { Box } from "shared/ui/box";
import { fieldChangeData } from "../../field.model";
import { SelectBox } from "shared/ui/select-box";

interface Props {
  field: Field;
}

export const FieldTypeSelect = ({ field }: Props) => {
  const data = field.data as SelectDataType;
  const selectList = data.initialData;
  const selected = data.selected;

  return useMemo(() => {
    if (!selectList?.length) return null;

    const activeItem = selected
      ? selectList.find((item) => item.id === selected.id) ?? {
          label: "Select",
        }
      : { label: "Select" };

    return (
      <Box label={`${field.label} (${field.name})`}>
        <SelectBox<SelectDataItem>
          value={activeItem}
          items={selectList}
          onChange={(item) =>
            fieldChangeData({
              field,
              key: "selected",
              value: { id: item.id, label: item.label },
            })
          }
          expandUp
        />
      </Box>
    );
  }, [data?.initialData, data?.selected, field]);
};
