import React, { ReactNode, useMemo } from "react";
import type { Field } from "modules/field/field.types";
import { Box } from "shared/ui/box";
import { fieldChangeData } from "../../../field.model";
import { SelectBox } from "shared/ui/select-box";
import { Icon } from "shared/ui/icon";
import { type IconDataType } from "modules/field/field.types";
import { selectListIcons } from "./select-icons";

interface Props {
  field: Field;
}

export const FieldIconSelect = ({ field }: Props) => {
  const data = field.data as IconDataType;

  return useMemo(() => {
    const icons = selectListIcons.map((icon) => ({
      label: icon.label,
      iconNode: (
        <Icon
          name={icon.label}
          className="stroke-[#428AB5] w-[24px] h-[24px]"
        />
      ),
    }));

    const activeIcon = data?.icon
      ? icons.find((icon) => icon.label === data.icon) ?? { label: "Select" }
      : { label: "Select" };

    return (
      <Box label={`${field.label} (${field.name})`}>
        <SelectBox<{ label: string; icon?: ReactNode }>
          value={activeIcon}
          items={icons}
          onChange={(item) =>
            fieldChangeData({ field, key: "icon", value: item.label })
          }
        />
      </Box>
    );
  }, [data?.icon, field]);
};
