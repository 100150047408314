export type ValidatorType = "required";
export type ValidateResponse = { isValid: boolean; message: string };
export type ValidateSchema = {
  [key: string]: ValidatorType[];
};
export const required = (value?: unknown): ValidateResponse => {
  const isValid = !!value;
  return { isValid, message: isValid ? "" : "Not empty" };
};

export const validate = ({
  validators,
  value,
}: {
  validators: ValidatorType[];
  value: unknown;
}): ValidateResponse | null => {
  let error: ValidateResponse | null = null;

  if (validators?.length) {
    validators.forEach((validator) => {
      if (validator === "required") {
        error = required(value as string);
      }
    });
  }

  return error;
};
