export const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || "";
};

export const uid = () => Math.random().toString(36).slice(2, 9);
// export const uid = () =>
//   String(Date.now().toString(32) + Math.random().toString(16)).replace(
//     /\./g,
//     ""
//   );

// export const getNumberFromString = (value: string) => {
//   if (value && value.match(/\d?/is)) {
//     const amount = Number(value);
//     if (!isNaN(amount)) {
//       return amount;
//     }
//   }
//   return false;
// };
//
// import { ChangeEvent, RefObject } from "react";
//
// export const imageToCanvasResize = (
//   file: File,
//   refCanvas: RefObject<HTMLCanvasElement>
// ) => {
//   const maxSideInPixel = 320;
//   const canvas = refCanvas.current;
//   /// const file = e?.target?.files?.length ? (e.target.files[0] as File) : null;
//   const ctx = canvas?.getContext("2d");
//   console.log(refCanvas.current);
//
//   if (canvas && ctx && file) {
//     const reader = new FileReader();
//     reader.onload = function (event) {
//       const img = new Image();
//
//       img.onload = function () {
//         if (img.width > img.height) {
//           canvas.width = maxSideInPixel;
//           canvas.height = Math.round((img.height * maxSideInPixel) / img.width);
//         } else {
//           canvas.width = Math.round((img.width * maxSideInPixel) / img.height);
//           canvas.height = maxSideInPixel;
//         }
//         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//       };
//       console.log("event.target?.result");
//       console.log(event.target?.result);
//       if (event.target?.result) {
//         img.src = event.target?.result as string;
//       }
//     };
//
//     reader.readAsDataURL(file);
//   }
// };
