import { combine, createEvent, createStore, sample } from "effector";
import { loginUserFx } from "modules/auth/auth.model";
import type { AuthErrors } from "shared/api";

export const loginChanged = createEvent<string>();
export const passwordChanged = createEvent<string>();
export const loginFormSubmit = createEvent();

export const $login = createStore<string>("");
export const $password = createStore<string>("");
export const $errors = createStore<AuthErrors | null>(null);

$login.on(loginChanged, (_, login) => login);
$password.on(passwordChanged, (_, password) => password);

$errors.on(
  loginUserFx.fail,
  (_, error) => error.error as unknown as AuthErrors
);
$errors.on(loginUserFx.doneData, (_, user) => null);

export const $loginFormState = combine({ login: $login, password: $password });
export const $loginFormPending = loginUserFx.pending;

sample({
  source: $loginFormState,
  clock: loginFormSubmit,
  target: loginUserFx,
});
