import { Editor } from "@tiptap/react/dist/packages/react/src/Editor";
import { Fragment } from "react";
import { MenuItem } from "./menu-item";
import { useEditorItems } from "./hooks";

export const MenuBar = ({ editor }: { editor: Editor }) => {
  const items = useEditorItems(editor);
  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-1">
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === "divider" ? (
            <div className="px-1" />
          ) : (
            <MenuItem {...item} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
