import React, { type TextareaHTMLAttributes } from "react";
import cn from "clsx";
import { PopupError } from "shared/ui/popup-error";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  sizing?: "xs" | "sm" | "md" | "lg";
  error?: string;
  className?: string;
}

export const Textarea = ({
  sizing = "sm",
  error,
  className,
  ...rest
}: Props) => {
  return (
    <div className="relative w-full">
      {error && <PopupError message={error} />}
      <textarea
        className={cn(
          className,
          "textarea textarea-bordered textarea-sm w-full text-base",
          {
            "h-[100px]": sizing === "sm",
            "h-[160px]": sizing === "md",
            "h-[200px]": sizing === "lg",
            "textarea-error": error,
          }
        )}
        {...rest}
      />
    </div>
  );
};
