import React from "react";
import type { Field } from "modules/field/field.types";
import { FieldRepeaterGroup } from "./field-repeater-group";
import { FieldText } from "./field-text";
import { FieldRepeater } from "./field-repeater";
import { FieldImage } from "./field-image";
import { FieldIconSelect } from "./field-icon-select";
import { FieldButton } from "./field-button";
import { FieldBlockRelation } from "./field-block-relation";
import { FieldTextarea } from "./field-textarea";
import { FieldTypeSelect } from "./field-select";
import { FieldTextEditor } from "./field-text-editor";

interface Props {
  field: Field;
}

export const FieldByType = ({ field }: Props) => {
  switch (field.type) {
    case "repeater-group":
      return <FieldRepeaterGroup field={field} />;
    case "repeater":
      return <FieldRepeater field={field} />;
    case "block-rel":
      return <FieldBlockRelation field={field} />;
    case "text":
      return <FieldText field={field} />;
    case "textarea":
      return <FieldTextarea field={field} />;
    case "image":
      return <FieldImage field={field} />;
    case "icon":
      return <FieldIconSelect field={field} />;
    case "button":
      return <FieldButton field={field} />;
    case "select":
      return <FieldTypeSelect field={field} />;
    case "text-editor":
      return <FieldTextEditor field={field} />;
    default:
      return (
        <div className="my-3 p-3 bg-red-400 rounded-md">
          Not assigned to component with type: {field.type}
        </div>
      );
  }
};
