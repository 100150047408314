import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { InputToggle } from "shared/ui/inputs";
import { pageCreateModel, pageEditModel } from "../page.model";
import { Button } from "shared/ui/buttons/button";
import { ButtonRemove } from "shared/ui/buttons";
import { Box } from "shared/ui/box";
import { type Page } from "shared/api";

interface Props {
  page: Page;
}

export const PageRightPanelControls = ({ page }: Props) => {
  const isNewPage = !page?.id;
  return (
    <Box className="space-y-3">
      {isNewPage ? (
        <>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={page?.hidden}
              onChange={() =>
                pageCreateModel.update({
                  key: "hidden",
                  value: !page?.hidden,
                })
              }
            />
          </div>

          <Button color="accent" onClick={pageCreateModel.submitForm} fullWidth>
            Save new page
          </Button>
        </>
      ) : (
        <>
          <div>
            <FormLabel text="Created:" inline />
            {page?.createdAt && new Date(page.createdAt).toLocaleString()}
          </div>
          <div>
            <FormLabel text="Updated:" inline />
            {page?.updatedAt && new Date(page.updatedAt).toLocaleString()}
          </div>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={page.hidden}
              onChange={() =>
                pageEditModel.update({
                  key: "hidden",
                  value: !page.hidden,
                })
              }
            />
          </div>
          <div className="text-right">
            <ButtonRemove
              text="Remove"
              onClick={() =>
                pageEditModel.removePostWithAttachmentsFx(page.id!)
              }
            />
          </div>
          <Button color="accent" onClick={pageEditModel.submitForm} fullWidth>
            Update page
          </Button>
        </>
      )}
    </Box>
  );
};
