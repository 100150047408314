import React from "react";
import { type Blog } from "shared/api/blog";
import { initialBlogContentFields } from "modules/blog/blog.config";
import { Box } from "shared/ui/box";
import { FieldList } from "modules/field/ui/field-list";
import { FieldSelect } from "modules/field/ui/field-select";
import { LayoutPostEdit } from "shared/ui/layouts/layout-post-edit";
import { FormLabel } from "shared/ui/form-label";
import { BlogBaseControls } from "./blog-base-controls.";
import { BlogRightPanelControls } from "./blog-right-panel-controls";
import { fieldAdd } from "../../field/field.model";

interface Props {
  blog: Blog | null;
}

export const BlogEditForm = ({ blog }: Props) => {
  console.log("---blog");
  console.log(blog);

  if (!blog) return null;

  return (
    <>
      <LayoutPostEdit
        content={
          <>
            <BlogBaseControls blog={blog} />
            {/* field list */}
            <FieldList />
          </>
        }
        rightPanel={
          <>
            <BlogRightPanelControls blog={blog} />
            {/* select and adding fields block */}
            <Box>
              <FormLabel text="Content blocks" />
              <FieldSelect
                selectListFields={initialBlogContentFields}
                onAdd={(selectedField) => {
                  fieldAdd({ initialField: selectedField });
                }}
                textBtnAdd="Add block"
              />
            </Box>
          </>
        }
      />
    </>
  );
};
