import React, { useMemo } from "react";
import type { Field } from "modules/field/field.types";
import { type BlockRelationDataType } from "modules/field/field.types";
import { useGate, useStore } from "effector-react";
import { $pendingRelBlocks, RelBlocksGate } from "./model";
import { $relBlocks } from "./model";
import { fieldChangeData } from "modules/field/field.model";
import { SelectBox } from "shared/ui/select-box";
import { Box } from "shared/ui/box";

interface Props {
  field: Field;
}

type BlockOption = { label: string; id?: number | null };

export const FieldBlockRelation = ({ field }: Props) => {
  const data = field.data as BlockRelationDataType;
  const blockId = data?.blockId;
  const relBlocks = useStore($relBlocks);
  const pendingRelBlocks = useStore($pendingRelBlocks);
  useGate(RelBlocksGate);

  console.log("====relBlocks");
  console.log(relBlocks);

  return useMemo(() => {
    const selectedListBlocks: BlockOption[] =
      relBlocks?.map((block) => ({
        id: block.id,
        label: block.title,
      })) ?? [];
    selectedListBlocks.unshift({ id: null, label: "Select" });

    const relBlock = relBlocks?.find((relBlock) => relBlock.id === blockId);
    const activeBlock = relBlock
      ? { id: relBlock.id, label: relBlock.title }
      : selectedListBlocks[0];

    return (
      <Box label="Static block">
        <SelectBox<BlockOption>
          value={pendingRelBlocks ? { label: "loading" } : activeBlock}
          items={selectedListBlocks}
          onChange={(item) =>
            fieldChangeData({ field, key: "blockId", value: item.id })
          }
          expandUp
        />
      </Box>
    );
  }, [relBlocks, blockId, pendingRelBlocks]);
};
