import React, { useMemo } from "react";
import { Loader } from "shared/ui/loader";
import { useGate, useStore } from "effector-react";
import {
  propertiesModel,
  propertiesPaginationModel,
} from "../property-list.model";
import {
  Attachment,
  getPropertyReference,
  type Property,
  propertyTypes,
  propertyYears,
} from "shared/api";
import { Pagination } from "features/pagination/ui/pagination";
import { useRouterChangePageIndex } from "features/pagination/useRouterChangePageIndex";
import { TablePosts } from "shared/ui/table-posts";
import { useNavigate } from "react-router-dom";
import { $provinces, LocationGate } from "../property-location.model";
import { config } from "../../../shared/config";

export const PropertyList = () => {
  const properties = useStore(propertiesModel.$properties);
  const pending = useStore(propertiesModel.$loading);
  useGate(propertiesModel.PropertiesGate);
  const navigate = useNavigate();
  const pagination = useStore(propertiesPaginationModel.params);

  console.log(properties);

  useRouterChangePageIndex({
    pageIndex: pagination.pageIndex,
    callback: propertiesPaginationModel.changePageIndex,
  });

  const getPreview = (item: Property) => {
    if (item.source) {
      const images = item.meta?.images as Partial<Attachment[]>;
      return images && images[0] ? images[0].url : null;
    } else {
      const preview =
        item.attachments?.preview[0]?.meta?.sizes?.sm?.url ??
        item.attachments?.preview[0]?.url;
      return preview ? `${config.uploadUrl}/${preview}` : null;
    }
  };

  return (
    <>
      {pending && <Loader />}
      <Pagination className="mb-3" prefixUrl="property" {...pagination} />
      <TablePosts<Property>
        items={properties}
        colData={{
          preview: {
            header: "Preview",
            type: "image",
            data: (item) => getPreview(item),
            className: "min-w-[160px] w-[200px]",
          },
          ref: {
            header: "Reference",
            type: "strong-text",
            data: (item) => getPropertyReference(item),
            className: "min-w-[100px]",
          },
          description: {
            header: "Description",
            type: "text",
            data: (item) => item.description,
            className: "min-w-[160px]",
          },
          type: {
            header: "Type",
            type: "text",
            data: (item) => {
              const type = propertyTypes.find(
                (type) => type.value === item.type
              );
              return type ? type.label : "";
            },
            className: "min-w-[160px]",
          },
          province: {
            header: "Province",
            type: "strong-text",
            data: (item) => item.province?.label ?? "",
            className: "min-w-[160px]",
          },
          price: {
            header: "Price",
            type: "strong-text",
            data: (item) => (
              <>&euro; {Number(item.price).toLocaleString("en-US")}</>
            ),
            className: "min-w-[160px]",
          },
          updatedAt: {
            header: "Date edit",
            type: "text",
            data: (item) => new Date(item.updatedAt!).toLocaleString(),
            className: "min-w-[160px]",
          },
          hidden: {
            header: "Hidden",
            type: "hidden",
            data: (item) => item.hidden,
            className: "w-[80px]",
          },
        }}
        onRemove={propertiesModel.removePropertyWithAttachmentsFx}
        onClick={(id) => navigate(`/property/${id}/edit`)}
      />
      <Pagination className="mt-3" prefixUrl="property" {...pagination} />
    </>
  );
};
