import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { PageList } from "modules/page/ui/page-list";
import { NavLink } from "react-router-dom";

export const Pages = () => {
  return (
    <LayoutBase
      title="Pages"
      actions={
        <>
          <NavLink to="/page/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
        </>
      }
    >
      <PageList />
    </LayoutBase>
  );
};
