import { api, response } from "./axios";

export type AttachmentType = "image" | "document";
export type AttachmentObject =
  | "page"
  | "blog"
  | "block"
  | "review"
  | "property";
export type AttachmentMetaSize = Record<string, Partial<Attachment>>;
export type AttachmentUploadMethod = "create" | "update";
export type ImageSize = "sm" | "md" | "lg";

export interface Attachment {
  id?: number;
  type: AttachmentType;
  mimeType: string;
  name: string;
  path?: string;
  url: string;
  alt?: string;
  width?: number;
  height?: number;
  size: number;
  file?: File;
  meta?: { sizes?: AttachmentMetaSize };
}

export type AttachmentByFieldId = { [fieldId: string]: Attachment[] };

export interface AttachmentRelation {
  id?: number;
  object: AttachmentObject;
  fieldId: string;
  recordId: number;
  attachmentId: number;
}

export const getAttachments = (): Promise<Attachment[]> => {
  return api.get(`/attachment`).then(response);
};

export const attachmentUpload = (formData: FormData): Promise<Attachment> => {
  return api.post(`/attachment/upload`, formData).then(response);
};

export const attachmentRelationCreate = (
  body: AttachmentRelation[]
): Promise<AttachmentRelation[]> => {
  return api.post("/attachment/relation", body).then(response);
};

export const attachmentUpdateByIds = (body: {
  object: AttachmentObject;
  recordId: number;
  loadedAttachmentIds: number[];
}): Promise<AttachmentRelation[]> => {
  return api.patch("/attachment/ids", body).then(response);
};

export const attachmentRemove = (attachmentId: number): Promise<number> => {
  return api.delete(`/attachment/${attachmentId}`).then(response);
};
