import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { Box } from "shared/ui/box";
import { Link } from "react-router-dom";

const DashboardItem = ({ title, url }: { title: string; url: string }) => (
  <Link to={url} className="flex-1">
    <Box
      size="lg"
      className="flex flex-col items-center justify-center h-[300px] min-w-[300px] hover:bg-accent transition-all"
    >
      <h2>{title}</h2>
    </Box>
  </Link>
);

export const Dashboard = () => {
  return (
    <LayoutBase title="Dashboard">
      <div className="flex flex-wrap gap-3">
        <DashboardItem title="Properties" url="/property" />
        <DashboardItem title="Pages" url="/page" />
        <DashboardItem title="Blog" url="/blog" />
        <DashboardItem title="Reviews" url="/review" />
        <DashboardItem title="Static blocks" url="/block" />
      </div>
    </LayoutBase>
  );
};
