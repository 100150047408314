import React from "react";
import { LayoutBase } from "shared/ui/layouts";
import { BlockList } from "modules/block/ui/block-list";
import { NavLink } from "react-router-dom";

export const Blocks = () => {
  return (
    <LayoutBase
      title="Static blocks"
      actions={
        <>
          <NavLink to="/block/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
        </>
      }
    >
      <BlockList />
    </LayoutBase>
  );
};
