import { createEffect, createEvent, createStore, sample } from "effector";
import type { Field } from "modules/field/field.types";
import {
  createNewFieldsFromFieldInitial,
  getFieldLastOrderByParentId,
  mergeAndRemoveUnusedFields,
  removeWithChildByField,
} from "./field.lib";

// selected fields
export const $selectedField = createStore<Field | null>(null);
export const selectedFieldChange = createEvent<Field>();
$selectedField.on(selectedFieldChange, (_, field) => field);

// fields
export const $fields = createStore<Field[]>([]);

export const fieldLoadAndMerge = createEvent<{
  initialFields: Field[];
  sourceFields: Field[];
}>();
$fields.on(fieldLoadAndMerge, (state, { initialFields, sourceFields }) => {
  return initialFields.length && sourceFields.length
    ? mergeAndRemoveUnusedFields(initialFields, sourceFields)
    : [];
});

export const fieldChangeOrder = createEvent<{
  fromField: Field;
  toField: Field;
}>();

$fields.on(fieldChangeOrder, (state, { fromField, toField }) => {
  const excludedOrderedFields = state.filter(
    (f) => f.id !== fromField.id && f.id !== toField.id
  );

  const fromOrder = fromField.order;
  const toOrder = toField.order;

  return [
    ...excludedOrderedFields,
    {
      ...fromField,
      order: toOrder,
    },
    {
      ...toField,
      order: fromOrder,
    },
  ];
});

export const fieldAdd = createEvent<{
  initialField: Field;
  parentId?: string;
}>();
// added root field (block) and repeater group fields
$fields.on(fieldAdd, (state, { initialField, parentId }) => {
  const lastOrder = getFieldLastOrderByParentId({ fields: state, parentId });
  if (initialField) {
    return [
      ...state,
      ...createNewFieldsFromFieldInitial({
        initialField,
        parentId,
        lastOrder,
      }),
    ];
  }
});

export const fieldChangeData = createEvent<{
  field: Field;
  key: string;
  value: unknown;
}>();
$fields.on(fieldChangeData, (state, { field, key, value }) => {
  const fieldIndex = state.findIndex((f) => f.id === field.id);

  if (fieldIndex > -1) {
    return [
      ...state.slice(0, fieldIndex),
      {
        ...state[fieldIndex],
        data: { ...state[fieldIndex].data, [key]: value },
      },
      ...state.slice(fieldIndex + 1),
    ];
  }
});

// update field attributes
export const fieldUpdate = createEvent<{
  field: Field;
  key: string;
  value: unknown;
}>();
$fields.on(fieldUpdate, (state, { field, key, value }) => {
  const fieldIndex = state.findIndex((f) => f.id === field.id);

  if (fieldIndex > -1) {
    return [
      ...state.slice(0, fieldIndex),
      { ...state[fieldIndex], [key]: value },
      ...state.slice(fieldIndex + 1),
    ];
  }
});

export const fieldRemoveFx = createEffect(
  (
    field: Field
  ): {
    removedIds: string[];
    newFields: Field[];
  } | null => {
    if (field) {
      const fields = $fields.getState();
      const removedIds = removeWithChildByField(fields, field);

      return {
        removedIds,
        newFields: fields.filter((f) => f.id && !removedIds.includes(f.id)),
      };
    }

    return null;
  }
);

$fields.on(fieldRemoveFx.doneData, (state, payload) => {
  if (payload && payload.newFields) {
    return payload.newFields;
  }
});

// remove all
export const fieldRemoveAll = createEvent();
sample({
  clock: fieldRemoveAll,
  fn: () => [],
  target: $fields,
});
