import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { Input, Textarea } from "shared/ui/inputs";
import { blogCreateModel, blogEditModel } from "../blog.model";
import { Box } from "shared/ui/box";
import { type Blog } from "shared/api";
import { useStore } from "effector-react";
import { $errors } from "modules/validation/validation.model";
import { ImagePreviewUpload } from "modules/attachment/ui/image-preview-upload";

interface Props {
  blog: Blog;
}

export const BlogBaseControls = ({ blog }: Props) => {
  const isNew = !blog?.id;
  const blogModel = isNew ? blogCreateModel : blogEditModel;
  const errors = useStore($errors);

  return (
    <Box className="flex">
      {/* preview */}
      <div className="flex-1 max-w-[280px] mr-5">
        <FormLabel text="Preview" />
        <div className="h-[220px]">
          <ImagePreviewUpload fieldId="preview" />
        </div>
      </div>
      {/* base info */}
      <div className="flex-1 space-y-3">
        <div>
          {/* title */}
          <FormLabel text="Title" />
          <Input
            type="text"
            sizing="sm"
            value={blog?.title}
            onChange={(e) =>
              blogModel.update({
                key: "title",
                value: e.target.value as string,
              })
            }
            error={errors.title}
          />
        </div>

        {/* slug */}
        <div>
          <FormLabel text="Slug (not required)" />
          <Input
            type="text"
            sizing="sm"
            value={blog?.slug}
            onChange={(e) =>
              blogModel.update({
                key: "slug",
                value: e.target.value,
              })
            }
          />
        </div>

        {/* exception */}
        <div>
          <FormLabel text="Excerpt" />
          <Textarea
            value={blog?.excerpt ?? ""}
            onChange={(e) =>
              blogModel.update({
                key: "excerpt",
                value: e.target.value,
              })
            }
          />
        </div>
      </div>
    </Box>
  );
};
