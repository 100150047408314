import type {
  Attachment,
  AttachmentType,
  ImageSize,
} from "shared/api/attachment";
import { config } from "shared/config";

export const checkAllowedAndGetTypeFromMimeType = (
  mimeType: string,
  allowType?: AttachmentType
): AttachmentType | null => {
  // allow images
  if (
    allowType === "image" &&
    config.upload.allowMimeImages.some((t) => t === mimeType)
  ) {
    return allowType;
  }

  // allow document
  if (
    allowType === "document" &&
    config.upload.allowMimeDocuments.some((t) => t === mimeType)
  ) {
    return allowType;
  }

  // allow images and document
  if (
    !allowType &&
    [
      ...config.upload.allowMimeImages,
      ...config.upload.allowMimeDocuments,
    ].some((t) => t === mimeType)
  ) {
    return "document";
  }

  return null;
};

export const revokeAttachmentByUrl = (url: string) => {
  console.log("revoked:", url);
  URL.revokeObjectURL(url);
};

export const revokeAttachments = (files: Attachment[]) => {
  files.forEach((file) => {
    if (file?.url && file.file instanceof File) {
      console.log("revoked:", file.url);
      URL.revokeObjectURL(file.url);
    }
  });
};

const newAttachment = (
  file: File,
  attachmentType: AttachmentType
): Attachment | null => {
  switch (attachmentType) {
    case "image":
      return {
        name: "",
        mimeType: file.type,
        type: attachmentType,
        url: URL.createObjectURL(file),
        size: file.size,
        file: file,
      };
    case "document":
    default:
      return {
        name: "",
        mimeType: file.type,
        type: attachmentType,
        url: "",
        size: file.size,
        file: file,
      };
  }
};

export const createNewAttachments = (
  files: FileList,
  allowType?: AttachmentType
): Attachment[] => {
  const attachments: Attachment[] = [];

  if (files.length) {
    for (let i = 0; i < files.length; i++) {
      const checkedType = checkAllowedAndGetTypeFromMimeType(
        files[i].type,
        allowType
      );

      if (checkedType) {
        const attachment = newAttachment(files[i], checkedType);
        // console.log("newAttachment", attachment);
        console.log("createObjectURL", attachment?.url);
        attachment && attachments.push(attachment);
      }
    }
  }

  return attachments;
};

export const getAttachmentUrl = (
  attachment: Attachment,
  size: ImageSize = "md"
): string | undefined => {
  const url =
    attachment.meta?.sizes && attachment.meta?.sizes[size]?.url
      ? attachment.meta.sizes[size].url
      : attachment.url;

  // !id > new image > File blob link,
  // id have uploaded file
  return attachment.id ? `${config.uploadUrl}/${url}` : url;
};
