import React from "react";
import { Loader } from "shared/ui/loader";
import { useGate, useStore } from "effector-react";
import { blocksModel, blocksPaginationModel } from "../block.model";
import { type Block } from "shared/api";
import { useRouterChangePageIndex } from "features/pagination/useRouterChangePageIndex";
import { Pagination } from "features/pagination/ui/pagination";
import { TablePosts } from "shared/ui/table-posts";
import { useNavigate } from "react-router-dom";

export const BlockList = () => {
  const blocks = useStore(blocksModel.$posts);
  const pending = useStore(blocksModel.$loading);
  useGate(blocksModel.PostsGate);
  const navigate = useNavigate();

  const pagination = useStore(blocksPaginationModel.params);
  useRouterChangePageIndex({
    pageIndex: pagination.pageIndex,
    callback: blocksPaginationModel.changePageIndex,
  });

  console.log(blocks);
  return (
    <>
      {pending && <Loader />}
      <Pagination className="mb-3" prefixUrl="block" {...pagination} />
      <TablePosts<Block>
        items={blocks}
        colData={{
          title: {
            header: "Title",
            type: "strong-text",
            data: (item) => item.title,
            className: "min-w-[160px]",
          },
          updatedAt: {
            header: "Date edit",
            type: "text",
            data: (item) => new Date(item.updatedAt!).toLocaleString(),
            className: "min-w-[160px]",
          },
        }}
        onRemove={blocksModel.removePostWithAttachmentsFx}
        onClick={(id) => navigate(`/block/${id}/edit`)}
      />
      <Pagination className="mt-3" prefixUrl="block" {...pagination} />
    </>
  );
};
