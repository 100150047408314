import React, { useEffect } from "react";
import { LayoutBase } from "shared/ui/layouts";
import { PageEditForm } from "modules/page/ui/page-edit-form";
import { useGate, useStore } from "effector-react";
import { NavLink, useNavigate } from "react-router-dom";
import { pageCreateModel } from "modules/page/page.model";
import { Loader } from "shared/ui/loader";

export const PageCreate = () => {
  const page = useStore(pageCreateModel.$post);
  const navigate = useNavigate();
  const loading = useStore(pageCreateModel.$loading);

  useGate(pageCreateModel.PostCreateGate, {
    parentId: null,
    title: "",
    slug: "",
    excerpt: "",
    fields: null,
    attachments: null,
    hidden: true,
  });

  useEffect(() => {
    page?.id && navigate(`/page/${page?.id}/edit`);
  }, [page?.id]);

  console.log("=====page create");
  // console.log(page);
  return (
    <LayoutBase
      title="Create new page"
      actions={
        <>
          <NavLink to="/page" className="btn btn-secondary btn-sm">
            All pages
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {!page?.id && <PageEditForm page={page} />}
    </LayoutBase>
  );
};
