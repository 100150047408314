import React, { type ReactNode } from "react";
import type { ButtonProps } from "shared/ui/buttons/button";
import { Button } from "shared/ui/buttons/button";
import { Icon } from "../icon";

interface Props extends Omit<ButtonProps, "children"> {
  order: "up" | "down" | "prev" | "next";
}

export const ButtonOrder = (props: Props) => {
  let component: ReactNode;
  switch (props.order) {
    case "up":
      component = (
        <Icon name="bars-arrow-up" className="w-[20px] h-[20px] stroke-white" />
      );
      break;
    case "down":
      component = (
        <Icon
          name="bars-arrow-down"
          className="w-[20px] h-[20px] stroke-white"
        />
      );
      break;
    case "prev":
      component = (
        <Icon
          name="bars-arrow-up"
          className="w-[20px] h-[20px] stroke-white -rotate-90"
        />
      );
      break;
    case "next":
      component = (
        <Icon
          name="bars-arrow-up"
          className="w-[20px] h-[20px] stroke-white rotate-90"
        />
      );
      break;
  }

  return (
    <Button variant="square" color="secondary" size="sm" {...props}>
      {component}
    </Button>
  );
};
