import React from "react";
import cn from "clsx";

interface Props {
  inline?: boolean;
  text: string;
  required?: boolean;
  className?: string;
}

export const FormLabel = ({
  className,
  text,
  inline = false,
  required = false,
}: Props) => {
  return (
    <label
      className={cn(className, "font-bold", {
        "block mb-2": !inline,
        "mr-2": inline,
      })}
    >
      {text}
      {required && <span className="text-error">*</span>}
    </label>
  );
};
