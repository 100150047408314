import React, { PropsWithChildren } from "react";
import { Sidebar } from "features/sidebar/ui/sidebar";
import { ToastView } from "modules/toast/ui/toast-view";

interface Props extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
}

export const LayoutBase = ({ title, subtitle, actions, children }: Props) => {
  return (
    <>
      <main className="flex-1 flex bg-gray-200">
        <Sidebar />
        <div className="flex-1">
          <div className="sticky top-0 flex items-center gap-3 w-full h-[50px] px-5 text-white bg-indigo-600 z-20">
            {title && <h2>{title}</h2>}
            {actions && <div className="flex gap-2">{actions}</div>}
          </div>
          <div className="mx-5 my-3">
            {subtitle && <h3 className="">{subtitle}</h3>}
            {children}
          </div>
        </div>
      </main>
      <ToastView />
    </>
  );
};
