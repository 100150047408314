import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import {
  getLocations,
  locationCreate,
  locationRemove,
  type PropertyLocation,
  provinceCreate,
} from "shared/api";
import { createGate } from "effector-react";
import { FindAllOptions } from "../../shared/api/types";

type LocationType = "province" | "location";
type EditAction = "delete" | "update";

const createProvinceFx = createEffect({
  handler: async (label: string) => {
    return await provinceCreate(label);
  },
});

const createLocationFx = createEffect({
  handler: async ({
    label,
    province,
  }: {
    label: string;
    province: PropertyLocation | null;
  }) => {
    return province && (await locationCreate(label, province.id));
  },
});

const loadLocationsFx = createEffect({
  handler: async (params?: FindAllOptions) => {
    return await getLocations(params);
  },
});

export const removeLocationFx = createEffect({
  handler: async (id: number) => {
    return await locationRemove(id);
  },
});

// validate
export const $errors = createStore<{ [key: string]: string }>({});
export const validateCreateLocation = createEvent<{
  type: LocationType;
  value: string;
}>();
$errors.on(validateCreateLocation, (state, { type, value }) => {
  if (value.length < 3) {
    return { ...state, [type]: "min 3 symbols" };
  } else {
    const errors = { ...state };
    delete errors[type];
    return errors;
  }
});

export const $selectedProvince = createStore<PropertyLocation | null>(null);
export const selectedProvinceChange = createEvent<PropertyLocation>();
export const refreshSelected = createEvent<{
  updatedId: number;
  action: EditAction;
}>();
$selectedProvince.on(selectedProvinceChange, (_, province) => province);
$selectedProvince.on(refreshSelected, (state, { updatedId, action }) => {
  if (state?.id === updatedId) {
    if (action === "delete") return null;
    if (action === "update")
      return $provinces.getState().find((p) => p.id === updatedId);
  }
});

// on edit/remove - refresh selected province
sample({
  clock: [removeLocationFx.doneData],
  fn: (clock) => ({ updatedId: clock, action: "delete" as EditAction }),
  target: refreshSelected,
});

// provinces locations
export const $locations = createStore<PropertyLocation[]>([]);
export const $provinces = $locations.map((location) =>
  location.filter((l) => !l.parent)
);
export const LocationGate = createGate<FindAllOptions>();

const $pendingLoadingLocations = loadLocationsFx.pending.map(
  (pending) => !pending
);
$locations.on(loadLocationsFx.doneData, (_, locations) => locations);

sample({
  clock: LocationGate.open,
  filter: () => $pendingLoadingLocations.getState(),
  fn: (clock) => clock,
  target: loadLocationsFx,
});

sample({
  clock: [createProvinceFx.done, createLocationFx.done, removeLocationFx.done],
  source: LocationGate.open,
  target: loadLocationsFx,
});

// create province
export const $createProvince = createStore<string>("");
export const changeCreateProvince = createEvent<string>();
$createProvince.on(changeCreateProvince, (_, label) => label);
export const submitCreateProvince = createEvent();

sample({
  clock: changeCreateProvince,
  fn: (clock) => ({ type: "province" as LocationType, value: clock }),
  target: validateCreateLocation,
});

sample({
  clock: submitCreateProvince,
  source: $createProvince,
  fn: (source) => ({ type: "province" as LocationType, value: source }),
  target: validateCreateLocation,
});

sample({
  clock: submitCreateProvince,
  source: $createProvince,
  filter: () => !$errors.getState().province,
  target: createProvinceFx,
});

sample({
  clock: createProvinceFx.doneData,
  fn: () => "",
  target: $createProvince,
});

// create location
export const $createLocation = createStore<string>("");
export const changeCreateLocation = createEvent<string>();
$createLocation.on(changeCreateLocation, (_, label) => label);
export const submitCreateLocation = createEvent();

sample({
  clock: changeCreateLocation,
  fn: (clock) => ({ type: "location" as LocationType, value: clock }),
  target: validateCreateLocation,
});

sample({
  clock: submitCreateLocation,
  source: $createLocation,
  fn: (source) => ({ type: "location" as LocationType, value: source }),
  target: validateCreateLocation,
});

sample({
  clock: submitCreateLocation,
  source: combine({ label: $createLocation, province: $selectedProvince }),
  filter: () => !$errors.getState().location,
  target: createLocationFx,
});

sample({
  clock: createLocationFx.doneData,
  fn: () => "",
  target: $createLocation,
});
