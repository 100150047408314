import React, { type ChangeEvent } from "react";
import { Input, type InputProps } from "./input";

export const InputNumber = (props: InputProps) => {
  const value = String(props.value);
  const { minLength, maxLength, ...rest } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(`^[0-9]{${minLength ?? 0},${maxLength ?? ""}}$`);
    if (e.target.value.match(regex)) {
      rest.onChange && rest.onChange(e);
    }
  };

  return (
    <Input type="number" {...rest} onChange={handleChange} value={value} />
  );
};
