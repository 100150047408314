import { createEvent, createStore } from "effector";

type ItemCollapsed = { [key: string]: boolean };

export const $collapsed = createStore(false);
export const setCollapsed = createEvent();
$collapsed.on(setCollapsed, (state) => !state);

export const $itemsCollapsed = createStore<ItemCollapsed | null>(null);
export const setItemCollapsed = createEvent<{ key: string }>();
$itemsCollapsed.on(setItemCollapsed, (state, { key }) => {
  if (state) {
    return { ...state, [key]: state[key] ? !state[key] : true };
  }
  return { [key]: true };
});
