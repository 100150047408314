import React, { useMemo } from "react";
import type {
  ButtonAsType,
  ButtonDataType,
  Field,
} from "modules/field/field.types";
import { Box } from "shared/ui/box";
import { Input } from "shared/ui/inputs";
import { fieldChangeData } from "../../field.model";
import { FormLabel } from "shared/ui/form-label";
import { ModalTypesEnums } from "modules/field/field.types";
import { SelectBox } from "shared/ui/select-box";

interface Props {
  field: Field;
}

type ButtonTypeOption = { label: ButtonAsType };
type ButtonModalOption = { label: string; id?: string };
const types: ButtonTypeOption[] = [{ label: "link" }, { label: "modal" }];
const modals: ButtonModalOption[] = Object.keys(ModalTypesEnums).map((key) => ({
  id: key,
  label: ModalTypesEnums[key as keyof typeof ModalTypesEnums],
}));

export const FieldButton = ({ field }: Props) => {
  const data = field.data as ButtonDataType;

  return useMemo(() => {
    const activeType = data?.as && types.find((type) => type.label === data.as);

    const activeModal = data?.modal
      ? modals.find((modal) => modal.id === data.modal) ?? { label: "Select" }
      : { label: "Select" };
    return (
      <Box label={`${field.label} (${field.name})`}>
        <div className="flex flex-wrap items-center gap-3">
          {/* type */}
          <div className="w-[160px]">
            <FormLabel text="Type" />
            <SelectBox<ButtonTypeOption>
              value={activeType ?? types[0]}
              items={types}
              onChange={(item) =>
                fieldChangeData({ field, key: "as", value: item.label })
              }
              expandUp
            />
          </div>
          {/* select modal or link text field */}
          <div className="flex-1">
            {activeType?.label === "modal" ? (
              <>
                <FormLabel text="Modal name" />
                <SelectBox<ButtonModalOption>
                  value={activeModal}
                  items={modals}
                  onChange={(item) =>
                    fieldChangeData({ field, key: "modal", value: item.id })
                  }
                  expandUp
                />
              </>
            ) : (
              <>
                <FormLabel text="Link" />
                <Input
                  sizing="sm"
                  value={data?.href ?? ""}
                  onChange={(e) =>
                    fieldChangeData({
                      field,
                      key: "href",
                      value: e.target.value,
                    })
                  }
                />
              </>
            )}
          </div>
          {/* text */}
          <div className="w-full">
            <FormLabel text="Text" />
            <Input
              sizing="sm"
              value={data?.text || ""}
              onChange={(e) =>
                fieldChangeData({ field, key: "text", value: e.target.value })
              }
            />
          </div>
        </div>
      </Box>
    );
  }, [data]);
};
