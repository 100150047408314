import {
  combine,
  createEvent,
  createStore,
  type Effect,
  type Event,
  sample,
  type Store,
} from "effector";

export interface PaginationModelProps {
  take: number;
  loadFx: Effect<any, any>;
  appendParams?: Record<string, unknown>;
}

export interface PaginationModel {
  // $pageIndex: Store<number>;
  params: Store<{
    skip: number;
    take: number;
    total: number;
    pageIndex: number;
  }>;
  reset: Event<any>;
  changePageIndex: Event<any>;
}

export const paginationModel = ({
  loadFx,
  take,
  appendParams,
}: PaginationModelProps): PaginationModel => {
  const $pageIndex = createStore<number>(0);
  const $take = createStore<number>(take);
  const $total = createStore<number>(0);
  const $pendingLoading = loadFx.pending.map((pending) => !pending);

  const $params = combine(
    $pageIndex,
    $take,
    $total,
    (pageIndex, take, total) => {
      return {
        skip: pageIndex === 0 ? take * pageIndex : take * (pageIndex - 1),
        take,
        total,
        pageIndex,
      };
    }
  );

  const changePageIndex = createEvent<number>();
  $pageIndex.on(changePageIndex, (_, pageIndex) => pageIndex);

  const reset = createEvent();
  $pageIndex.reset(reset);
  $total.reset(reset);

  sample({
    clock: changePageIndex,
    source: $params,
    filter: $pendingLoading,
    fn: (source) => {
      const { skip, take } = source;
      return { skip, take, ...appendParams };
    },
    target: loadFx,
  });

  sample({
    clock: loadFx.doneData,
    fn: (clock) => clock.total,
    target: $total,
  });

  return { params: $params, reset, changePageIndex };
};
