import React, { ButtonHTMLAttributes } from "react";
import cn from "clsx";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "accent"
    | "info"
    | "warning"
    | "success"
    | "error"
    | "light";
  variant?: "circle" | "square" | "icon-text";
  outline?: boolean;
  size?: "xs" | "sm" | "lg";
  fullWidth?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export const Button = ({
  className,
  color = "primary",
  size,
  variant,
  outline = false,
  fullWidth = false,
  loading = false,
  onClick,
  children,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={cn(className, `btn`, {
        "btn-block": fullWidth,
        "btn-primary": color === "primary",
        "btn-secondary": color === "secondary",
        "btn-accent": color === "accent",
        "btn-info": color === "info",
        "btn-warning": color === "warning",
        "btn-success": color === "success",
        "btn-error": color === "error",
        "btn-light": color === "light",
        "btn-square": variant === "square",
        "btn-circle": variant === "circle",
        "btn-outline": outline,
        "gap-2": variant === "icon-text",
        "btn-xs": size === "xs",
        "btn-sm": size === "sm",
        "btn-lg": size === "lg",
        loading,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
      {...rest}
    >
      {children}
    </button>
  );
};
