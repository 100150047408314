import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useRouterChangePageIndex = ({
  pageIndex,
  callback,
}: {
  pageIndex: number;
  callback: (index: number) => void;
}) => {
  const routerPage = Number(useParams().page);
  useEffect(() => {
    if (!isNaN(routerPage) && routerPage !== pageIndex) {
      callback(routerPage);
    }

    if (isNaN(routerPage)) {
      callback(0);
    }
  }, [routerPage]);
};
