import type { Field } from "modules/field/field.types";
import { api, response } from "./axios";
import { type AttachmentByFieldId } from "./attachment";
import { getSearchParamsAsJson } from "./lib";
import type { FindAllOptions, Paginated } from "./types";

export interface Block {
  id?: number;
  title: string;
  attachments: AttachmentByFieldId | null;
  fields: Field[] | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export const getBlocks = (
  params?: FindAllOptions
): Promise<Paginated<Block>> => {
  const searchParams = getSearchParamsAsJson(params);
  console.log("----get blocks");

  return api.get(`/block?${searchParams}`).then(response);
};

export const getBlock = ({ postId }: { postId: number }): Promise<Block> => {
  return api.get(`/block/${postId}`).then(response);
};

export const blockCreate = (block: Block): Promise<Block> => {
  return api.post(`/block`, block).then(response);
};

export const blockEdit = (block: Block): Promise<Block> => {
  return api.put(`/block`, block).then(response);
};

export const blockRemove = (blockId: number): Promise<number> => {
  return api.delete(`/block/${blockId}`).then(response);
};
