export interface MenuItemProps {
  path: string;
  label?: string;
  icon?: string;
}

export interface MenuListProps extends MenuItemProps {
  children?: MenuListProps[];
}

export const menu: MenuListProps[] = [
  {
    path: "/",
    label: "Dashboard",
    icon: "chart-pie",
  },
  {
    path: "/property",
    label: "Properties",
    icon: "home-modern",
    children: [
      {
        path: "/property",
        label: "All properties",
      },
      {
        path: "/property/create",
        label: "Add new property",
      },
      {
        path: "/property/location",
        label: "Locations",
      },
    ],
  },
  {
    path: "/page",
    label: "Pages",
    icon: "document-text",
    children: [
      {
        path: "/page",
        label: "All pages",
      },
      {
        path: "/page/create",
        label: "Add new",
      },
    ],
  },
  {
    path: "/blog",
    label: "Blog",
    icon: "newspaper",
    children: [
      {
        path: "/blog",
        label: "All posts",
      },
      {
        path: "/blog/create",
        label: "Add new",
      },
    ],
  },
  {
    path: "/review",
    label: "Reviews",
    icon: "chat-bubble-bottom-center-text",
    children: [
      {
        path: "/review",
        label: "All reviews",
      },
      {
        path: "/review/create",
        label: "Add new",
      },
    ],
  },
  {
    path: "/block",
    label: "Static blocks",
    icon: "rectangle-group",
    children: [
      {
        path: "/block",
        label: "All blocks",
      },
      {
        path: "/block/create",
        label: "Add new",
      },
    ],
  },
];
