import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { propertyCreate } from "../property-create.model";
import { propertyEdit } from "../property-edit.model";
import { Button } from "shared/ui/buttons/button";
import { ButtonRemove } from "shared/ui/buttons";
import { Box } from "shared/ui/box";
import { type Property } from "shared/api";
import { InputToggle } from "shared/ui/inputs";

interface Props {
  property: Property;
}

export const PropertyRightPanelControls = ({ property }: Props) => {
  const isNew = !property?.id;
  return (
    <Box className="space-y-3">
      {isNew ? (
        <>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={property?.hidden}
              onChange={() =>
                propertyCreate.update({
                  key: "hidden",
                  value: !property?.hidden,
                })
              }
            />
          </div>

          <Button color="accent" onClick={propertyCreate.submitForm} fullWidth>
            Save new property
          </Button>
        </>
      ) : (
        <>
          <div>
            <FormLabel text="Created:" inline />
            {property?.createdAt &&
              new Date(property.createdAt).toLocaleString()}
          </div>
          <div>
            <FormLabel text="Updated:" inline />
            {property?.updatedAt &&
              new Date(property.updatedAt).toLocaleString()}
          </div>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={property?.hidden}
              onChange={() =>
                propertyEdit.update({
                  key: "hidden",
                  value: !property?.hidden,
                })
              }
            />
          </div>
          <div className="text-right">
            <ButtonRemove
              text="Remove"
              onClick={() =>
                propertyEdit.removePropertyWithAttachmentsFx(property.id!)
              }
            />
          </div>
          <Button color="accent" onClick={propertyEdit.submitForm} fullWidth>
            Update property
          </Button>
        </>
      )}
    </Box>
  );
};
