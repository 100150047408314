import React from "react";
import { Loader } from "shared/ui/loader";
import { useGate, useStore } from "effector-react";
import { reviewsModel, reviewsPaginationModel } from "../review-list.model";
import { type Attachment, type Review } from "shared/api";
import { Pagination } from "features/pagination/ui/pagination";
import { useRouterChangePageIndex } from "features/pagination/useRouterChangePageIndex";
import { TablePosts } from "shared/ui/table-posts";
import { useNavigate } from "react-router-dom";
import { config } from "shared/config";

export const ReviewList = () => {
  const reviews = useStore(reviewsModel.$reviews);
  const pending = useStore(reviewsModel.$loading);
  useGate(reviewsModel.ReviewsGate);
  const navigate = useNavigate();

  const pagination = useStore(reviewsPaginationModel.params);

  useRouterChangePageIndex({
    pageIndex: pagination.pageIndex,
    callback: reviewsPaginationModel.changePageIndex,
  });

  console.log(reviews);

  const getAvatar = (item: Review) => {
    const avatar =
      item.attachments?.avatar[0]?.meta?.sizes?.sm?.url ??
      item.attachments?.avatar[0]?.url;
    return avatar ? `${config.uploadUrl}/${avatar}` : null;
  };

  return (
    <>
      {pending && <Loader />}
      <Pagination className="mb-3" prefixUrl="review" {...pagination} />
      <TablePosts<Review>
        items={reviews}
        colData={{
          preview: {
            header: "Preview",
            type: "image",
            data: (item) => getAvatar(item),
            className: "min-w-[160px] w-[200px]",
          },
          title: {
            header: "Title",
            type: "strong-text",
            data: (item) => item.title,
            className: "min-w-[160px]",
          },
          text: {
            header: "Text",
            type: "text",
            data: (item) => item.text,
            className: "min-w-[160px]",
          },
          updatedAt: {
            header: "Date edit",
            type: "text",
            data: (item) => new Date(item.updatedAt!).toLocaleString(),
            className: "min-w-[160px]",
          },
          hidden: {
            header: "Hidden",
            type: "hidden",
            data: (item) => item.hidden,
            className: "w-[80px]",
          },
        }}
        onRemove={reviewsModel.removeReviewWithAttachmentsFx}
        onClick={(id) => navigate(`/review/${id}/edit`)}
      />
      <Pagination className="mt-3" prefixUrl="review" {...pagination} />
    </>
  );
};
