import React from "react";
import { $toasts, type Toast } from "../toast.model";
import { useStore } from "effector-react";
import cn from "clsx";

export const ToastItem = ({ title, text, type }: Toast) => {
  return (
    <div
      className={cn("alert", {
        "alert-info": type === "info",
        "alert-success": type === "success",
        "alert-error": type === "error",
        "alert-warning": type === "warning",
      })}
    >
      <div>
        <strong>{title}</strong>
      </div>
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
};

export const ToastView = () => {
  const toasts = useStore($toasts);

  return toasts.length ? (
    <div className="toast toast-end">
      {toasts.map((toast) => {
        return <ToastItem key={toast.id} {...toast} />;
      })}
    </div>
  ) : null;
};
