import { type Field } from "modules/field/field.types";

export const initialBlockContentFields: Field[] = [
  {
    type: "block",
    name: "staticServices",
    label: "List of services",
    data: {
      initialFields: [
        {
          type: "repeater",
          name: "repeaterService",
          label: "Services",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupService",
                label: "Service",
                data: {
                  initialFields: [
                    {
                      name: "icon",
                      type: "icon",
                      label: "Icon",
                    },
                    {
                      name: "title",
                      type: "text",
                      label: "Title",
                    },
                    {
                      name: "text",
                      type: "text",
                      label: "Text",
                    },
                    {
                      name: "button",
                      type: "button",
                      label: "Button",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "staticAdvantageWithContact",
    label: "Advantage with contact",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "repeater",
          name: "repeaterAdvantageWithContact",
          label: "Advantages",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupAdvantageWithContact",
                label: "Advantage",
                data: {
                  initialFields: [
                    {
                      type: "icon",
                      name: "icon",
                      label: "Icon",
                    },
                    {
                      type: "text",
                      name: "title",
                      label: "Title",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "staticSaleConsultation",
    label: "Sale consultation",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "subtitle",
          label: "Subtitle",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
      ],
    },
  },
  {
    type: "block",
    name: "staticOnlineService",
    label: "Online services",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          type: "repeater",
          name: "repeaterOnlineService",
          label: "Services",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupOnlineService",
                label: "Service",
                data: {
                  initialFields: [
                    {
                      type: "image",
                      name: "image",
                      label: "Image",
                    },
                    {
                      type: "text",
                      name: "title",
                      label: "Title",
                    },
                    {
                      type: "text",
                      name: "href",
                      label: "Link",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "staticOnlineConsultationWithForm",
    label: "Online consultation with form",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "subtitle",
          label: "Subtitle",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          type: "text",
          name: "note",
          label: "Text",
        },
      ],
    },
  },
  {
    type: "block",
    name: "staticBanners",
    label: "Banners",
    data: {
      initialFields: [
        {
          type: "image",
          name: "imagePrimary",
          label: "Image",
        },
        {
          type: "text",
          name: "linkPrimary",
          label: "Link",
        },
        {
          type: "image",
          name: "imageSecondary",
          label: "Image",
        },
        {
          type: "text",
          name: "linkSecondary",
          label: "Link",
        },
      ],
    },
  },
];
