import { useGate, useStore } from "effector-react";
import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { LayoutBase } from "shared/ui/layouts";
import { PropertyEditForm } from "modules/property/ui/property-edit-form";
import { propertyEdit } from "modules/property/property-edit.model";
import { Loader } from "shared/ui/loader";
import { getPropertyReference } from "shared/api";

export const PropertyEdit = () => {
  const params = useParams<{ id?: string }>();
  const property = useStore(propertyEdit.$property);
  const propertyRemovedId = useStore(propertyEdit.$propertyRemovedId);
  const loading = useStore(propertyEdit.$loading);
  const navigate = useNavigate();

  const propertyId = Number(params.id);
  useGate(propertyEdit.PropertyEditGate, { id: propertyId });

  useEffect(() => {
    propertyRemovedId === propertyId && navigate("/property");
  }, [propertyRemovedId]);

  console.log("=====property edit");
  console.log(property);

  return (
    <LayoutBase
      title={`Edit property`}
      subtitle={property ? `#${getPropertyReference(property)}` : ""}
      actions={
        <>
          <NavLink to="/property/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
          <NavLink to="/property" className="btn btn-secondary btn-sm">
            All properties
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {property && <PropertyEditForm property={property} />}
    </LayoutBase>
  );
};
