import React from "react";
import { SelectBox } from "shared/ui/select-box";
import { useGate, useStore } from "effector-react";
import {
  pageCreateModel,
  pageEditModel,
  parentPagesModel,
} from "../page.model";
import { type Post } from "modules/post-field-attachment/post-field-attachment.model";
import { selectShort } from "shared/api";

interface SelectParentProps extends Partial<Post> {
  label: string;
}

interface Props {
  id?: number;
  parentId: number | null;
  isNew: boolean;
}

export const PageParentSelect = ({ id, parentId, isNew }: Props) => {
  const pageModel = isNew ? pageCreateModel : pageEditModel;
  const pages = useStore(parentPagesModel.$posts);
  useGate(parentPagesModel.PostsGate, {
    select: selectShort,
    where: { excludeIds: id ? [id] : undefined },
  });

  const selectListPages: SelectParentProps[] = pages
    .map((page) => ({
      label: page.title,
      ...page,
    }))
    .filter((page) => page.parentId !== id);

  selectListPages.unshift({
    parentId: null,
    label: "Not selected",
  });

  const activeParent = parentId
    ? selectListPages.find((parentPage) => parentPage.id === parentId) ??
      selectListPages[0]
    : selectListPages[0];

  // console.log("---selectListPages");
  // console.log(selectListPages);
  //
  // console.log("---activeParent");
  // console.log(activeParent);

  return (
    <>
      <SelectBox<SelectParentProps>
        value={activeParent}
        items={selectListPages}
        onChange={(item) =>
          pageModel.update({
            key: "parentId",
            value: (item.id as number) ?? null,
          })
        }
      />
    </>
  );
};
