import React, { ChangeEvent, useMemo } from "react";
import type { Field } from "modules/field/field.types";
import type { AttachmentDataType } from "modules/field/field.types";
import { ImageListUpload } from "modules/attachment/ui/image-list-upload";

interface Props {
  field: Field;
}

export const FieldImage = ({ field }: Props) => {
  const data = field.data as AttachmentDataType;
  const fieldId = field.id!;
  const multiple = data?.multiple;

  return (
    <ImageListUpload
      fieldId={fieldId}
      multiple={multiple}
      label={field.label}
    />
  );
};
