import React, { Fragment, type ReactNode } from "react";
import { Listbox, Transition } from "@headlessui/react";
import cn from "clsx";
import { Icon } from "./icon";
import { PopupError } from "./popup-error";

interface Props<T> {
  items: T[];
  onChange?: (item: T) => void;
  className?: string;
  value: T | null;
  expandUp?: boolean;
  disabled?: boolean;
  error?: string;
}

export const SelectBox = <
  T extends {
    label: string;
    icon?: ReactNode;
    isParent?: boolean;
    isChild?: boolean;
  }
>({
  items,
  onChange,
  value,
  className,
  expandUp = false,
  disabled = false,
  error,
}: Props<T>) => {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div
            className={cn(className, "relative h-full", {
              "opacity-50": disabled,
            })}
          >
            {error && <PopupError message={error} />}
            <Listbox.Button
              className={cn(
                `relative w-full cursor-pointer rounded-md bg-white py-2 px-3 text-left shadow-sm 
                border  focus:outline-none focus:ring-1`,
                error
                  ? "select-error"
                  : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              )}
            >
              <span className="flex items-center">
                {value?.icon && <span className="pr-3">{value.icon}</span>}
                <span className="block truncate">
                  {value?.label ? value.label : "Select"}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <Icon
                  name="chevron-up-down"
                  className="h-5 w-5 stroke-gray-400"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={cn(
                  `absolute z-10 max-h-80 w-full overflow-auto rounded-md bg-white py-1 
                shadow-lg ring-1 ring-indigo-300 focus:outline-none z-20`,
                  { "bottom-10": expandUp }
                )}
              >
                {items.length > 0 &&
                  items.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          cn(
                            "relative flex items-center cursor-pointer select-none py-2 pl-3 pr-9",
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            {
                              "pl-6": item.isChild,
                              "border-b bg-gray-100": item.isParent,
                            }
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            {item.icon && (
                              <span className="pr-3">{item.icon}</span>
                            )}
                            <span
                              className={cn(
                                "block truncate",
                                selected || item.isParent
                                  ? "font-medium"
                                  : "font-normal"
                              )}
                            >
                              <span>{item.label}</span>
                            </span>

                            {selected ? (
                              <span
                                className={cn(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <Icon name="check" className="stroke-current" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
