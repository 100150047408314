import React, { type PropsWithChildren } from "react";
import cn from "clsx";
import { useStore } from "effector-react";
import { $itemsCollapsed, setItemCollapsed } from "../sidebar.model";
import { useLocation } from "react-router-dom";
import { Icon } from "shared/ui/icon";

interface Props extends PropsWithChildren {
  className?: string;
  path: string;
  index: number;
  label?: string;
  icon?: any;
  collapsed?: boolean;
}

export const SidebarItemCollapsed = ({
  index,
  children,
  path,
  icon,
  className,
  label,
  collapsed,
}: Props) => {
  const itemsCollapsed = useStore($itemsCollapsed);
  const isItemCollapsed =
    itemsCollapsed && itemsCollapsed[index]
      ? itemsCollapsed[String(index)]
      : false;

  const location = useLocation();
  const isActive = location.pathname.startsWith(path);
  return (
    <div>
      <span
        className={cn(
          className,
          "bg-gray-800 flex items-center p-3 text-gray-200 border-b border-b-gray-700 hover:bg-indigo-700 select-none cursor-pointer",
          {
            "bg-gradient bg-gradient-to-r from-indigo-700 to-gray-900":
              isActive,
            "bg-gray-800": isItemCollapsed,
          }
        )}
        onClick={() => setItemCollapsed({ key: String(index) })}
      >
        {icon && <Icon name={icon} className="w-[22px] h-[22px] fill-white" />}
        {!collapsed && <span className="flex-1 pl-3">{label}</span>}
        {isItemCollapsed ? (
          <Icon name="chevron-up" className="stroke-white" />
        ) : (
          <Icon name="chevron-down" className="stroke-white" />
        )}
      </span>
      {isItemCollapsed && children}
    </div>
  );
};
