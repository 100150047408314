import { createEffect, createEvent, createStore } from "effector";

export interface Toast {
  id: string;
  title?: string;
  text: string;
  type: "error" | "info" | "success" | "warning";
}

export const $toasts = createStore<Toast[]>([]);

export const addToast = createEvent<Toast>();
$toasts.on(addToast, (state, toast) => [toast, ...state]);

export const removeToastById = createEvent<string>();
$toasts.on(removeToastById, (state, id) => state.filter((t) => t.id !== id));

export const addToastFx = createEffect((toast: Toast) => {
  addToast(toast);
  setTimeout(() => removeToastById(toast.id), 5000);
});
