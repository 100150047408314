import React from "react";
import { type Property, propertyFeatures } from "shared/api";
import { InputCheckbox } from "shared/ui/inputs/input-checkbox";
import { propertyCreate } from "../property-create.model";
import { propertyEdit } from "../property-edit.model";

interface Props {
  property: Property;
}

export const PropertyFeatures = ({ property }: Props) => {
  const isNew = !property?.id;
  const propertyModel = isNew ? propertyCreate : propertyEdit;

  return (
    <div className="flex gap-3 flex-wrap">
      {!property.source
        ? propertyFeatures.map((feature) => {
            return (
              <label
                key={feature.value}
                className="flex items-center cursor-pointer bg-gray-100 p-2 rounded-md"
              >
                <InputCheckbox
                  value={feature.value}
                  onChange={(e) =>
                    propertyModel.update({
                      key: "features",
                      value: e.target.value,
                    })
                  }
                  checked={!!property.features?.includes(feature.value)}
                />
                <span className="ml-1">{feature.label}</span>
              </label>
            );
          })
        : property.features?.length &&
          property.features.map((feature, index) => (
            <label
              key={index}
              className="flex items-center cursor-pointer bg-gray-100 p-2 rounded-md"
            >
              <InputCheckbox
                value={feature}
                onChange={() => undefined}
                checked
              />
              <span className="ml-1">{feature}</span>
            </label>
          ))}
    </div>
  );
};
