import { useGate, useStore } from "effector-react";
import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { LayoutBase } from "shared/ui/layouts";
import { BlockEditForm } from "modules/block/ui/block-edit-form";
import { blockEditModel } from "modules/block/block.model";
import { Loader } from "shared/ui/loader";

export const BlockEdit = () => {
  const params = useParams<{ id?: string }>();
  const block = useStore(blockEditModel.$post);
  const blockRemovedId = useStore(blockEditModel.$postRemovedId);
  const loading = useStore(blockEditModel.$loading);
  const navigate = useNavigate();

  const blockId = Number(params.id);
  useGate(blockEditModel.PostEditGate, { postId: blockId });

  useEffect(() => {
    blockRemovedId === blockId && navigate("/block");
  }, [blockRemovedId]);

  console.log("=====block edit");
  // console.log(block);

  return (
    <LayoutBase
      title={`Edit block`}
      subtitle={`#${blockId}: ${block?.title ?? ""}`}
      actions={
        <>
          <NavLink to="/block/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
          <NavLink to="/block" className="btn btn-secondary btn-sm">
            All blocks
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {block && <BlockEditForm block={block} />}
    </LayoutBase>
  );
};
