import "app/styles/index.css";
// import "effector-logger/inspector";
import React from "react";
import { Routing } from "pages/routing";
import { BrowserRouter } from "react-router-dom";
import { checkAuthFx } from "modules/auth/auth.model";

checkAuthFx();

export const App = () => {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
};
