import React, { PropsWithChildren } from "react";
import cn from "clsx";
import { FormLabel } from "./form-label";

interface Props extends PropsWithChildren {
  label?: string;
  variant?: "light" | "dark" | "dark-light";
  size?: "sm" | "md" | "lg";
  className?: string;
}

export const Box = ({
  label,
  children,
  size = "sm",
  variant = "light",
  className,
}: Props) => {
  return (
    <div
      className={cn(className, "my-3 border rounded-md shadow-md", {
        "p-3": size === "sm",
        "p-12": size === "lg",
        "bg-white": variant === "light",
        "bg-neutral": variant === "dark",
        "bg-gray-300": variant === "dark-light",
      })}
    >
      {label && (
        <FormLabel
          className={cn({
            "text-black": variant === "light" || variant === "dark-light",
            "text-white": variant === "dark",
          })}
          text={label}
        />
      )}
      {children}
    </div>
  );
};
