import React, { type FormEvent } from "react";
import { Input } from "shared/ui/inputs";
import {
  loginChanged,
  passwordChanged,
  loginFormSubmit,
  $loginFormState,
  $loginFormPending,
  $errors,
} from "../auth-form.model";
import { useStore } from "effector-react";
import { FormErrors } from "./form-errors";
import { Button } from "shared/ui/buttons/button";

export const FormLogin = () => {
  const credentials = useStore($loginFormState);
  const loading = useStore($loginFormPending);
  const errors = useStore($errors);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    loginFormSubmit();
  };

  return (
    <div className="flex-1 flex items-center justify-center p-5 bg-gray-800">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body items-center text-center">
          <h1 className="card-title mb-3">Sign in to your account</h1>

          {errors?.status && (
            <div className="mb-3">
              <FormErrors errors={errors} />
            </div>
          )}

          <form onSubmit={handleSubmit} className="w-full space-y-5">
            <Input
              type="text"
              error={errors?.login}
              required
              minLength={3}
              maxLength={16}
              placeholder="login"
              value={credentials.login}
              onChange={(e) => loginChanged(e.target.value)}
            />
            <Input
              error={errors?.password}
              type="password"
              required
              minLength={6}
              maxLength={32}
              placeholder="password"
              value={credentials.password}
              onChange={(e) => passwordChanged(e.target.value)}
            />
            <div className="mt-5">
              <Button type="submit" loading={loading} fullWidth>
                Sign in
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
