import React, { useMemo } from "react";
import type { Field } from "modules/field/field.types";
import { Box } from "shared/ui/box";
import { fieldChangeData } from "../../field.model";
import { TextDataType } from "modules/field/field.types";
import { Textarea } from "shared/ui/inputs";

interface Props {
  field: Field;
}

export const FieldTextarea = ({ field }: Props) => {
  const data = field.data as TextDataType;

  return useMemo(() => {
    return (
      <Box label={`${field.label} (${field.name})`}>
        <Textarea
          sizing="sm"
          value={data?.value || ""}
          onChange={(e) =>
            fieldChangeData({ field, key: "value", value: e.target.value })
          }
        />
      </Box>
    );
  }, [data?.value, field]);
};
