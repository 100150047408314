import React from "react";
import { SelectBox } from "shared/ui/select-box";
import { type Field } from "modules/field/field.types";
import { $selectedField, selectedFieldChange } from "../field.model";
import { useStore } from "effector-react";
import { ButtonAdd } from "shared/ui/buttons";

interface Props {
  selectListFields: Field[];
  textBtnAdd?: string;
  onAdd: (selectedField: Field) => void;
}

export const FieldSelect = ({ selectListFields, textBtnAdd, onAdd }: Props) => {
  const selectedField = useStore($selectedField);
  const handleAddField = () => {
    selectedField && onAdd(selectedField);
  };

  return (
    <>
      <SelectBox<Field>
        value={selectedField}
        items={selectListFields}
        onChange={selectedFieldChange}
      />
      {selectedField && (
        // add field
        <div className="mt-3">
          <ButtonAdd
            onClick={handleAddField}
            text={textBtnAdd ?? "Add"}
            fullWidth
          />
        </div>
      )}
    </>
  );
};
