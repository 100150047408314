import { useGate, useStore } from "effector-react";
import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { LayoutBase } from "shared/ui/layouts";
import { ReviewEditForm } from "modules/review/ui/review-edit-form";
import { reviewEdit } from "modules/review/review-edit.model";
import { Loader } from "shared/ui/loader";

export const ReviewEdit = () => {
  const params = useParams<{ id?: string }>();
  const review = useStore(reviewEdit.$review);
  const reviewRemovedId = useStore(reviewEdit.$reviewRemovedId);
  const loading = useStore(reviewEdit.$loading);
  const navigate = useNavigate();

  const reviewId = Number(params.id);
  useGate(reviewEdit.ReviewEditGate, { id: reviewId });

  useEffect(() => {
    reviewRemovedId === reviewId && navigate("/review");
  }, [reviewRemovedId]);

  // console.log("=====review edit");
  // console.log(review);

  return (
    <LayoutBase
      title={`Edit review`}
      subtitle={`#${reviewId}: ${review?.title ?? ""}`}
      actions={
        <>
          <NavLink to="/review/create" className="btn btn-secondary btn-sm">
            Add new
          </NavLink>
          <NavLink to="/review" className="btn btn-secondary btn-sm">
            All reviews
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {review && <ReviewEditForm review={review} />}
    </LayoutBase>
  );
};
