import React, { type PropsWithChildren } from "react";
import cn from "clsx";
import { Icon } from "./icon";

interface Props extends PropsWithChildren {
  variant?: "warning";
  className?: string;
}

export const Alert = ({ variant, className, children }: Props) => {
  return (
    <div
      className={cn(className, "alert shadow-lg", {
        "alert-warning": variant === "warning",
      })}
    >
      <div>
        {variant === "warning" && (
          <Icon
            name="exclamation-circle"
            className="w-[26px] h-[26px] stroke-white"
          />
        )}
        <span>{children}</span>
      </div>
    </div>
  );
};
