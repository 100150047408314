import React from "react";
import type { ButtonProps } from "shared/ui/buttons/button";
import { Button } from "shared/ui/buttons/button";
import { Icon } from "../icon";

interface Props extends Omit<ButtonProps, "children"> {
  text?: string;
}

export const ButtonAdd = (props: Props) => {
  const { text, ...rest } = props;
  return (
    <Button
      variant={text ? "icon-text" : "square"}
      color="secondary"
      size="sm"
      {...rest}
    >
      <Icon name="plus" className="w-[20px] h-[20px] stroke-white" />
      {text && text}
    </Button>
  );
};
