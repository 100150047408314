import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { blockCreateModel, blockEditModel } from "../block.model";
import { Button } from "shared/ui/buttons/button";
import { ButtonRemove } from "shared/ui/buttons";
import { Box } from "shared/ui/box";
import { type Block } from "shared/api";

interface Props {
  block: Block;
}

export const BlockRightPanelControls = ({ block }: Props) => {
  const isNewBlock = !block?.id;
  return (
    <Box className="space-y-3">
      {isNewBlock ? (
        <>
          <Button
            color="accent"
            onClick={blockCreateModel.submitForm}
            fullWidth
          >
            Save new block
          </Button>
        </>
      ) : (
        <>
          <div>
            <FormLabel text="Created:" inline />
            {block?.createdAt && new Date(block.createdAt).toLocaleString()}
          </div>
          <div>
            <FormLabel text="Updated:" inline />
            {block?.updatedAt && new Date(block.updatedAt).toLocaleString()}
          </div>
          <div className="text-right">
            <ButtonRemove
              text="Remove"
              onClick={() =>
                blockEditModel.removePostWithAttachmentsFx(block.id!)
              }
            />
          </div>
          <Button color="accent" onClick={blockEditModel.submitForm} fullWidth>
            Update block
          </Button>
        </>
      )}
    </Box>
  );
};
