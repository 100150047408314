import React, { useEffect } from "react";
import { LayoutBase } from "shared/ui/layouts";
import { ReviewEditForm } from "modules/review/ui/review-edit-form";
import { useGate, useStore } from "effector-react";
import { NavLink, useNavigate } from "react-router-dom";
import { reviewCreate } from "modules/review/review-create.model";
import { Loader } from "shared/ui/loader";

export const ReviewCreate = () => {
  const review = useStore(reviewCreate.$review);
  const navigate = useNavigate();
  const loading = useStore(reviewCreate.$loading);

  useGate(reviewCreate.ReviewCreateGate, {
    text: "",
    rating: 0,
    attachments: null,
    hidden: true,
  });

  useEffect(() => {
    review?.id && navigate(`/review/${review?.id}/edit`);
  }, [review?.id]);

  console.log("=====review create");
  // console.log(review);

  return (
    <LayoutBase
      title="Create new review"
      actions={
        <>
          <NavLink to="/review" className="btn btn-secondary btn-sm">
            All reviews
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {!review?.id && <ReviewEditForm review={review} />}
    </LayoutBase>
  );
};
