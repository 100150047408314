import { api, response } from "./axios";

export interface User {
  id: number;
  login: string;
  name: string;
  password?: string;
  email?: string;
}

export interface UserLoginRequest {
  login: string;
  password: string;
}

export interface UserLogoutResponse {
  message: "success";
}

export type AuthErrors = Record<string, string>;

export const auth = {
  login: (loginUserData: UserLoginRequest): Promise<User> =>
    api.post("/auth/login", loginUserData).then(response),
  check: (): Promise<User> => api.get("/auth/check").then(response),
  refresh: (): Promise<User> => api.get("/auth/refresh").then(response),
  logout: (): Promise<UserLogoutResponse> =>
    api.get("/auth/logout").then(response),
};
