import { auth, type User, UserLoginRequest } from "shared/api/auth";
import {
  createEffect,
  createEvent,
  createStore,
  forward,
  merge,
  sample,
} from "effector";

export const $authUser = createStore<User | null>(null);
export const setAuth = createEvent<User | null>();
export const $isAuthenticated = $authUser.map((user) => user !== null);
export const $authPending = createStore(false);

$authUser.on(setAuth, (_, user) => user);

export const loginUserFx = createEffect({
  handler: async (loginData: UserLoginRequest) => {
    const user = await auth.login(loginData);
    return user || null;
  },
});

export const logoutUserFx = createEffect({
  handler: async () => {
    await auth.logout();
    return null;
  },
});

export const checkAuthFx = createEffect({
  handler: async () => {
    const user = await auth.check();
    return user || null;
  },
});

forward({
  from: merge([loginUserFx.doneData, checkAuthFx.doneData]),
  to: setAuth,
});

sample({
  clock: logoutUserFx.finally,
  fn: () => null,
  target: $authUser,
});

forward({
  from: merge([checkAuthFx.pending, logoutUserFx.pending]),
  to: $authPending,
});
