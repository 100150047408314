import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { reviewCreate } from "../review-create.model";
import { reviewEdit } from "../review-edit.model";
import { Button } from "shared/ui/buttons/button";
import { ButtonRemove } from "shared/ui/buttons";
import { Box } from "shared/ui/box";
import { type Review } from "shared/api";
import { InputToggle } from "shared/ui/inputs";

interface Props {
  review: Review;
}

export const ReviewRightPanelControls = ({ review }: Props) => {
  const isNew = !review?.id;
  return (
    <Box className="space-y-3">
      {isNew ? (
        <>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={review?.hidden}
              onChange={() =>
                reviewCreate.update({
                  key: "hidden",
                  value: !review?.hidden,
                })
              }
            />
          </div>

          <Button color="accent" onClick={reviewCreate.submitForm} fullWidth>
            Save new review
          </Button>
        </>
      ) : (
        <>
          <div>
            <FormLabel text="Created:" inline />
            {review?.createdAt && new Date(review.createdAt).toLocaleString()}
          </div>
          <div>
            <FormLabel text="Updated:" inline />
            {review?.updatedAt && new Date(review.updatedAt).toLocaleString()}
          </div>
          <div className="flex items-center">
            <FormLabel text="Hidden" inline />
            <InputToggle
              checked={review?.hidden}
              onChange={() =>
                reviewEdit.update({
                  key: "hidden",
                  value: !review?.hidden,
                })
              }
            />
          </div>
          <div className="text-right">
            <ButtonRemove
              text="Remove"
              onClick={() =>
                reviewEdit.removeReviewWithAttachmentsFx(review.id!)
              }
            />
          </div>
          <Button color="accent" onClick={reviewEdit.submitForm} fullWidth>
            Update review
          </Button>
        </>
      )}
    </Box>
  );
};
