import React from "react";
import type { Field } from "modules/field/field.types";
import { useStore } from "effector-react";
import { $fields } from "../../field.model";
import { FieldByType } from "./field-by-type";
import { Box } from "shared/ui/box";

interface Props {
  field: Field;
}

export const FieldRepeaterGroup = ({ field }: Props) => {
  const fields = useStore($fields);
  const childRepeater = fields.filter((f) => f.parentId === field.id);
  return (
    <Box label={`${field.label} (${field.name})`} variant={"dark-light"}>
      {childRepeater.length > 0 && (
        <>
          {childRepeater.map((field) => {
            return <FieldByType key={field.id} field={field} />;
          })}
        </>
      )}
    </Box>
  );
};
