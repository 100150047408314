import React from "react";
import type { Field } from "modules/field/field.types";
import { Box } from "shared/ui/box";
import { fieldChangeData } from "../../../field.model";
import { TextDataType } from "modules/field/field.types";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import { StarterKit } from "@tiptap/starter-kit";
import { MenuBar } from "./menu-bar";

interface Props {
  field: Field;
}

export const FieldTextEditor = ({ field }: Props) => {
  const data = field.data as TextDataType;

  const editor = useEditor({
    extensions: [
      // Color.configure({ types: [TextStyle.name, ListItem.name] }),
      // TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        // bulletList: {
        //   keepMarks: true,
        //   keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
        // orderedList: {
        //   keepMarks: true,
        //   keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
      }),
      Link.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "text-editor textarea textarea-bordered w-full text-base min-h-[100px]",
      },
    },
    content: data?.value ?? "",
    injectCSS: false,
  });

  return (
    <Box label={`${field.label} (${field.name})`}>
      {editor && <MenuBar editor={editor} />}
      <div className="mt-3">
        <EditorContent
          editor={editor}
          onBlur={() =>
            fieldChangeData({ field, key: "value", value: editor?.getHTML() })
          }
        />
      </div>
    </Box>
  );
};
