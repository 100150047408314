import { api, response } from "./axios";
import { type AttachmentByFieldId } from "./attachment";
import { getSearchParamsAsJson } from "./lib";
import type { FindAllOptions, Paginated } from "./types";

export interface Review {
  id?: number;
  title?: string;
  attachments?: AttachmentByFieldId | null;
  text: string;
  rating: number;
  author?: string;
  hidden: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export const getReviews = (
  params?: FindAllOptions
): Promise<Paginated<Review>> => {
  const searchParams = getSearchParamsAsJson(params);
  return api.get(`/review?${searchParams}`).then(response);
};

export const getReview = ({ id }: { id: number }): Promise<Review> => {
  return api.get(`/review/${id}`).then(response);
};

export const reviewCreate = (review: Review): Promise<Review> => {
  return api.post(`/review`, review).then(response);
};

export const reviewEdit = (review: Review): Promise<Review> => {
  return api.put(`/review`, review).then(response);
};

export const reviewRemove = (id: number): Promise<number> => {
  return api.delete(`/review/${id}`).then(response);
};
