import React from "react";
import { useStore } from "effector-react";
import {
  $fields,
  fieldUpdate,
  fieldChangeOrder,
  fieldRemoveFx,
} from "../field.model";
import { FieldByType } from "./field-type";
import { Box } from "shared/ui/box";
import { ButtonRemove, ButtonOrder, ButtonCollapse } from "shared/ui/buttons";

export const FieldList = () => {
  const fields = useStore($fields);

  // console.log("--FieldList");
  // console.log(fields);

  if (!fields.length) {
    return null;
  }

  /* root fields (blocks) */
  const rootFields = fields.filter((f) => !f.parentId);

  rootFields.sort((a, b) => {
    if (a.order && b.order) {
      return a.order > b.order ? 1 : -1;
    }

    return 1;
  });

  return (
    <>
      {rootFields.map((field, index) => {
        const childFields = fields.filter((f) => f.parentId === field.id);

        const prevField = rootFields[index - 1];
        const nextField = rootFields[index + 1];
        return (
          <Box key={field.id} variant="dark">
            {/* field info */}
            <div className="flex items-center rounded-md">
              <div className="flex-1">
                <div className="text-white">
                  <strong>{field.label}</strong> | {field.name} | {field.id}
                </div>
              </div>
              {/* action buttons */}
              <div className="flex gap-2">
                {/* toggle collapsed */}
                <ButtonCollapse
                  collapsed={!!field.collapsed}
                  onClick={() =>
                    fieldUpdate({
                      field,
                      key: "collapsed",
                      value: !field.collapsed,
                    })
                  }
                />
                {/* order prev */}
                {prevField ? (
                  <ButtonOrder
                    order="up"
                    onClick={() =>
                      fieldChangeOrder({
                        fromField: field,
                        toField: prevField,
                      })
                    }
                  />
                ) : null}
                {/* order next */}
                {nextField ? (
                  <ButtonOrder
                    order="down"
                    onClick={() =>
                      fieldChangeOrder({
                        fromField: field,
                        toField: nextField,
                      })
                    }
                  />
                ) : null}
                {/* remove */}
                <ButtonRemove
                  className="ml-2"
                  onClick={() => fieldRemoveFx(field)}
                />
              </div>
            </div>
            {/* field type */}
            {field.collapsed &&
              childFields?.length > 0 &&
              childFields.map((field) => {
                return <FieldByType key={field.id} field={field} />;
              })}
          </Box>
        );
      })}
    </>
  );
};
