import React from "react";
import cn from "clsx";

interface Props {
  name: string;
  className?: string;
}

export const Icon = ({ name, className }: Props) => {
  return (
    <svg className={cn("w-[16px] h-[16px]", className)}>
      <use xlinkHref={`/sprites/icon.svg#${name.toLocaleLowerCase()}`} />
    </svg>
  );
};
