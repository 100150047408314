import React from "react";
import { type Page } from "shared/api/page";
import { initialPageContentFields } from "modules/page/page.config";
import { Box } from "shared/ui/box";
import { FieldList } from "modules/field/ui/field-list";
import { FieldSelect } from "modules/field/ui/field-select";
import { LayoutPostEdit } from "shared/ui/layouts/layout-post-edit";
import { FormLabel } from "shared/ui/form-label";
import { PageBaseControls } from "./page-base-controls.";
import { PageRightPanelControls } from "./page-right-panel-controls";
import { fieldAdd } from "../../field/field.model";

interface Props {
  page: Page | null;
}

export const PageEditForm = ({ page }: Props) => {
  console.log("---page");
  console.log(page);

  if (!page) return null;

  return (
    <>
      <LayoutPostEdit
        content={
          <>
            <PageBaseControls page={page} />
            {/* field list */}
            <FieldList />
          </>
        }
        rightPanel={
          <>
            <PageRightPanelControls page={page} />
            {/* select and adding fields block */}
            <Box>
              <FormLabel text="Content blocks" />
              <FieldSelect
                selectListFields={initialPageContentFields}
                onAdd={(selectedField) => {
                  fieldAdd({ initialField: selectedField });
                }}
                textBtnAdd="Add block"
              />
            </Box>
          </>
        }
      />
    </>
  );
};
