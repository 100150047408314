import { type Field } from "modules/field/field.types";
import { type ValidateSchema } from "modules/validation/validation.lib";

export const initialPageContentFields: Field[] = [
  {
    type: "block",
    name: "homePromo",
    label: "Home promo",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "text",
          label: "Text",
        },
        {
          type: "button",
          name: "button",
          label: "Button",
        },
        {
          type: "block-rel",
          name: "homePromoStaticBlock",
          label: "",
        },
      ],
    },
  },
  {
    type: "block",
    name: "listWithImage",
    label: "List with image",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "textarea",
          name: "list",
          label: "List items",
        },
      ],
    },
  },
  {
    type: "block",
    name: "listWithStrictImage",
    label: "List with strict image",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          type: "textarea",
          name: "list",
          label: "List items",
        },
      ],
    },
  },
  {
    type: "block",
    name: "imageWithOverlapText",
    label: "Image with overlap text",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          name: "button",
          type: "button",
          label: "Button",
        },
      ],
    },
  },
  {
    type: "block",
    name: "imageWithContent",
    label: "Image with content",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "text-editor",
          name: "text",
          label: "Text",
        },
      ],
    },
  },
  {
    type: "block",
    name: "sloganWithText",
    label: "Slogan with text",
    data: {
      initialFields: [
        {
          type: "textarea",
          name: "slogan",
          label: "Slogan",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          name: "button",
          type: "button",
          label: "Button",
        },
        {
          type: "select",
          name: "sloganAlign",
          label: "Slogan align",
          data: {
            initialData: [
              { id: "left", label: "left" },
              { id: "right", label: "right" },
            ],
          },
        },
        {
          type: "select",
          name: "bgColor",
          label: "Background color",
          data: {
            initialData: [
              { id: "primary", label: "primary" },
              { id: "secondary", label: "secondary" },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "sloganWithTextExtend",
    label: "Slogan with text extend",
    data: {
      initialFields: [
        {
          type: "repeater",
          name: "itemsSloganExtend",
          label: "Image with text",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupSloganExtend",
                label: "Item",
                data: {
                  initialFields: [
                    {
                      type: "text",
                      name: "title",
                      label: "Title",
                    },
                    {
                      type: "textarea",
                      name: "text",
                      label: "Text",
                    },
                    {
                      type: "button",
                      name: "button",
                      label: "Button",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "titleWithListServices",
    label: "Title with list services",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "subtitle",
          label: "Subtitle",
        },
        {
          type: "block-rel",
          name: "services",
          label: "",
        },
      ],
    },
  },
  {
    type: "block",
    name: "titleWithTiledItems",
    label: "Title with tiled items",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "subtitle",
          label: "Subtitle",
        },
        {
          type: "repeater",
          name: "repeaterTiledItems",
          label: "Tiled items",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupTiledItems",
                label: "Item",
                data: {
                  initialFields: [
                    {
                      name: "icon",
                      type: "icon",
                      label: "Icon",
                    },
                    {
                      name: "title",
                      type: "text",
                      label: "Title",
                    },
                    {
                      name: "text",
                      type: "text",
                      label: "Text",
                    },
                    {
                      name: "button",
                      type: "button",
                      label: "Button",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "contentRowsWithImages",
    label: "Content rows with images",
    data: {
      initialFields: [
        {
          type: "repeater",
          name: "rowsTextImages",
          label: "Image with text",
          data: {
            initialFields: [
              {
                type: "repeater-group",
                name: "groupRowsTextImages",
                label: "Item",
                data: {
                  initialFields: [
                    {
                      type: "image",
                      name: "image",
                      label: "Image",
                    },
                    {
                      type: "text-editor",
                      name: "content",
                      label: "Text",
                    },
                    {
                      type: "button",
                      name: "button",
                      label: "Button",
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "contentExtraWithImage",
    label: "Content extra with image",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "text",
          name: "subtitle",
          label: "Subtitle",
        },
        {
          type: "button",
          name: "button",
          label: "Button",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
        {
          type: "text-editor",
          name: "textAdditional",
          label: "Addition text",
        },
        {
          type: "text",
          name: "titleExtra",
          label: "Title (extra block)",
        },
        {
          type: "text",
          name: "textExtra",
          label: "Text (extra block)",
        },
        {
          type: "button",
          name: "buttonExtra",
          label: "Button (extra block)",
        },
      ],
    },
  },
  {
    type: "block",
    name: "textEditor",
    label: "Text editor",
    data: {
      initialFields: [
        {
          type: "text-editor",
          name: "content",
          label: "Content",
        },
      ],
    },
  },
  {
    type: "block",
    name: "avatarWithQuote",
    label: "Avatar with quote",
    data: {
      initialFields: [
        {
          type: "image",
          name: "image",
          label: "Image",
        },
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
      ],
    },
  },
  {
    type: "block",
    name: "contactUs",
    label: "Contact us",
    data: {
      initialFields: [
        {
          type: "textarea",
          name: "text",
          label: "Text",
        },
      ],
    },
  },
  {
    type: "block",
    name: "review",
    label: "Reviews",
    data: {
      initialFields: [
        {
          type: "text",
          name: "title",
          label: "Title",
        },
        {
          type: "select",
          name: "reviewShowType",
          label: "Show type",
          data: {
            initialData: [
              { id: "more", label: "Show all with more button" },
              { id: "last", label: "Show last reviews with link" },
            ],
          },
        },
      ],
    },
  },
  {
    type: "block",
    name: "contactWithMap",
    label: "Contact with map",
  },
  {
    type: "block-static",
    name: "blockStatic",
    label: "Static block",
    data: {
      initialFields: [
        {
          type: "block-rel",
          name: "blockRel",
          label: "Block relation",
        },
      ],
    },
  },
];

export const pageSchemaValidate: ValidateSchema = {
  title: ["required"],
};
