import {
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
  sample,
} from "effector";
import {
  attachmentUpdateByIds,
  getProperty,
  getProperties,
  propertyCreate,
  propertyEdit,
  propertyRemove,
  type AttachmentByFieldId,
  type Property,
  type PropertyLocation,
} from "shared/api";
import { createGate } from "effector-react";
import {
  attachmentsInitialize,
  uploadAttachmentsFx,
} from "../attachment/attachment.model";
import { FindAllOptions } from "shared/api/types";

export const propertyModel = () => {
  const $property = createStore<Property | null>(null);
  const $properties = createStore<Property[]>([]);
  const $propertyRemovedId = createStore<number | null>(null);
  const PropertyCreateGate = createGate<Property>();
  const PropertyEditGate = createGate<{ id: number }>();
  const PropertiesGate = createGate<FindAllOptions | undefined>();

  const submitForm = createEvent();
  const update = createEvent<{ key: keyof Property; value: unknown }>();
  $property.on(update, (state, { key, value }) => {
    if (state) {
      // set location to null, if changed province
      if (key === "province") {
        const location =
          key === "province" && state.province !== value
            ? null
            : state.location;
        return { ...state, location, [key]: value as PropertyLocation | null };
      }

      if (key === "features") {
        const features = state[key] as string[];
        const current = value as string;

        if (features?.length) {
          if (features.includes(current)) {
            return { ...state, [key]: features.filter((f) => f !== current) };
          } else {
            return { ...state, [key]: [...features, current] };
          }
        }
        return { ...state, [key]: [current] };
      }

      return { ...state, [key]: value };
    }
  });

  const loadPropertiesFx = createEffect({
    handler: async (params?: FindAllOptions) => {
      return await getProperties(params);
    },
  });

  const loadPropertyByIdFx = createEffect({
    handler: async ({ id }: { id: number }) => {
      return await getProperty({ id });
    },
  });

  const initializeAttachmentsFx = createEffect({
    handler: async ({
      initialAttachments,
    }: {
      initialAttachments: AttachmentByFieldId | null;
    }) => {
      attachmentsInitialize({
        initialAttachments,
        fields: null,
        additionalFieldIds: ["preview", "images"],
      });
    },
  });

  const createPropertyWithAttachmentsFx = createEffect({
    handler: async ({
      property,
      attachments,
    }: {
      property: Property;
      attachments: AttachmentByFieldId | null;
    }) => {
      const created = await propertyCreate(property);

      if (attachments) {
        await uploadAttachmentsFx({
          object: "property",
          recordId: created.id as number,
          attachments,
          method: "create",
        });
      }

      return created;
    },
  });

  const updatePropertyAttachmentsFx = createEffect({
    handler: async ({
      property,
      attachments,
    }: {
      property: Property;
      attachments: AttachmentByFieldId | null;
    }) => {
      const newAttachments = await uploadAttachmentsFx({
        object: "property",
        recordId: property.id!,
        attachments,
        method: "update",
      });
      return {
        property: {
          ...(await propertyEdit(property)),
          attachments: newAttachments,
        },
        attachments: newAttachments,
      };
    },
  });

  const removePropertyWithAttachmentsFx = createEffect({
    handler: async (id: number): Promise<number> => {
      if (id) {
        // remove all attachments
        await attachmentUpdateByIds({
          object: "property",
          recordId: id,
          loadedAttachmentIds: [],
        });
      }
      return await propertyRemove(id);
    },
  });

  sample({
    clock: removePropertyWithAttachmentsFx.doneData,
    target: $propertyRemovedId,
  });

  const $loading = restore(
    merge([
      loadPropertyByIdFx.pending,
      loadPropertiesFx.pending,
      updatePropertyAttachmentsFx.pending,
      createPropertyWithAttachmentsFx.pending,
      removePropertyWithAttachmentsFx.pending,
      uploadAttachmentsFx.pending,
    ]),
    false
  );

  return {
    $property,
    $properties,
    $propertyRemovedId,
    $loading,
    PropertyCreateGate,
    PropertyEditGate,
    PropertiesGate,
    submitForm,
    update,
    initializeAttachmentsFx,
    loadPropertiesFx,
    loadPropertyByIdFx,
    createPropertyWithAttachmentsFx,
    updatePropertyAttachmentsFx,
    removePropertyWithAttachmentsFx,
  };
};
