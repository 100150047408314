import React from "react";
import { Loader } from "shared/ui/loader";
import { useGate, useStore } from "effector-react";
import { blogsModel, blogsPaginationModel } from "../blog.model";
import { type Blog } from "shared/api";
import { useRouterChangePageIndex, Pagination } from "features/pagination";
import { TablePosts } from "shared/ui/table-posts";
import { useNavigate } from "react-router-dom";
import { config } from "shared/config";

export const BlogList = () => {
  const blogs = useStore(blogsModel.$posts);
  const pending = useStore(blogsModel.$loading);
  useGate(blogsModel.PostsGate);
  const navigate = useNavigate();

  const pagination = useStore(blogsPaginationModel.params);
  useRouterChangePageIndex({
    pageIndex: pagination.pageIndex,
    callback: blogsPaginationModel.changePageIndex,
  });

  console.log(blogs);

  const getPreview = (item: Blog) => {
    const preview =
      item.attachments?.preview[0]?.meta?.sizes?.sm?.url ??
      item.attachments?.preview[0]?.url;

    return preview ? `${config.uploadUrl}/${preview}` : null;
  };

  return (
    <>
      {pending && <Loader />}
      <Pagination className="mb-3" prefixUrl="blog" {...pagination} />
      <TablePosts<Blog>
        items={blogs}
        colData={{
          preview: {
            header: "Preview",
            type: "image",
            data: (item) => getPreview(item),
            className: "min-w-[160px] w-[200px]",
          },
          title: {
            header: "Title",
            type: "strong-text",
            data: (item) => item.title,
            className: "min-w-[160px]",
          },
          updatedAt: {
            header: "Date edit",
            type: "text",
            data: (item) => new Date(item.updatedAt!).toLocaleString(),
            className: "min-w-[160px]",
          },
          hidden: {
            header: "Hidden",
            type: "hidden",
            data: (item) => item.hidden,
            className: "w-[80px]",
          },
        }}
        onRemove={blogsModel.removePostWithAttachmentsFx}
        onClick={(id) => navigate(`/blog/${id}/edit`)}
      />
      <Pagination className="mt-3" prefixUrl="blog" {...pagination} />
    </>
  );
};
