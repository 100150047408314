import { postModel } from "modules/post-field-attachment/post-field-attachment.model";
import {
  getPage,
  getPages,
  Page,
  pageCreate,
  pageEdit,
  pageRemove,
  selectShort,
} from "shared/api";
import { createPostEvents } from "modules/post-field-attachment/event-create";
import { updatePostEvents } from "modules/post-field-attachment/event-update";
import { initialPageContentFields, pageSchemaValidate } from "./page.config";
import { postsEvents } from "modules/post-field-attachment/event-list";
import { paginationModel } from "features/pagination/pagination.model";
import { postsPaginationEvents } from "../post-field-attachment/event-list-pagination";

const apiResolvers = {
  loadPosts: getPages,
  loadPostById: getPage,
  createPost: pageCreate,
  updatePost: pageEdit,
  removePost: pageRemove,
};

// create post
const postType = "page";
export const pageCreateModel = postModel<Page>(postType, apiResolvers);
createPostEvents<Page>(pageCreateModel, pageSchemaValidate);

// edit post
export const pageEditModel = postModel<Page>(postType, apiResolvers);
updatePostEvents<Page>(
  pageEditModel,
  initialPageContentFields,
  pageSchemaValidate
);

// results with pagination
export const pagesModel = postModel<Page>(postType, apiResolvers);
const take = 10;
export const pagesPaginationModel = paginationModel({
  loadFx: pagesModel.loadPostsFx,
  take,
  appendParams: { select: selectShort },
});
postsPaginationEvents<Page>(pagesModel, pagesPaginationModel);

// parent posts
export const parentPagesModel = postModel<Page>(postType, apiResolvers);
postsEvents<Page>(parentPagesModel);
