import React from "react";
import { FormLabel } from "shared/ui/form-label";
import { Box } from "shared/ui/box";
import { LayoutPostEdit } from "shared/ui/layouts";
import { PropertyLocationList } from "./property-location-list";
import { Input } from "shared/ui/inputs";
import { Button } from "shared/ui/buttons/button";
import { SelectBox } from "shared/ui/select-box";
import { type PropertyLocation } from "shared/api";
import { useGate, useStore } from "effector-react";
import {
  $createLocation,
  $createProvince,
  $errors,
  $locations,
  $provinces,
  $selectedProvince,
  changeCreateLocation,
  changeCreateProvince,
  LocationGate,
  selectedProvinceChange,
  submitCreateLocation,
  submitCreateProvince,
} from "../property-location.model";

export const PropertyLocationForm = () => {
  const selectedProvince = useStore($selectedProvince);
  const provinces = useStore($provinces);
  const locations = useStore($locations);
  const createProvince = useStore($createProvince);
  const createLocation = useStore($createLocation);
  const errors = useStore($errors);
  useGate(LocationGate, {
    include: {
      children: true,
      parent: true,
    },
  });

  console.log("-location");
  console.log(locations);

  return (
    <LayoutPostEdit
      content={<PropertyLocationList />}
      rightPanel={
        <>
          {/* new province */}
          <Box>
            <FormLabel text="New province" />
            <Input
              type="text"
              sizing="sm"
              value={createProvince}
              placeholder="Province name"
              onChange={(e) => changeCreateProvince(e.target.value)}
              error={errors.province}
            />
            <Button
              color="accent"
              className="mt-3"
              onClick={submitCreateProvince}
              fullWidth
              size="sm"
              disabled={!!errors.province}
            >
              Add province
            </Button>
          </Box>

          {/* new location */}
          <Box>
            <FormLabel text="New location" />
            <SelectBox<PropertyLocation>
              value={selectedProvince}
              items={provinces}
              onChange={selectedProvinceChange}
            />
            <Input
              type="text"
              sizing="sm"
              value={createLocation}
              placeholder="Location name"
              className="mt-3"
              onChange={(e) => changeCreateLocation(e.target.value)}
              error={errors.location}
            />
            <Button
              color="accent"
              className="mt-3"
              onClick={submitCreateLocation}
              fullWidth
              size="sm"
              disabled={!selectedProvince || !!errors.location}
            >
              Add location
            </Button>
          </Box>
        </>
      }
    />
  );
};
