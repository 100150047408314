import React from "react";
import { type Block } from "shared/api/block";
import { initialBlockContentFields } from "modules/block/block.config";
import { Box } from "shared/ui/box";
import { FieldList } from "modules/field/ui/field-list";
import { FieldSelect } from "modules/field/ui/field-select";
import { LayoutPostEdit } from "shared/ui/layouts/layout-post-edit";
import { FormLabel } from "shared/ui/form-label";
import { useStore } from "effector-react";
import { BlockRightPanelControls } from "./block-right-panel-controls";
import { $fields, fieldAdd, fieldRemoveAll } from "modules/field/field.model";

interface Props {
  block: Block | null;
}

export const BlockEditForm = ({ block }: Props) => {
  const fields = useStore($fields);
  console.log("---block");
  console.log(block);

  if (!block) return null;

  return (
    <>
      <LayoutPostEdit
        content={<FieldList />}
        rightPanel={
          <>
            {fields.length > 0 && <BlockRightPanelControls block={block} />}
            {/* select and adding fields block */}
            {fields.length === 0 && (
              <Box>
                <FormLabel text="Block" />
                <FieldSelect
                  selectListFields={initialBlockContentFields}
                  onAdd={(selectedField) => {
                    fieldRemoveAll();
                    fieldAdd({ initialField: selectedField });
                  }}
                  textBtnAdd={fields.length ? "Replace block" : "Add block"}
                />
              </Box>
            )}
          </>
        }
      />
    </>
  );
};
