import { Editor } from "@tiptap/react/dist/packages/react/src/Editor";
import { EditorMenuItemProps } from "./types";
import { useCallback } from "react";

export const useEditorItems = (editor: Editor): EditorMenuItemProps[] => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);
    const isExternal = url?.startsWith("http");

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url, target: isExternal ? "_blank" : null })
      .run();
  }, [editor]);

  return [
    {
      action: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold().run(),
      icon: "editor-bold",
      isActive: () => editor.isActive("bold"),
    },
    {
      action: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      icon: "editor-italic",
      isActive: () => editor.isActive("italic"),
    },
    {
      action: setLink,
      icon: "editor-link",
      isActive: () => editor.isActive("link"),
    },
    {
      action: () => editor.chain().focus().unsetLink().run(),
      icon: "editor-unlink",
      disabled: !editor.isActive("link"),
    },
    {
      type: "divider",
    },
    {
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      icon: "editor-h1",
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      icon: "editor-h2",
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    {
      action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      icon: "editor-h3",
      isActive: () => editor.isActive("heading", { level: 3 }),
    },
    {
      action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
      icon: "editor-h4",
      isActive: () => editor.isActive("heading", { level: 4 }),
    },
    {
      action: () => editor.chain().focus().setParagraph().run(),
      icon: "editor-paragraph",
      isActive: () => editor.isActive("paragraph"),
    },
    {
      type: "divider",
    },
    {
      action: () => editor.chain().focus().setTextAlign("left").run(),
      icon: "editor-align-left",
      isActive: () => editor.isActive({ textAlign: "left" }),
    },
    {
      action: () => editor.chain().focus().setTextAlign("center").run(),
      icon: "editor-align-center",
      isActive: () => editor.isActive({ textAlign: "center" }),
    },
    {
      action: () => editor.chain().focus().setTextAlign("right").run(),
      icon: "editor-align-right",
      isActive: () => editor.isActive({ textAlign: "right" }),
    },
    {
      type: "divider",
    },
    {
      action: () => editor.chain().focus().unsetAllMarks().run(),
      icon: "editor-format-clear",
    },
    {
      type: "divider",
    },
    {
      action: () => editor.chain().focus().undo().run(),
      icon: "editor-undo",
      disabled: !editor.can().chain().focus().undo().run(),
    },
    {
      action: () => editor.chain().focus().redo().run(),
      icon: "editor-redo",
      disabled: !editor.can().chain().focus().redo().run(),
    },
  ];
};
