import React, { type ReactNode, useState } from "react";
import { ButtonRemove } from "./buttons";
import { config } from "../config";
import cn from "clsx";
import { Button } from "./buttons/button";

type DataType = "image" | "text" | "strong-text" | "hidden" | "spoiler";

interface ColData<T> {
  [name: string]: {
    header?: string;
    type: DataType;
    data: (item: T) => unknown;
    className?: string;
  };
}

interface Props<T> {
  colData: ColData<T>;
  items: T[];
  onRemove?: (id: number) => void;
  onClick?: (id: number) => void;
  className?: string;
  renderChild?: (item: T[]) => ReactNode;
}

const RenderDataType = ({ type, data }: { type: DataType; data: unknown }) => {
  switch (type) {
    case "text":
      return <p className="text-line-clamp-3">{data as string}</p>;

    case "strong-text":
      return <strong>{data as string}</strong>;

    case "hidden":
      const hidden = data as boolean;
      return hidden ? (
        <span className="badge badge-outline badge-warning">Yes</span>
      ) : (
        <span className="badge badge-outline badge-accent">No</span>
      );

    case "image":
      const path = data as string;
      return path ? (
        <img
          className="h-[120px] w-full object-cover rounded-lg shadow-md"
          src={path}
          alt=""
          loading={"lazy"}
        />
      ) : null;

    default:
      return null;
  }
};

const TableRow = <T extends { [key: string]: any }>({
  item,
  colData,
  onRemove,
  onClick,
  renderChild,
}: {
  item: T;
  colData: ColData<T>;
  onRemove?: (id: number) => void;
  onClick?: (id: number) => void;
  renderChild?: (item: T[]) => ReactNode;
}) => {
  // const [isChildOpen, setIsChildOpen] = useState(false);

  return (
    <tr
      className="group cursor-pointer"
      onClick={() => onClick && onClick(item.id)}
    >
      {Object.entries(colData).map(([colName, param]) =>
        colData[colName] ? (
          <td
            key={colName}
            className={cn(
              colData[colName].className,
              "group-hover:bg-indigo-100"
            )}
          >
            <div className="flex items-center">
              <RenderDataType
                type={colData[colName].type}
                data={colData[colName].data(item)}
              />
              {/*  {item.children?.length ? (*/}
              {/*    <Button*/}
              {/*      className="ml-5"*/}
              {/*      size="xs"*/}
              {/*      onClick={() => setIsChildOpen(!isChildOpen)}*/}
              {/*    >*/}
              {/*      Show child*/}
              {/*    </Button>*/}
              {/*  ) : null}*/}
            </div>

            {/* child spoiler */}
            {item.children?.length && renderChild
              ? renderChild(item.children)
              : null}
          </td>
        ) : null
      )}
      {/* remove button */}
      <td className="w-[50px] group-hover:bg-indigo-100">
        <div className="flex justify-end gap-2">
          <ButtonRemove
            onClick={() => (onRemove ? onRemove(item.id) : undefined)}
          />
        </div>
      </td>
    </tr>
  );
};

export const TablePosts = <T extends { [key: string]: any }>({
  colData,
  items,
  onRemove,
  onClick,
  className,
  renderChild,
}: Props<T>) => {
  return (
    <div className={cn(className, "overflow-x-auto w-full")}>
      <table className="table w-full shadow-md">
        {/* head */}
        <thead>
          <tr>
            {/*<th>*/}
            {/*  <label>*/}
            {/*    <input type="checkbox" className="checkbox" />*/}
            {/*  </label>*/}
            {/*</th>*/}
            {Object.entries(colData).map(([colName, param], index) => (
              <th key={colName}>{param.header}</th>
            ))}
            <th />
          </tr>
        </thead>
        {/* rows */}
        <tbody>
          {items.map((item, index) => (
            <TableRow
              key={item.id}
              item={item}
              colData={colData}
              onClick={onClick}
              onRemove={onRemove}
              renderChild={renderChild}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
