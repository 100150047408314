import { createEffect, createEvent, createStore, sample } from "effector";
import type {
  AttachmentByFieldId,
  AttachmentRelation,
  AttachmentUploadMethod,
  AttachmentObject,
  AttachmentType,
  Attachment,
} from "shared/api/attachment";
import {
  attachmentRelationCreate,
  attachmentUpdateByIds,
  attachmentUpload,
} from "shared/api/attachment";
import type { Field } from "../field/field.types";
import {
  createNewAttachments,
  revokeAttachmentByUrl,
  revokeAttachments,
} from "./attachment.lib";
import { fieldRemoveAll, fieldRemoveFx } from "../field/field.model";

export const uploadAttachmentsFx = createEffect(
  async ({
    object,
    recordId,
    attachments,
    method,
  }: {
    object: AttachmentObject;
    recordId: number;
    attachments: AttachmentByFieldId | null; // null and 'update' - remove all attachments
    method: AttachmentUploadMethod;
  }) => {
    console.log("updateUploadAttachments", recordId);

    if (recordId && object) {
      let loadedAttachmentByFieldId: AttachmentByFieldId = {};
      const loadedAttachmentIds: number[] = [];
      const newRelations: AttachmentRelation[] = [];

      if (attachments) {
        // console.log("--attachments");
        // console.log(attachments);
        const fieldIds = Object.keys(attachments);
        // fields
        for (const fieldId of fieldIds) {
          // files
          for (const file of attachments[fieldId]) {
            // new file
            if (!file.id && file.file instanceof File) {
              try {
                const formData = new FormData();
                formData.append("file", file.file as Blob);
                const uploadedAttachment = await attachmentUpload(formData);

                // add new attachment
                loadedAttachmentByFieldId[fieldId]
                  ? loadedAttachmentByFieldId[fieldId].push(uploadedAttachment)
                  : (loadedAttachmentByFieldId[fieldId] = [uploadedAttachment]);

                loadedAttachmentIds.push(uploadedAttachment.id!);

                // add new relation
                newRelations.push({
                  object,
                  recordId,
                  fieldId,
                  attachmentId: uploadedAttachment.id!,
                });
              } catch (e) {
                console.log("upload error", e);
              }
            } else {
              // exist file
              loadedAttachmentByFieldId[fieldId]
                ? loadedAttachmentByFieldId[fieldId].push(file)
                : (loadedAttachmentByFieldId[fieldId] = [file]);
              loadedAttachmentIds.push(file.id!);
            }
          }
        }

        // add new relations for new files
        newRelations.length && (await attachmentRelationCreate(newRelations));
      }

      // update relations from loadedAttachmentIds = [] removed all attached
      if (method === "update") {
        await attachmentUpdateByIds({
          object,
          recordId,
          loadedAttachmentIds,
        });
      }

      console.log("-loadedAttachmentByFieldId");
      console.log(loadedAttachmentByFieldId);
      return Object.keys(loadedAttachmentByFieldId).length
        ? loadedAttachmentByFieldId
        : null;
    }
    return null;
  }
);

export const $attachments = createStore<AttachmentByFieldId | null>(null);

export const attachmentsInitialize = createEvent<{
  initialAttachments: AttachmentByFieldId | null;
  fields: Field[] | null;
  additionalFieldIds?: string[];
}>();

// init
$attachments.on(attachmentsInitialize, (state, payload) => {
  if (payload) {
    const { initialAttachments, fields, additionalFieldIds } = payload;

    console.log("----initialAttachments");
    console.log(initialAttachments);
    console.log("----initialFields");
    console.log(fields);

    let attachments: AttachmentByFieldId = {};
    if (initialAttachments) {
      if (fields?.length) {
        fields.forEach((field) => {
          const fieldId = field.id;
          // check exist fieldId in initialAttachments
          if (fieldId && initialAttachments[fieldId]) {
            attachments[fieldId] = [...initialAttachments[fieldId]];
          }
        });
      }

      // added attachments from additional fieldIds
      if (additionalFieldIds?.length) {
        additionalFieldIds.forEach((fieldId) => {
          if (fieldId && initialAttachments[fieldId]) {
            attachments[fieldId] = [...initialAttachments[fieldId]];
          }
        });
      }
    }

    console.log("----result attachments");
    console.log(attachments);

    return Object.keys(attachments)?.length ? attachments : null;
  }
  return null;
});

export const attachmentsAdd = createEvent<{
  fieldId: string;
  newFiles: FileList;
  allowType?: AttachmentType;
}>();
$attachments.on(attachmentsAdd, (state, { fieldId, newFiles, allowType }) => {
  if (newFiles.length) {
    const attachments = createNewAttachments(newFiles, allowType);
    if (attachments.length) {
      if (state && fieldId in state) {
        return { ...state, [fieldId]: [...state[fieldId], ...attachments] };
      }

      return { ...state, [fieldId]: attachments };
    }
  }
});

export const attachmentsChange = createEvent<{
  fieldId: string;
  newFiles: FileList | null;
  allowType?: AttachmentType;
}>();
$attachments.on(
  attachmentsChange,
  (state, { fieldId, newFiles, allowType }) => {
    if (state) {
      // revoked blob files
      state[fieldId]?.forEach((file) => {
        /* not id - is blob file */
        if (!file.id) {
          revokeAttachmentByUrl(file.url);
        }
      });

      // add new
      if (newFiles?.length) {
        const newAttachments = createNewAttachments(newFiles, allowType);
        if (newAttachments.length) {
          return {
            ...state,
            [fieldId]: newAttachments,
          };
        }
      } else {
        // null - removed all files
        const newAttachments = { ...state };
        delete newAttachments[fieldId];
        return newAttachments;
      }
    }

    // add new if empty
    if (!state && newFiles) {
      const newAttachments = createNewAttachments(newFiles, allowType);
      return { [fieldId]: newAttachments };
    }
  }
);

export const attachmentsRemoveOne = createEvent<{
  fieldId: string;
  removeIndex: number;
}>();
$attachments.on(attachmentsRemoveOne, (state, { fieldId, removeIndex }) => {
  if (state && state[fieldId]?.length) {
    const removedFile = state[fieldId][removeIndex];

    let updatedFiles: Attachment[];
    /* not id - is blob file */
    if (!removedFile.id) {
      revokeAttachmentByUrl(removedFile.url);
    }

    updatedFiles = state[fieldId].filter((_, i) => i !== removeIndex);

    // added files
    if (updatedFiles.length) {
      return {
        ...state,
        [fieldId]: updatedFiles,
      };
    } else {
      // removed field as empty
      const newAttachments = { ...state };
      delete newAttachments[fieldId];

      // exist fields
      if (Object.keys(newAttachments).length) {
        return newAttachments;
      }
      // not exists fields
      return null;
    }
  }
});

export const attachmentsRemoveAll = createEvent();
$attachments.on(attachmentsRemoveAll, (state) => {
  if (state) {
    const fieldIds = Object.keys(state);
    fieldIds.length &&
      fieldIds.forEach((fieldId) => {
        state[fieldId].length && revokeAttachments(state[fieldId]);
      });
    return null;
  }
});

export const attachmentsRevokeAll = createEvent();
$attachments.on(attachmentsRevokeAll, (state) => {
  if (state) {
    const fieldIds = Object.keys(state);
    fieldIds.length &&
      fieldIds.forEach((fieldId) => {
        state[fieldId].length && revokeAttachments(state[fieldId]);
      });
  }
});

export const attachmentsOrder = createEvent<{
  fieldId: string;
  fileIndex: number;
  order: 1 | -1;
}>();
$attachments.on(attachmentsOrder, (state, { fieldId, fileIndex, order }) => {
  if (state && state[fieldId].length > 1) {
    const newFiles = [...state[fieldId]];

    if (newFiles.length) {
      const deletedFile = newFiles.splice(fileIndex, 1)[0];
      newFiles.splice(fileIndex + order, 0, deletedFile);
    }

    return {
      ...state,
      [fieldId]: newFiles,
    };
  }
});

// effect fieldRemoveFx.doneData
$attachments.on(fieldRemoveFx.doneData, (state, payload) => {
  if (state && payload?.removedIds?.length) {
    const { removedIds } = payload;
    // console.log(attachmentFieldIds);
    console.log(removedIds);

    const attachmentFieldIds = Object.keys(state);
    let newAttachmentByFieldId: AttachmentByFieldId = {};

    attachmentFieldIds?.forEach((fieldId) => {
      // remove
      if (removedIds.some((removedId) => removedId === fieldId)) {
        // files
        state[fieldId]?.forEach((file) => {
          // blob files
          if (!file.id) {
            revokeAttachmentByUrl(file.url);
          }
        });
      } else {
        newAttachmentByFieldId[fieldId] = [...state[fieldId]];
      }
    });

    return newAttachmentByFieldId;
  }
});

// remove all attachments, where field removed
sample({
  clock: fieldRemoveAll,
  target: attachmentsRemoveAll,
});
