const parseObjWithDelimiter = (
  key: string,
  value: Record<string, any>
): any => {
  const keys = Object.keys(value);

  const parsed = keys.map((key) => {
    if (typeof value[key] === "object" && !Array.isArray(value[key])) {
      return `${key}.${parseObjWithDelimiter(key, value[key])}`;
    } else if (Array.isArray(value[key])) {
      return `${key}:${value[key].join(",")}`;
    }

    return `${key}:${value[key]}`;
  });

  return parsed.join("|");
};

export const getSearchParamsAsJson = (
  params?: Record<string, unknown> | any
): string => {
  if (typeof params !== "object" || Array.isArray(params)) return "";
  const keys = Object.keys(params);
  if (!keys.length) return "";

  let parsed: string[] = [];

  keys.forEach((key) => {
    parsed.push(`${key}=${JSON.stringify(params[key])}`);
  });

  return parsed.join("&");
};

// export const getSearchParams = (
//   params?: Record<string, unknown> | any
// ): string => {
//   const keys = Object.keys(params);
//   if (!keys.length) return "";
//
//   let parsed: string[] = [];
//
//   keys.forEach((key) => {
//     if (Array.isArray(params[key])) {
//       return parsed.push(`${key}=${params[key].join(",")}`);
//     }
//
//     if (typeof params[key] === "object") {
//       return parsed.push(`${key}=${parseObjWithDelimiter(key, params[key])}`);
//     }
//
//     if (params[key]) {
//       return parsed.push(`${key}=${params[key]}`);
//     }
//   });
//
//   return parsed.join("&");
// };
