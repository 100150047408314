import React from "react";
import cn from "clsx";

interface Props {
  size?: "sm" | "md" | "lg";
  rate?: number;
  className?: string;
  onChange?: (rate: number) => void;
}

export const Rating = ({ size = "md", rate, className, onChange }: Props) => {
  return (
    <div
      className={cn("rating", {
        "rating-md": size === "md",
        "rating-lg": size === "lg",
      })}
    >
      {[...new Array(6)].map((_, index) => {
        return (
          <input
            key={index}
            type="radio"
            name="rating-5"
            className={cn("mask mask-star-2 bg-orange-400", {
              hidden: index === 0,
            })}
            checked={rate ? rate === index : index === 0}
            onChange={() => (onChange ? onChange(index) : null)}
          />
        );
      })}
    </div>
  );
};
