import React from "react";
import { useStore } from "effector-react";
import { $provinces, removeLocationFx } from "../property-location.model";
import { type PropertyLocation } from "shared/api";
import { TablePosts } from "shared/ui/table-posts";
import { ButtonRemove } from "shared/ui/buttons";
import { Button } from "shared/ui/buttons/button";
import cn from "clsx";

interface Props {}

export const PropertyLocationList = ({}: Props) => {
  const provinces = useStore($provinces);
  return (
    <TablePosts<PropertyLocation>
      className="mt-3"
      items={provinces}
      colData={{
        label: {
          header: "Name",
          type: "strong-text",
          data: (item) => item.label,
        },
      }}
      renderChild={(items) => (
        <div className="flex flex-wrap gap-2 my-3">
          {items.map((item) => (
            <div
              key={item.id}
              className={cn(
                "flex items-center gap-2 p-2 bg-gray-100 rounded-md"
                // isOpen ? "block" : "hidden"
              )}
            >
              <div>{item.label}</div>
              <Button
                size="xs"
                color="accent"
                onClick={() => console.log(`edit${item.id}`)}
              >
                Edit
              </Button>
              <ButtonRemove
                size="xs"
                onClick={() => removeLocationFx(item.id)}
              />
            </div>
          ))}
        </div>
      )}
      onRemove={removeLocationFx}
    />
  );
};
