import React from "react";
import { type Review } from "shared/api/review";
import { LayoutPostEdit } from "shared/ui/layouts/layout-post-edit";
import { ReviewRightPanelControls } from "./review-right-panel-controls";
import { ReviewBaseControls } from "./review-base-controls.";

interface Props {
  review: Review | null;
}

export const ReviewEditForm = ({ review }: Props) => {
  console.log("---review");
  console.log(review);

  if (!review) return null;

  return (
    <>
      <LayoutPostEdit
        content={<ReviewBaseControls review={review} />}
        rightPanel={
          <>
            <ReviewRightPanelControls review={review} />
          </>
        }
      />
    </>
  );
};
