import React from "react";
import cn from "clsx";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "shared/ui/icon";

interface Props {
  className?: string;
  path: string;
  label?: string;
  icon?: any;
  collapsed?: boolean;
  onClick?: () => void;
  isChildren?: boolean;
}

export const SidebarItem = ({
  path,
  icon,
  className,
  label,
  collapsed,
  onClick,
  isChildren = false,
}: Props) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  return (
    <>
      <Link
        className={cn(
          className,
          "flex items-center p-3 text-gray-200 border-b border-b-gray-700 hover:bg-indigo-700 select-none",
          {
            "border-b-gray-800 bg-gray-900": isChildren,
            "bg-gradient bg-gradient-to-r from-indigo-900 to-gray-900":
              isActive,
          },
          !collapsed ? "pl-3" : "justify-center"
        )}
        to={path}
        onClick={onClick}
      >
        {icon && <Icon name={icon} className="w-[22px] h-[22px] fill-white" />}
        {!icon && collapsed ? (
          <span className="font-bold">{label?.slice(0, 3)}</span>
        ) : null}
        {!collapsed && <span className="pl-3">{label}</span>}
      </Link>
    </>
  );
};
