import { sample } from "effector";
import { type PostModel } from "./post-field-attachment.model";
import { type PaginationModel } from "features/pagination/pagination.model";

export const postsPaginationEvents = <T>(
  postsModel: PostModel<T>,
  paginationModel: PaginationModel
) => {
  const $pendingLoading = postsModel.loadPostsFx.pending.map(
    (pending) => !pending
  );

  sample({
    clock: postsModel.loadPostsFx.doneData,
    fn: (clock) => clock.results,
    target: postsModel.$posts,
  });

  sample({
    clock: postsModel.PostsGate.close,
    filter: () => $pendingLoading.getState(),
    fn: () => [],
    target: [postsModel.$posts, paginationModel.reset],
  });

  sample({
    clock: postsModel.removePostWithAttachmentsFx.done,
    source: paginationModel.params,
    fn: (source) => ({
      take: source.take,
      skip: source.skip,
    }),
    target: postsModel.loadPostsFx,
  });
};
