import React, { useEffect } from "react";
import { LayoutBase } from "shared/ui/layouts";
import { PropertyEditForm } from "modules/property/ui/property-edit-form";
import { useGate, useStore } from "effector-react";
import { NavLink, useNavigate } from "react-router-dom";
import { propertyCreate } from "modules/property/property-create.model";
import { Loader } from "shared/ui/loader";

export const PropertyCreate = () => {
  const property = useStore(propertyCreate.$property);
  const navigate = useNavigate();
  const loading = useStore(propertyCreate.$loading);

  useGate(propertyCreate.PropertyCreateGate, {
    type: "",
    province: null,
    location: null,
    description: "",
    attachments: null,
    status: "Sale",
    hidden: true,
  });

  useEffect(() => {
    property?.id && navigate(`/property/${property?.id}/edit`);
  }, [property?.id]);

  console.log("=====property create");
  // console.log(property);

  return (
    <LayoutBase
      title="Create new property"
      actions={
        <>
          <NavLink to="/property" className="btn btn-secondary btn-sm">
            All properties
          </NavLink>
        </>
      }
    >
      {loading && <Loader />}
      {!property?.id && <PropertyEditForm property={property} />}
    </LayoutBase>
  );
};
