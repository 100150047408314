import { combine, forward, sample, Store } from "effector";
import { $fields, $selectedField } from "modules/field/field.model";
import {
  $attachments,
  attachmentsRemoveAll,
  attachmentsRevokeAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { PostModel } from "./post-field-attachment.model";
import { Field } from "../field/field.types";
import { ValidateSchema } from "../validation/validation.lib";

export const updatePostEvents = <T>(
  model: PostModel<T>,
  initialPostFields: Field[],
  validateSchema?: ValidateSchema
) => {
  const $pendingLoadingPost = model.loadPostByIdFx.pending.map(
    (pending) => !pending
  );

  sample({
    clock: model.PostEditGate.open,
    filter: () => $pendingLoadingPost.getState(),
    target: model.loadPostByIdFx,
  });

  // errors validate schema
  sample({
    clock: model.PostEditGate.open,
    fn: () => validateSchema ?? null,
    target: $validateSchema,
  });

  // clear
  sample({
    clock: [model.PostEditGate.close, model.removePostWithAttachmentsFx.done],
    filter: () => $pendingLoadingPost.getState(),
    fn: () => null,
    target: [
      model.$post,
      $selectedField,
      attachmentsRemoveAll,
      $validateSchema,
    ],
  });

  sample({
    clock: model.PostEditGate.close,
    fn: () => ({}),
    target: $errors,
  });

  sample({
    clock: [model.PostEditGate.close, model.removePostWithAttachmentsFx.done],
    filter: () => $pendingLoadingPost.getState(),
    fn: () => [],
    target: $fields,
  });

  forward({
    from: model.loadPostByIdFx.doneData,
    to: model.$post,
  });

  // initialize fields
  sample({
    clock: model.loadPostByIdFx.doneData,
    fn: (clock) => ({
      initialFields: initialPostFields,
      sourceFields: clock.fields ?? [],
    }),
    target: model.initializeFieldsFx,
  });

  // initialize attachments
  sample({
    clock: model.initializeFieldsFx,
    source: model.loadPostByIdFx.doneData,
    fn: (source) => ({
      initialAttachments: source.attachments ?? null,
    }),
    target: model.initializeAttachmentsFx,
  });

  // validate
  sample({
    clock: model.update,
    target: validateField,
  });

  sample({
    clock: model.submitForm,
    source: model.$post,
    fn: (source) => source ?? {},
    target: validateAllFields,
  });

  // update fields attachments
  sample({
    clock: model.submitForm,
    source: combine({ fields: $fields, attachments: $attachments }),
    filter: () => {
      return !Object.keys($errors.getState()).length;
    },
    target: model.updateFieldsAttachments,
  });

  sample({
    clock: model.updateFieldsAttachments,
    source: combine({
      attachments: $attachments,
      post: model.$post as Store<T>,
    }),
    target: model.updatePostAttachmentsFx,
  });

  // clear blob data
  sample({
    clock: model.updatePostAttachmentsFx.doneData,
    target: attachmentsRevokeAll,
  });

  // new attachments
  sample({
    clock: model.updatePostAttachmentsFx.doneData,
    source: model.updatePostAttachmentsFx.doneData,
    fn: (source) => source.attachments,
    target: $attachments,
  });

  // new data
  sample({
    clock: model.updatePostAttachmentsFx.doneData,
    fn: (clock) => clock.post,
    target: model.$post,
  });
};
