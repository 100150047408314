import { combine, sample, Store } from "effector";
import { $fields, $selectedField } from "modules/field/field.model";
import {
  $attachments,
  attachmentsRemoveAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { type PostModel } from "./post-field-attachment.model";
import { type ValidateSchema } from "../validation/validation.lib";

export const createPostEvents = <T>(
  model: PostModel<T>,
  validateSchema?: ValidateSchema
) => {
  sample({
    clock: [model.PostCreateGate.open, model.PostCreateGate.close],
    source: model.PostCreateGate.open,
    target: model.$post,
  });

  // errors validate schema
  sample({
    clock: model.PostCreateGate.open,
    fn: () => validateSchema ?? null,
    target: $validateSchema,
  });

  // clear all
  sample({
    clock: model.PostCreateGate.close,
    fn: () => null,
    target: [
      model.$post,
      $selectedField,
      attachmentsRemoveAll,
      $validateSchema,
    ],
  });

  sample({
    clock: model.PostCreateGate.close,
    fn: () => ({}),
    target: $errors,
  });

  // clear fields
  sample({
    clock: model.PostCreateGate.close,
    fn: () => [],
    target: $fields,
  });

  // validate and updated data
  sample({
    clock: model.update,
    target: validateField,
  });

  // validate
  sample({
    clock: model.submitForm,
    source: model.$post,
    fn: (source) => source ?? {},
    target: validateAllFields,
  });

  // update fields attachments
  sample({
    clock: model.submitForm,
    source: combine({ fields: $fields, attachments: $attachments }),
    filter: () => {
      return !Object.keys($errors.getState()).length;
    },
    target: model.updateFieldsAttachments,
  });

  sample({
    clock: model.updateFieldsAttachments,
    source: combine({
      post: model.$post as Store<T>,
      attachments: $attachments,
    }),
    target: model.createPostWithAttachmentsFx,
  });

  sample({
    clock: model.createPostWithAttachmentsFx.doneData,
    target: model.$post,
  });
};
