import {
  createEffect,
  createEvent,
  createStore,
  type Effect,
  type Event,
  merge,
  restore,
  sample,
  type Store,
} from "effector";
import type { Field } from "../field/field.types";
import type {
  AttachmentByFieldId,
  AttachmentObject,
  AttachmentUploadMethod,
} from "shared/api";
import { attachmentUpdateByIds } from "shared/api";
import { createGate, type Gate } from "effector-react";
import {
  attachmentsInitialize,
  uploadAttachmentsFx,
} from "../attachment/attachment.model";
import { $fields, fieldLoadAndMerge } from "../field/field.model";
import { type FindAllOptions } from "shared/api/types";

export interface PostModel<T> {
  $post: Store<T | null>;
  $posts: Store<T[]>;
  $postType: Store<string>;
  $postRemovedId: Store<number | null>;
  $loading: Store<boolean>;
  PostCreateGate: Gate<T>;
  PostEditGate: Gate<{ postId: number }>;
  PostsGate: Gate<FindAllOptions | undefined>;
  update: Event<{ key: string; value: unknown }>;
  updateFieldsAttachments: Event<{
    fields: Field[] | null;
    attachments: AttachmentByFieldId | null;
  }>;
  submitForm: Event<void>;
  // effects
  loadPostsFx: Effect<FindAllOptions | void, { results: T[]; total: number }>;
  loadPostByIdFx: Effect<{ postId: number }, any>;
  createPostWithAttachmentsFx: Effect<
    {
      post: T;
      attachments: AttachmentByFieldId | null;
    },
    any
  >;
  updatePostAttachmentsFx: Effect<
    {
      post: T;
      attachments: AttachmentByFieldId | null;
    },
    any
  >;
  removePostWithAttachmentsFx: Effect<number, any>;
  // removePostFromListWithAttachmentsFx: Effect<number, any>;
  initializeFieldsFx: Effect<
    {
      initialFields: Field[];
      sourceFields: Field[];
    },
    any
  >;
  initializeAttachmentsFx: Effect<
    {
      initialAttachments: AttachmentByFieldId | null;
    },
    any
  >;
}

export interface Post {
  id?: number;
  parentId?: number | null;
  slug?: string;
  title: string;
  fields: Field[] | null;
  attachments: AttachmentByFieldId | null;
}

export interface PostModelApiResolvers<T> {
  loadPosts: (
    query?: FindAllOptions
  ) => Promise<{ results: T[]; total: number }>;
  loadPostById: ({ postId }: { postId: number }) => Promise<T>;
  createPost: (post: T) => Promise<T>;
  updatePost: (post: T) => Promise<T>;
  removePost: (postId: number) => Promise<number>;
}

export const postModel = <T extends Post>(
  postType: string,
  apiResolvers: PostModelApiResolvers<T>
) => {
  const $post = createStore<T | null>(null);
  const $posts = createStore<T[]>([]);
  const $postType = createStore<string>(postType);
  const $postRemovedId = createStore<number | null>(null);
  const PostCreateGate = createGate<T>();
  const PostEditGate = createGate<{ postId: number }>();
  const PostsGate = createGate<FindAllOptions | undefined>();

  const submitForm = createEvent();
  const update = createEvent<{ key: string; value: unknown }>();
  $post.on(update, (state, { key, value }) => {
    if (state) {
      return { ...state, [key]: value };
    }
  });

  const updateFieldsAttachments = createEvent<{
    fields: Field[] | null;
    attachments: AttachmentByFieldId | null;
  }>();
  $post.on(updateFieldsAttachments, (state, { fields, attachments }) => {
    if (state) {
      return { ...state, fields, attachments };
    }
  });

  const loadPostsFx = createEffect({
    handler: async (params?: FindAllOptions) => {
      return await apiResolvers.loadPosts(params);
    },
  });

  const loadPostByIdFx = createEffect({
    handler: async ({ postId }: { postId: number }) => {
      return await apiResolvers.loadPostById({ postId });
    },
  });

  const initializeFieldsFx = createEffect({
    handler: async ({
      initialFields,
      sourceFields,
    }: {
      initialFields: Field[];
      sourceFields: Field[];
    }) => {
      fieldLoadAndMerge({ initialFields, sourceFields });
    },
  });

  const initializeAttachmentsFx = createEffect({
    handler: async ({
      initialAttachments,
    }: {
      initialAttachments: AttachmentByFieldId | null;
    }) => {
      attachmentsInitialize({
        initialAttachments,
        fields: $fields.getState(),
        additionalFieldIds: ["preview"],
      });
    },
  });

  const createPostWithAttachmentsFx = createEffect({
    handler: async ({
      post,
      attachments,
    }: {
      post: T;
      attachments: AttachmentByFieldId | null;
    }) => {
      const created = await apiResolvers.createPost(post);

      if (attachments) {
        await uploadAttachmentsFx({
          object: $postType.getState() as AttachmentObject,
          recordId: created.id as number,
          attachments,
          method: "create" as AttachmentUploadMethod,
        });
      }

      return created;
    },
  });

  const updatePostAttachmentsFx = createEffect({
    handler: async ({
      post,
      attachments,
    }: {
      post: T;
      attachments: AttachmentByFieldId | null;
    }) => {
      const newAttachments = await uploadAttachmentsFx({
        object: $postType.getState() as AttachmentObject,
        recordId: post.id as number,
        attachments,
        method: "update" as AttachmentUploadMethod,
      });
      return {
        post: {
          ...(await apiResolvers.updatePost(post)),
          attachments: newAttachments,
        },
        attachments: newAttachments,
      };
    },
  });

  const removePostWithAttachmentsFx = createEffect({
    handler: async (postId: number): Promise<number> => {
      if (postId) {
        // remove all attachments
        await attachmentUpdateByIds({
          object: $postType.getState() as AttachmentObject,
          recordId: postId,
          loadedAttachmentIds: [],
        });
      }
      return await apiResolvers.removePost(postId);
    },
  });

  sample({
    clock: removePostWithAttachmentsFx.doneData,
    target: $postRemovedId,
  });

  const $loading = restore(
    merge([
      loadPostByIdFx.pending,
      loadPostsFx.pending,
      updatePostAttachmentsFx.pending,
      createPostWithAttachmentsFx.pending,
      removePostWithAttachmentsFx.pending,
      uploadAttachmentsFx.pending,
    ]),
    false
  );

  return {
    $post,
    $posts,
    $postType,
    $postRemovedId,
    $loading,
    PostCreateGate,
    PostEditGate,
    PostsGate,
    submitForm,
    update,
    updateFieldsAttachments,
    initializeFieldsFx,
    initializeAttachmentsFx,
    loadPostsFx,
    loadPostByIdFx,
    createPostWithAttachmentsFx,
    updatePostAttachmentsFx,
    removePostWithAttachmentsFx,
  };
};
