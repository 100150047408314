import type {
  Field,
  RepeaterGroupDataType,
  RepeaterDataType,
  TextDataType,
  IconDataType,
  BlockRelationDataType,
  ButtonDataType,
  SelectDataType,
} from "modules/field/field.types";
import { uid } from "shared/lib/helpers";

export const getInitialDataAttributesFromField = (field: Field) => {
  switch (field.type) {
    case "text":
    case "textarea":
    case "text-editor":
      return {
        value: "",
      } as TextDataType;

    case "icon":
      return {
        icon: "",
      } as IconDataType;

    case "button":
      return {
        as: "link",
        modal: undefined,
        href: undefined,
        text: undefined,
      } as ButtonDataType;

    case "select":
      return {
        initialData: field.data?.initialData,
        selected: undefined,
      } as SelectDataType;

    case "block-rel":
      return {
        blockId: null,
      } as BlockRelationDataType;

    case "repeater":
      const dataRepeater: RepeaterDataType = {
        initialFields: field.data?.initialFields,
        // cols: (field.data?.cols as number) ?? undefined,
      };
      return dataRepeater;

    case "repeater-group":
      const dataGroup: RepeaterGroupDataType = {
        initialFields: field.data?.initialFields,
      };
      return dataGroup;
  }

  return undefined;
};

// export const getChildIdsByField = (
//   fields: Field[],
//   fromField: Field
// ): string[] => {
//   const ids: string[] = [];
//
//   const getChildFields = (field: Field) => {
//     field.id && ids.push(field.id);
//     const childFields = fields.filter((f) => f.parentId === field.id);
//     if (childFields?.length) {
//       childFields.forEach((child) => {
//         getChildFields(child);
//       });
//     }
//   };
//
//   getChildFields(fromField);
//
//   return ids;
// };

export const createNewFieldsFromFieldInitial = ({
  initialField,
  parentId,
  lastOrder,
}: {
  initialField: Field;
  parentId?: string;
  lastOrder?: number;
}): Field[] => {
  const fields: Field[] = [];
  let order = lastOrder ?? 0;

  const createField = ({
    field,
    parentId,
  }: {
    field: Field;
    parentId?: string;
  }) => {
    const fieldId = uid();
    // get field data attributes
    const fieldData = getInitialDataAttributesFromField(field);

    fields.push({
      ...field,
      id: fieldId,
      parentId,
      data: fieldData,
      order: ++order,
      collapsed: true,
    });

    if (field.data?.initialFields?.length) {
      order = 0;
      field.data?.initialFields.forEach((field) => {
        createField({ field, parentId: fieldId });
      });
    }
  };

  createField({ field: initialField, parentId });

  return fields;
};

// merge and remove unused fields from initial fields
export const mergeAndRemoveUnusedFields = (
  initialFields: Field[],
  sourceFields: Field[]
): Field[] => {
  const fields: Field[] = [];

  const mergeFields = (originField: Field, parentId?: string) => {
    let foundSourceFields: Field[];
    // has parentId - is a child field
    if (parentId) {
      foundSourceFields = sourceFields.filter(
        (f) => f.parentId === parentId && f.name === originField.name
      );
    } else {
      // root block
      foundSourceFields = sourceFields.filter(
        (f) => f.name === originField.name
      );
    }

    // exist fields in source fields
    if (foundSourceFields.length) {
      foundSourceFields.forEach((sourceField) => {
        //
        // merge initial <-> source data and add block
        //
        const originFieldData = getInitialDataAttributesFromField(originField);
        // iterate origin keys and merge values if exists in source field data
        let newData: { [key: string]: unknown } | undefined;
        if (originFieldData && sourceField.data) {
          Object.keys(originFieldData).forEach((originKey) => {
            if (sourceField.data && sourceField.data[originKey]) {
              // get initialFields from origin field
              if (originKey === "initialFields") {
                if (newData) {
                  newData["initialFields"] = originField?.data?.initialFields;
                } else {
                  newData = { initialFields: originField?.data?.initialFields };
                }
              } else if (originKey === "initialData") {
                if (newData) {
                  newData["initialData"] = originField?.data?.initialData;
                } else {
                  newData = { initialData: originField?.data?.initialData };
                }
              } else {
                if (newData) {
                  newData[originKey] = sourceField.data[originKey];
                } else {
                  newData = { [originKey]: sourceField.data[originKey] };
                }
              }
            }
          });
        }

        fields.push({
          ...sourceField,
          label: originField.label,
          type: originField.type,
          data: newData,
        });

        // group or repeater have initial fields
        if (originField?.data?.initialFields?.length) {
          originField?.data?.initialFields.forEach((originChildField) => {
            mergeFields(originChildField, sourceField.id);
          });
        }
      });
    } else {
      // if field not in source, create new fields from initial
      // has parentId - is a child field, not root block
      parentId &&
        fields.push(
          ...createNewFieldsFromFieldInitial({
            initialField: originField,
            parentId,
          })
        );
    }
  };

  initialFields.forEach((initialField) => {
    mergeFields(initialField);
  });

  return fields;
};

export const getFieldLastOrderByParentId = ({
  fields,
  parentId,
}: {
  fields: Field[];
  parentId?: string;
}): number => {
  let lastOrder = 0;

  // root field (block)
  if (!parentId) {
    fields.forEach((field) => {
      if (field.type === "block" || field.type === "block-static") {
        lastOrder =
          field.order && field.order > lastOrder ? field.order : lastOrder;
      }
    });
  } else {
    fields.forEach((field) => {
      // last order in parent
      if (field.parentId === parentId) {
        lastOrder =
          field.order && field.order > lastOrder ? field.order : lastOrder;
      }
    });
  }

  return lastOrder;
};

export const removeWithChildByField = (
  fields: Field[],
  fromField: Field
): string[] => {
  const ids: string[] = [];

  const getChildFields = (field: Field) => {
    field.id && ids.push(field.id);
    const childFields = fields.filter((f) => f.parentId === field.id);
    if (childFields?.length) {
      childFields.forEach((child) => {
        getChildFields(child);
      });
    }
  };

  getChildFields(fromField);

  return ids;
};
