import { combine, sample, Store } from "effector";
import {
  $attachments,
  attachmentsRemoveAll,
} from "modules/attachment/attachment.model";
import {
  $errors,
  $validateSchema,
  validateAllFields,
  validateField,
} from "modules/validation/validation.model";
import { reviewModel } from "./review.model";
import { reviewSchemaValidate } from "./review.config";
import { Review } from "shared/api";

export const reviewCreate = reviewModel();
sample({
  clock: [
    reviewCreate.ReviewCreateGate.open,
    reviewCreate.ReviewCreateGate.close,
  ],
  source: reviewCreate.ReviewCreateGate.open,
  target: reviewCreate.$review,
});

// errors validate schema
sample({
  clock: reviewCreate.ReviewCreateGate.open,
  fn: () => reviewSchemaValidate ?? null,
  target: $validateSchema,
});

// clear all
sample({
  clock: reviewCreate.ReviewCreateGate.close,
  fn: () => null,
  target: [reviewCreate.$review, attachmentsRemoveAll, $validateSchema],
});

sample({
  clock: reviewCreate.ReviewCreateGate.close,
  fn: () => ({}),
  target: $errors,
});

// validate and updated data
sample({
  clock: reviewCreate.update,
  target: validateField,
});

// validate
sample({
  clock: reviewCreate.submitForm,
  source: reviewCreate.$review,
  fn: (source) => source ?? {},
  target: validateAllFields,
});

sample({
  clock: reviewCreate.submitForm,
  filter: () => {
    return !Object.keys($errors.getState()).length;
  },
  source: combine({
    review: reviewCreate.$review as Store<Review>,
    attachments: $attachments,
  }),
  target: reviewCreate.createReviewWithAttachmentsFx,
});

sample({
  clock: reviewCreate.createReviewWithAttachmentsFx.doneData,
  target: reviewCreate.$review,
});
