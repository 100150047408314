import axios, { type AxiosResponse } from "axios";
import { config } from "../config";
import { auth } from "./auth";
import { addToastFx } from "modules/toast/toast.model";
import { uid } from "shared/lib/helpers";

export const response = (response: AxiosResponse) => response.data;

export const api = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config;
    console.log(error);

    //check refresh token
    if (error?.response?.status === 401 && !config._retry) {
      config._retry = true;
      await auth.refresh();
      return api(config);
    }

    let errorMessage =
      error?.response?.data?.message ?? error.message ?? "unknown error";

    errorMessage =
      error.code === "ERR_NETWORK" ? "Not connected to server" : errorMessage;

    if (typeof errorMessage === "object") {
      for (const [key, value] of Object.entries(errorMessage)) {
        addToastFx({
          id: uid(),
          title: "Server error",
          text: `${key}: ${value}`,
          type: "error",
        });
      }
    } else {
      addToastFx({
        id: uid(),
        title: "Server error",
        text: errorMessage,
        type: "error",
      });
    }

    return Promise.reject(
      // api response errors as array
      typeof errorMessage === "string" ? { status: errorMessage } : errorMessage
    );
  }
);
