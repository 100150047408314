import type { Field } from "modules/field/field.types";
import { api, response } from "./axios";
import type { AttachmentByFieldId } from "./attachment";
import { getSearchParamsAsJson } from "./lib";
import type { FindAllOptions, Paginated } from "./types";

export interface Blog {
  id?: number;
  slug: string;
  title: string;
  excerpt: string;
  fields: Field[] | null;
  attachments: AttachmentByFieldId | null;
  hidden: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export const getBlogs = (params?: FindAllOptions): Promise<Paginated<Blog>> => {
  const searchParams = getSearchParamsAsJson(params);
  return api.get(`/blog?${searchParams}`).then(response);
};

export const getBlog = ({ postId }: { postId: number }): Promise<Blog> => {
  return api.get(`/blog/${postId}`).then(response);
};

export const blogCreate = (blog: Blog): Promise<Blog> => {
  return api.post(`/blog`, blog).then(response);
};

export const blogEdit = (blog: Blog): Promise<Blog> => {
  return api.put(`/blog`, blog).then(response);
};

export const blogRemove = (id: number): Promise<number> => {
  return api.delete(`/blog/${id}`).then(response);
};
