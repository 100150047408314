import React from "react";
import { LayoutPostEdit } from "shared/ui/layouts/layout-post-edit";
import { PropertyRightPanelControls } from "./property-right-panel-controls";
import { PropertyBaseControls } from "./property-base-controls.";
import { type Property } from "shared/api/property";
import { ImageListUpload } from "../../attachment/ui/image-list-upload";
import { ImageList } from "../../attachment/ui/image-list";
import { Attachment } from "../../../shared/api";

interface Props {
  property: Property | null;
}

export const PropertyEditForm = ({ property }: Props) => {
  console.log("---property");
  console.log(property);

  if (!property) return null;

  return (
    <>
      <LayoutPostEdit
        readonly={!!property?.source}
        content={
          <>
            <PropertyBaseControls property={property} />
            {property.source ? (
              property.meta?.images ? (
                <ImageList
                  label="Images"
                  images={property.meta.images as Attachment[]}
                />
              ) : null
            ) : (
              <ImageListUpload fieldId="images" label="Images" />
            )}
          </>
        }
        rightPanel={
          <>
            <PropertyRightPanelControls property={property} />
          </>
        }
      />
    </>
  );
};
