import { postModel } from "modules/post-field-attachment/post-field-attachment.model";
import { createPostEvents } from "modules/post-field-attachment/event-create";
import { updatePostEvents } from "modules/post-field-attachment/event-update";
import { initialBlockContentFields } from "./block.config";
import {
  type Block,
  blockCreate,
  blockEdit,
  blockRemove,
  getBlock,
  getBlocks,
} from "shared/api";
import { paginationModel } from "features/pagination/pagination.model";
import { postsPaginationEvents } from "../post-field-attachment/event-list-pagination";

const apiResolvers = {
  loadPosts: getBlocks,
  loadPostById: getBlock,
  createPost: blockCreate,
  updatePost: blockEdit,
  removePost: blockRemove,
};

const postType = "block";
export const blockCreateModel = postModel<Block>(postType, apiResolvers);
createPostEvents<Block>(blockCreateModel);

export const blockEditModel = postModel<Block>(postType, apiResolvers);
updatePostEvents<Block>(blockEditModel, initialBlockContentFields);

// results with pagination
export const blocksModel = postModel<Block>(postType, apiResolvers);
const take = 10;
export const blocksPaginationModel = paginationModel({
  loadFx: blocksModel.loadPostsFx,
  take,
});
postsPaginationEvents<Block>(blocksModel, blocksPaginationModel);
