import React, { ChangeEvent, useMemo } from "react";
import type { Field } from "modules/field/field.types";
import type { AttachmentDataType } from "modules/field/field.types";
import { UploadArea } from "shared/ui/upload-area";
import { Box } from "shared/ui/box";
import { ButtonOrder, ButtonRemove } from "shared/ui/buttons";
import { useStore } from "effector-react";
import {
  $attachments,
  attachmentsAdd,
  attachmentsChange,
  attachmentsOrder,
  attachmentsRemoveOne,
} from "modules/attachment/attachment.model";
import { config } from "shared/config";
import { getAttachmentUrl } from "../attachment.lib";

interface Props {
  fieldId: string;
  label?: string;
  multiple?: boolean;
}

export const ImageListUpload = ({ fieldId, label, multiple = true }: Props) => {
  const attachments = useStore($attachments);

  const images =
    attachments && attachments[fieldId] ? attachments[fieldId] : null;

  const handleAttachmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files as FileList;
    if (multiple) {
      attachmentsAdd({ fieldId, newFiles, allowType: "image" });
    } else {
      attachmentsChange({ fieldId, newFiles, allowType: "image" });
    }
  };

  return useMemo(() => {
    return (
      <Box label={label}>
        <div className="flex flex-wrap justify-center gap-3 mt-3">
          {/* list files */}
          {images?.map((image, index) => (
            // TODO: unique id?
            <div
              key={index}
              className="relative flex-1 min-w-[160px] max-w-[300px]"
            >
              {image.url && (
                <>
                  <img
                    className="h-[180px] w-full object-cover mx-auto rounded-lg shadow-md"
                    src={getAttachmentUrl(image, "sm")}
                    alt={image.alt ?? ""}
                    loading={"lazy"}
                  />
                  <div className="absolute flex justify-between gap-1 top-2 right-2 left-2">
                    <div className="flex gap-1">
                      {index > 0 && (
                        <ButtonOrder
                          order="prev"
                          onClick={() =>
                            attachmentsOrder({
                              fieldId,
                              fileIndex: index,
                              order: -1,
                            })
                          }
                        />
                      )}
                      {index + 1 < images.length && (
                        <ButtonOrder
                          order="next"
                          onClick={() =>
                            attachmentsOrder({
                              fieldId,
                              fileIndex: index,
                              order: 1,
                            })
                          }
                        />
                      )}
                    </div>
                    <ButtonRemove
                      onClick={() =>
                        attachmentsRemoveOne({
                          fieldId,
                          removeIndex: index,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          ))}
          {/* upload area */}
          <div className="relative flex-1 min-w-[160px] max-w-full">
            <UploadArea
              inputId={fieldId}
              onChange={handleAttachmentChange}
              multiple={multiple}
            />
          </div>
        </div>
      </Box>
    );
  }, [images]);
};
