import type { Field } from "modules/field/field.types";
import { api, response } from "./axios";
import type { AttachmentByFieldId } from "./attachment";
import { getSearchParamsAsJson } from "./lib";
import type { FindAllOptions, Paginated } from "./types";

export interface Page {
  id?: number;
  parentId: number | null;
  slug: string;
  title: string;
  excerpt: string;
  attachments: AttachmentByFieldId | null;
  fields: Field[] | null;
  hidden: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export const selectShort = {
  id: true,
  parentId: true,
  slug: true,
  title: true,
  excerpt: true,
  updatedAt: true,
  hidden: true,
};

export const getPages = (params?: FindAllOptions): Promise<Paginated<Page>> => {
  const searchParams = getSearchParamsAsJson(params);
  return api.get(`/page?${searchParams}`).then(response);
};

export const getPage = ({ postId }: { postId: number }): Promise<Page> => {
  return api.get(`/page/${postId}`).then(response);
};

export const pageCreate = (page: Page): Promise<Page> => {
  return api.post(`/page`, page).then(response);
};

export const pageEdit = (page: Page): Promise<Page> => {
  return api.put(`/page`, page).then(response);
};

export const pageRemove = (pageId: number): Promise<number> => {
  return api.delete(`/page/${pageId}`).then(response);
};
