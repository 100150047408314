export const selectListIcons = [
  { label: "" },
  { label: "medal" },
  { label: "checkmark-round" },
  { label: "hand-good" },
  { label: "home" },
  { label: "marker" },
  { label: "repair" },
  { label: "search" },
  { label: "user-target" },
  { label: "user-folder" },
  { label: "coaching" },
  { label: "photos" },
  { label: "headset" },
  { label: "income" },
  { label: "visible" },
  { label: "speaker" },
  { label: "important-date" },
  { label: "business-strategy" },
  { label: "payment-receipt" },
  { label: "company" },
  { label: "cash" },
  { label: "checkmark-shield" },
  { label: "heart" },
  { label: "sun" },
  { label: "star" },
];
